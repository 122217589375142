import { History } from 'history';
import { State } from '../@types/state';
import { routerMiddleware } from 'connected-react-router';
import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
  DeepPartial,
} from 'redux';
import thunk from 'redux-thunk';

import snackbarMiddleware from './middleware/snackbar.middleware';

import createRootReducer from './root.reducer';

const createStore = (history: History) => (
  preloadedState: DeepPartial<State> = {}
) => {
  const middlewares = [thunk, routerMiddleware(history), snackbarMiddleware];

  preloadedState.auth = {
    pending: false,
    data: {
      token: undefined,
      role: undefined,
    },
    errors: undefined,
  };

    preloadedState.info = {
    pending: false,
    data: {
      infos: null,
    },
    initFetch: true,
    errors: undefined,
  };




  const savedToken = localStorage.getItem('auth');
  const savedUser = localStorage.getItem('infos');

  if (savedToken) {
    preloadedState.auth.data = JSON.parse(savedToken);
  }

  if (savedUser) {
    preloadedState.info.data = JSON.parse(savedUser);
  }


  if (process.env.NODE_ENV === `development`) {
    const composeEnhancers =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createReduxStore(
      createRootReducer(history),
      // @ts-ignore
      preloadedState,
      composeEnhancers(applyMiddleware(...middlewares))
    );
  }

  return createReduxStore(
    createRootReducer(history),
    // @ts-ignore
    preloadedState,
    applyMiddleware(...middlewares)
  );
};

export default createStore;

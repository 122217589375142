import React from 'react';
import { useSelector } from 'react-redux';
import { useTimeout } from '../../modules/utils/hook';
import { useHistory } from 'react-router';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Endpoint } from '../../router/routes.config';
import { selectInfos } from '../../modules/info/info.selector';
import config from '../../application/app.config';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(var(--vh, 1vh) * 100)`,
    background: theme.palette.primary.main,
  },
  logoUrl: {
    maxWidth: 100,
    marginBottom: theme.spacing(2),
  },
  byAtsLogo: {
    maxWidth: 100,
  },
  byAts: {
    color: theme.palette.primary.contrastText,
  },
  divider: {
    width: 200,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    background: theme.palette.primary.contrastText,
  },
}));

export default function Starting() {
  const classes = useStyles();
  const history = useHistory();
  const infos = useSelector(selectInfos);

  useTimeout(() => {
    if (infos && infos.is_onboarding_done) {
      history.replace(Endpoint.ORDER_CREATE);
    } else {
      history.replace(Endpoint.ONBOARDING);
    }
  }, config.startingTime);

  return (
    <Fade in={!!infos && !!infos.logo_url} timeout={{ enter: 2000 }}>
      <div id="splashscreen-view" className={classes.container}>
        {infos && infos.logo_url && (
          <img
            src={infos.logo_url}
            className={classes.logoUrl}
            alt="logo-ats"
          />
        )}
        {infos && infos.business_name !== 'ATS STUDIOS' && (
          <>
            <Divider variant="middle" classes={{ root: classes.divider }} />
            <Typography className={classes.byAts}>By Ats Studios</Typography>
          </>
        )}
      </div>
    </Fade>
  );
}

import { State } from '../../@types/state';
import { PlayerStateEnum } from './config/player.config';
import * as authSelector from 'modules/auth/auth.selector';

export function selectPlayerState(state: State): PlayerStateEnum {
  return state.player.state;
}

export function selectPlayerPath(state: State): string {
  const token = authSelector.selectToken(state);
  return state.player.path === null
    ? ''
    : state.player.path + '?access_token=' + token;
}

export function selectPlayerId(state: State): string | null {
  return state.player.id;
}

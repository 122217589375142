import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { NotificationActionTypes } from './types';
import { NotificationAction } from './notification.action';

export const initialState = {
  pending: false,
  data: {
    notifications: null,
  },
  initFetch: true,
  errors: undefined,
};

const notification: Reducer<State['notification'], NotificationAction> = (
  state: State['notification'] = initialState,
  action: NotificationAction
) => {
  switch (action.type) {
    case NotificationActionTypes.GET_NOTIFICATIONS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case NotificationActionTypes.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case NotificationActionTypes.GET_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default notification;

import { State } from '../../@types/state';
import { News } from '../../@types/state/news';

export const selectInitFetch = (state: State): boolean => {
  return state.news.initFetch;
};

export const selectFetchPending = (state: State): boolean => {
  return state.news.pending;
};

export const selectNews = (state: State): News[] | null => {
  return state.news.data.news.sort((a: News, b: News) => {
    if (a.updated_at > b.updated_at) {
      return -1;
    }
    if (a.updated_at < b.updated_at) {
      return 1;
    }

    return 0;
  });
};

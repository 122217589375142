import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TopBanner from '../../modules/banner/components/topBanner.component';
import BottomBanner from '../../modules/banner/components/bottomBanner.component';
import {
  getContentDependingOnStep,
  getToBannerContentDependingOnStep,
} from '../../modules/scheduler/helpers/selection.helpers';
import config, { Steps } from '../../modules/sound/config/selection.config';
import Progress from '../../modules/utils/components/progress.component';
import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import * as orderAction from '../../modules/order/order.action';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: `calc(var(--vh, 1vh) * 100)`,
    },
    content: {
      display: 'flex',
      flex: 1,
      padding: `${115}px ${theme.spacing(1)}px ${
        59 + theme.spacing(5)
      }px ${theme.spacing(1)}px`,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    next: {
      color: theme.palette.common.white,
    },
    previous: {
      color: theme.palette.common.white,
    },
  })
);

export default function SchedulerSelection() {
  const [activeStep, setActiveStep] = useState(Steps.STEP_1);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);

  function handlePreviousClick() {
    if (activeStep === Steps.STEP_2) {
      setActiveStep(Steps.STEP_1);
    } else {
      history.goBack();
    }
  }

  async function handleNextClick() {
    if (activeStep === Steps.STEP_2) {
      const startingOnDate = selectedDate.toISOString();
      dispatch(orderAction.setScheduler(startingOnDate));
    }
    history.goBack();
  }

  return (
    <div id="selection-view" className={classes.container}>
      <TopBanner
        title={routeMetaData && t(`${routeMetaData.name}`)}
        content={t(`${getToBannerContentDependingOnStep(activeStep)}`)}
      />

      <div id="content" className={classes.content}>
        {activeStep &&
          getContentDependingOnStep(
            activeStep,
            setActiveStep,
            selectedDate,
            setSelectedDate
          )}
      </div>

      <BottomBanner
        progress={<Progress percentage={activeStep / config.nbStep} />}
        Previous={(props: unknown) => (
          <Button
            onClick={handlePreviousClick}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: unknown) => (
          <Button
            type="submit"
            onClick={handleNextClick}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.next')}
          </Button>
        )}
      />
    </div>
  );
}

export enum SoundSource {
  LIBRARY = 'library',
  CUSTOM = 'custom',
  CUSTOM_ATS = 'custom_ats',
  NO_SOUND = 'no_sound',
}

export enum OrderEvent {
  CREATED = 'created',
  IN_PRODUCTION = 'in_production',
  PRODUCED = 'produced',
  CANCELED = 'canceled',
  ERROR = 'error',
  REFUSED = 'refused',
}

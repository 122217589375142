import React from 'react';
import I18n from '../../application/i18n';
import { AnyAction } from 'redux';
import Button from '@material-ui/core/Button';
import * as snackbarAction from '../../modules/snackbar/snackbar.action';
import config, { Content } from './config/snackbar.config';

export const snackbar = (store: any) => (next: any) => (action: AnyAction) => {
  let content: Content | null = null;
  if (action.error) {
    const infos = config[action.error.message || action.type];
    if (infos) {
      content = {
        ...infos,
      };
    }
  } else {
    const infos = config[action.type];
    if (infos) {
      content = {
        ...infos,
      };
    }
  }

  if (content) {
    store.dispatch(
      snackbarAction.enqueueSnackbar({
        message: `${I18n.t(content.message)}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: content.variant,
          anchorOrigin: {
            vertical: content.anchorOrigin.vertical,
            horizontal: content.anchorOrigin.horizontal,
          },
          action: (key: number) => (
            <Button
              style={{ color: 'white' }}
              onClick={() => store.dispatch(snackbarAction.closeSnackbar(key))}
            >
              {I18n.t('action.close')}
            </Button>
          ),
        },
      })
    );
  }

  let result = next(action);

  return result;
};

export default snackbar;

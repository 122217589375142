import { Action } from 'redux';
import { SoundQuery } from '../../@types/sound';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import * as soundServiceApi from '../../services/api/sound.service';
import { SoundActionTypes } from './types';
import config from '../../application/app.config';

export type SoundAction =
  | GetSoundPending
  | GetSoundSuccess
  | GetSoundError
  | GetSoundsWithSearchParamsPending
  | GetSoundsWithSearchParamsSuccess
  | GetSoundsWithSearchParamsError
  | GetSoundsWithSearchParamsViewMorePending
  | GetSoundsWithSearchParamsViewMoreSuccess
  | GetSoundsWithSearchParamsViewMoreError;

export interface GetSoundPending
  extends Action<SoundActionTypes.GET_SOUNDS_PENDING> {
  pending: boolean;
}

export interface GetSoundSuccess
  extends Action<SoundActionTypes.GET_SOUNDS_SUCCESS> {
  data: State['sound']['data'];
}

export interface GetSoundError
  extends Action<SoundActionTypes.GET_SOUNDS_ERROR> {
  message: any;
}

const getSoundsPending = (pending: boolean): GetSoundPending => {
  return { type: SoundActionTypes.GET_SOUNDS_PENDING, pending };
};

const getSoundSuccess = (data: State['sound']['data']): GetSoundSuccess => {
  return { type: SoundActionTypes.GET_SOUNDS_SUCCESS, data };
};

const getSoundsError = (message: string): GetSoundError => {
  return { type: SoundActionTypes.GET_SOUNDS_ERROR, message };
};

export const getSounds = (
  soundQuery: SoundQuery
): ThunkAction<Promise<void>, State, {}, SoundAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, SoundAction>
  ): Promise<void> => {
    dispatch(getSoundsPending(true));
    try {
      const response = await soundServiceApi.getSounds(soundQuery);
      const { data } = response;
      const results = data.data;

      if (response.status === 200) {
        if (response) {
          dispatch(
            getSoundSuccess({
              sounds: results,
              soundsViewMore: [],
            })
          );
        }
      }
    } catch (error) {
      dispatch(getSoundsError(error.message));
    }
  };
};

export interface IOptions {
  search: string;
  types: string[];
  categories: string[];
  offset?: number;
}

export interface GetSoundsWithSearchParamsPending
  extends Action<SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_PENDING> {
  pending: boolean;
}

export interface GetSoundsWithSearchParamsSuccess
  extends Action<SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_SUCCESS> {
  data: State['sound']['data'];
}

export interface GetSoundsWithSearchParamsError
  extends Action<SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_ERROR> {
  message: any;
}

const getSoundsWithSearchParamsPending = (
  pending: boolean
): GetSoundsWithSearchParamsPending => {
  return {
    type: SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_PENDING,
    pending,
  };
};

const getSoundsWithSearchParamsSuccess = (
  data: State['sound']['data']
): GetSoundsWithSearchParamsSuccess => {
  return {
    type: SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_SUCCESS,
    data,
  };
};

const getSoundsWithSearchParamsError = (
  message: string
): GetSoundsWithSearchParamsError => {
  return {
    type: SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_ERROR,
    message,
  };
};

export const getSoundsWithSearchParams = (
  options: IOptions
): ThunkAction<Promise<void>, State, {}, SoundAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, SoundAction>
  ): Promise<void> => {
    dispatch(getSoundsWithSearchParamsPending(true));
    try {
      const soundQuery: SoundQuery = {
        query: `${options.search}`,
        tempo_min: 0,
        tempo_max: 5,
        offset: options.offset ? options.offset : 0,
        size: parseInt(`${config.search.sound.limit}`),
        categories: options.categories.length > 0 ? options.categories : null,
        types: options.types.length > 0 ? options.types : null,
      };
      const response = await soundServiceApi.getSoundsWithSearchParams(
        soundQuery
      );

      const { data } = response;
      const results = data.data;

      if (response.status === 200) {
        if (response) {
          dispatch(
            getSoundsWithSearchParamsSuccess({
              sounds: results,
              soundsViewMore: [],
            })
          );
        }
      }
    } catch (error) {
      dispatch(getSoundsWithSearchParamsError(error.message));
    }
  };
};

export interface GetSoundsWithSearchParamsViewMorePending
  extends Action<SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_PENDING> {
  pending: boolean;
}

export interface GetSoundsWithSearchParamsViewMoreSuccess
  extends Action<SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_SUCCESS> {
  data: State['sound']['data'];
}

export interface GetSoundsWithSearchParamsViewMoreError
  extends Action<SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_ERROR> {
  message: any;
}

const getSoundsWithSearchParamsViewMorePending = (
  pending: boolean
): GetSoundsWithSearchParamsViewMorePending => {
  return {
    type: SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_PENDING,
    pending,
  };
};

const getSoundsWithSearchParamsViewMoreSuccess = (
  data: State['sound']['data']
): GetSoundsWithSearchParamsViewMoreSuccess => {
  return {
    type: SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_SUCCESS,
    data,
  };
};

const getSoundsWithSearchParamsViewMoreError = (
  message: string
): GetSoundsWithSearchParamsViewMoreError => {
  return {
    type: SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_ERROR,
    message,
  };
};

export const getSoundsWithSearchParamsViewMore = (
  options: IOptions
): ThunkAction<Promise<void>, State, {}, SoundAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, SoundAction>
  ): Promise<void> => {
    dispatch(getSoundsWithSearchParamsViewMorePending(true));
    try {
      const soundQuery: SoundQuery = {
        query: `${options.search}`,
        tempo_min: 0,
        tempo_max: 5,
        offset: options.offset ? options.offset : 0,
        size: parseInt(`${config.search.sound.limit}`),
        categories: options.categories.length > 0 ? options.categories : null,
        types: options.types.length > 0 ? options.types : null,
      };
      const response = await soundServiceApi.getSoundsWithSearchParams(
        soundQuery
      );

      const { data } = response;
      const results = data.data;

      if (response.status === 200) {
        if (response) {
          dispatch(
            getSoundsWithSearchParamsViewMoreSuccess({
              sounds: results,
              soundsViewMore: results,
            })
          );
        }
      }
    } catch (error) {
      dispatch(getSoundsWithSearchParamsViewMoreError(error.message));
    }
  };
};

import { Steps } from '../config/selection.config';
export function getToBannerContentDependingOnStep(
  activeStepId: number
): string {
  let content: string = '';

  switch (activeStepId) {
    case Steps.STEP_1: {
      content = 'message.text.topBanner.content.1';
      break;
    }
    case Steps.STEP_2: {
      content = 'message.text.topBanner.content.2';
      break;
    }
    case Steps.STEP_3: {
      content = 'message.text.topBanner.content.3';
      break;
    }
    case Steps.STEP_4: {
      content = 'message.text.topBanner.content.4';
      break;
    }
    default: {
      content = 'message.text.topBanner.content.default';
    }
  }

  return content;
}

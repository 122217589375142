import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import Chip from '@material-ui/core/Chip';

import ActionMenu from 'modules/order/components/actionMenu.component';

import { selectMessageByOrderId } from 'modules/message/message.selector';

import { config as stateConfig } from 'modules/state/config/state.config';
import { MessageEvent } from 'modules/message/config/message.config';

import { Order } from '../../../@types/state/order';

const useStyles = makeStyles((theme: Theme) => ({
  secondary: {
    maxWidth: '90%',
  },
  active: {
    color: '#17A589',
    fontWeight: 'bold',
  },
  eventList: {
    paddingTop: theme.spacing(4),
    maxHeight: 300,
    overflow: 'auto',
  },
  eventItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  firstList: {
    marginBottom: theme.spacing(3),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleChip: {
    marginLeft: theme.spacing(2),
  },
}));

interface IProps {
  order: Order;
}

export default function OrderListItem({ order }: IProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displayMessageEvents, setDispayMessageEvents] = React.useState(false);
  const classes = useStyles();
  const message = useSelector(selectMessageByOrderId)(order.id);
  const getOrderState = (state: string) => stateConfig.states.order[state];
  const getMessageState = (state: string) => stateConfig.states.message[state];

  const state = message
    ? getMessageState(message.state)
    : getOrderState(order.state);

  const dateOptions: any = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleDisplayMessageEvents() {
    setDispayMessageEvents(!displayMessageEvents);
  }

  return (
    <>
      <ListItem onClick={handleDisplayMessageEvents} style={{ marginTop: 8 }}>
        <ListItemText
          disableTypography
          primary={
            <Grid container spacing={1} direction="column">
              <Grid item className={classes.title}>
                <Typography style={{ fontWeight: 'bold' }}>
                  {order.title}
                </Typography>

                {message?.state === MessageEvent.REFUSED && (
                  <Chip
                    size="small"
                    className={classes.titleChip}
                    label={t('message.state.refused')}
                  />
                )}
              </Grid>
            </Grid>
          }
          secondary={
            <Grid container direction="column" spacing={1}>
              <Grid item className={classes.secondary}>
                <Typography color={'textSecondary'} noWrap>
                  {order.content}
                </Typography>
              </Grid>
              {state && (
                <Grid item container spacing={1}>
                  <Grid item>
                    <state.Icon
                      className={state.active ? classes.active : undefined}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={state.active ? classes.active : undefined}
                    >
                      {message?.state === MessageEvent.ERROR_CODE &&
                      message.is_temporary
                        ? t('event.message.waitForValidation.title')
                        : t(state.title)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <Typography color="textSecondary">
                  {t('myMessages.orderCreatedAt')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {new Date(order.created_at).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </Typography>
              </Grid>
              {message && (
                <>
                  <Grid item>
                    <Typography color="textSecondary">
                      {t('myMessages.producedAt')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {new Date(message.produced_at).toLocaleDateString(
                        'fr-FR',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        }
                      )}
                    </Typography>
                  </Grid>
                  <Grid item container justify="center">
                    <IconButton
                      onClick={handleDisplayMessageEvents}
                      size="small"
                    >
                      {displayMessageEvents && <ExpandLessRoundedIcon />}
                      {!displayMessageEvents && <ExpandMoreRoundedIcon />}
                    </IconButton>
                  </Grid>
                </>
              )}
              {message && (
                <Collapse in={displayMessageEvents}>
                  <Grid item>
                    <Grid item>
                      <Paper elevation={0} className={classes.eventList}>
                        <Typography color="textSecondary">
                          {t('myMessages.plannedAt')}
                        </Typography>
                        <List className={classes.firstList}>
                          {message.planned_at &&
                            message.planned_at.length > 0 &&
                            message.planned_at.map((date: string) => {
                              return (
                                <ListItem
                                  className={classes.eventItem}
                                  key={date}
                                >
                                  {new Date(date).toLocaleDateString(
                                    'fr-Fr',
                                    dateOptions
                                  )}
                                </ListItem>
                              );
                            })}
                          {message.planned_at &&
                            message.planned_at.length === 0 && (
                              <ListItem className={classes.eventItem}>
                                {t('myMessages.noPlannification')}
                              </ListItem>
                            )}
                        </List>

                        <Typography color="textSecondary">
                          {t('myMessages.installedAt')}
                        </Typography>
                        <List>
                          {message.installed_at &&
                            message.installed_at.map((date: string) => {
                              return (
                                <ListItem
                                  className={classes.eventItem}
                                  key={date}
                                >
                                  {new Date(date).toLocaleDateString(
                                    'fr-Fr',
                                    dateOptions
                                  )}
                                </ListItem>
                              );
                            })}
                          {message.installed_at &&
                            message.installed_at.length === 0 && (
                              <ListItem className={classes.eventItem}>
                                {t('myMessages.noInstall')}
                              </ListItem>
                            )}
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                </Collapse>
              )}

              {!message && (
                <Grid item>
                  <Typography>{t('myMessages.inProduction')}</Typography>
                </Grid>
              )}
            </Grid>
          }
        />

        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <ActionMenu
            order={order}
            message={message}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            {...(message && { message })}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

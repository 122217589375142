import { State } from '../../@types/state';
import {
  MapTemplates,
  Template,
  MapCategories,
  Categorie,
} from '../../@types/state/template';
import { selectInfos } from '../info/info.selector';
import { selectInprogressOrder } from '../order/order.selector';
import {
  updateTemplatesForDisplay,
  updateTemplatesForDisplayWithDynamicLabels,
  updateTemplatesForDisplayWithDynamicVariableValues,
} from './helpers/textFormat.helpers';
import appConfig from '../../application/app.config';

export function selectInitFetch(state: State): boolean {
  return state.template.initFetch;
}

export function selectFetchPending(state: State): boolean {
  return state.template.pending;
}

export function selectMapTemplates(state: State): MapTemplates | null {
  return state.template.data.templates;
}

export function selectTemplatesByLanguage(state: State) {
  return function (language: string): Template[] {
    const mapTemplates = selectMapTemplates(state);

    if (!mapTemplates) return [];

    const templates = Object.values(mapTemplates);

    return templates.filter((template: Template) => {
      return template.language === language;
    });
  };
}

export function selectTemplateById(state: State) {
  return function (templateId: string | undefined | null): Template | null {
    if (!templateId) return null;

    const mapTemplates = selectMapTemplates(state);
    if (!mapTemplates) return null;

    return mapTemplates[templateId];
  };
}

export function selectDefaultTemplateId(state: State): number | null {
  const templates = selectTemplatesByLanguage(state)(appConfig.defaultlanguage);

  if (templates.length === 0) return null;

  return templates[0].id;
}

export function selectUpdatedTemplatesForDisplay(
  state: State
): Template[] | null {
  const templates = selectTemplatesByLanguage(state)(appConfig.defaultlanguage);
  const infos = selectInfos(state);

  const inProgressOrder = selectInprogressOrder(state);

  if (templates.length === 0) return null;

  const updatedTemplatesWithStandardVariableValues = updateTemplatesForDisplay(
    templates,
    infos
  );

  const hasDynamicVariablesValues =
    Object.keys(inProgressOrder?.dynamic_variables || {}).length > 0;

  if (hasDynamicVariablesValues) {
    return updateTemplatesForDisplayWithDynamicVariableValues(
      updatedTemplatesWithStandardVariableValues,
      inProgressOrder?.dynamic_variables || {}
    );
  } else {
    return updateTemplatesForDisplayWithDynamicLabels(
      updatedTemplatesWithStandardVariableValues
    );
  }
}

export function selectUpdatedTemplateForDisplayById(state: State) {
  const updatedTemplatesForDisplay = selectUpdatedTemplatesForDisplay(state);

  return function (templateId: number): Template | null {
    if (!updatedTemplatesForDisplay) return null;

    const searchedTemplate = updatedTemplatesForDisplay.find(
      (template: Template) => template.id === templateId
    );

    if (!searchedTemplate) return null;

    return searchedTemplate;
  };
}

export function selectUpdatedTemplateForDisplay(
  state: State
): Template | null | string {
  const updatedTemplatesForDisplay = selectUpdatedTemplatesForDisplay(state);
  const orderInProgress = selectInprogressOrder(state);
  const getUpdatedTemplateForDisplayById =
    selectUpdatedTemplateForDisplayById(state);
  let updatedTemplateForDisplay: Template | null = null;

  if (!updatedTemplatesForDisplay) return null;

  if (
    (orderInProgress && orderInProgress.template_id) ||
    (orderInProgress && orderInProgress.content)
  ) {
    const { template_id, content } = orderInProgress;

    if (template_id) {
      updatedTemplateForDisplay = getUpdatedTemplateForDisplayById(template_id);
    } else {
      return content;
    }
  }

  return updatedTemplateForDisplay;
}

export function selectMapCategories(state: State): MapCategories | null {
  return state.template.data.categories;
}

export function selectCategories(state: State): Categorie[] {
  const mapCategories = selectMapCategories(state);
  const templates = selectTemplatesByLanguage(state)(appConfig.defaultlanguage);

  const usedCategorie: number[] = [];
  templates.forEach((template: Template) => {
    usedCategorie.push(template.template_category_id);
  });

  if (!mapCategories) return [];

  const categories = Object.values(mapCategories);
  const distinctCategorie = categories.filter((categorie: Categorie) => {
    return usedCategorie.includes(categorie.id);
  });

  return distinctCategorie;
}

export function selectTemplatesDependingOnTemplateCategorie(state: State) {
  const templates = selectUpdatedTemplatesForDisplay(state);

  return function (categorieId: number): Template[] | null {
    if (!templates) return null;

    const filterdTemplates = templates.filter((template: Template) => {
      return template.template_category_id === categorieId;
    });

    return filterdTemplates;
  };
}

interface ITranslateReturn {
  template_id: number;
}

export function getTemplateIdByLanguage(state: State) {
  return function (
    atsId: string,
    language: string
  ): ITranslateReturn | undefined {
    const mapTemplates = selectMapTemplates(state);
    const infos = selectInfos(state);

    if (!mapTemplates || !infos) return undefined;

    const templates = Object.values(mapTemplates);

    const searchedTemplate = templates.find((template: Template) => {
      return template.ats_id === atsId && template.language === language;
    });

    if (searchedTemplate) {
      return {
        template_id: searchedTemplate.id,
      };
    }

    return undefined;
  };
}

export function selectIsTranslated(state: State) {
  return function (atsId: string, language: string): boolean {
    const templates = selectTemplatesByLanguage(state)(language);

    if (templates.length === 0) return false;
    const searchedTemplate = templates.find(
      (template: Template) => template.ats_id === atsId
    );

    if (searchedTemplate) return true;

    return false;
  };
}

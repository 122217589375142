import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Voice } from '../../../../../@types/state/voice';
import { selectVoicesByLanguage } from '../../../voice.selector';
import VoiceCard from '../../card.component';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Theme } from '@material-ui/core/styles';
// import Help from '../../../../help/components/help.component';
import OutlinedPlayerButton from '../../../../player/components/button/outlined.component';
import appConfig from '../../../../../application/app.config';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 1),
  },
  title: {
    fontWeight: 'lighter',
  },
  divider: {
    margin: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  help: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  card: {
    width: '85%',
  },
  selection: {
    width: '15%',
    paddingRight: 0,
  },
}));

export interface IProps {
  selectedVoiceId: string;
  setSelectedVoiceId: Dispatch<SetStateAction<string>>;
}

export default function VoicesList(props: IProps) {
  const { selectedVoiceId, setSelectedVoiceId } = props;
  const voices = useSelector(selectVoicesByLanguage)(appConfig.defaultlanguage);
  const classes = useStyles();
  const { t } = useTranslation();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const voice = JSON.parse(event.target.value);
    setSelectedVoiceId(voice.id.toString());
  }

  return (
    <div id="voice-list" className={classes.list}>
      <Typography className={classes.title} variant="h6">
        {t('message.voice.french')}
      </Typography>
      <Divider className={classes.divider} variant="fullWidth" />
      {voices &&
        voices.map((voice: Voice) => {
          return (
            <div key={voice.id} className={classes.item}>
              <VoiceCard available voice={voice as Voice} classe={classes.card}>
                <OutlinedPlayerButton
                  id={voice.id.toString()}
                  path={voice.voice_url}
                />
              </VoiceCard>
              <Radio
                className={classes.selection}
                checked={selectedVoiceId === voice.id.toString()}
                onChange={handleChange}
                value={JSON.stringify(voice)}
                name="voice-selection"
              />
            </div>
          );
        })}
      {/* <div className={classes.help}>
        <Help />
      </div> */}
    </div>
  );
}

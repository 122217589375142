import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import {
  getContentDependingOnStep,
  getToBannerContentDependingOnStep,
} from 'modules/sound/helpers/selection.helpers';

import * as orderAction from 'modules/order/order.action';
import * as playerAction from 'modules/player/player.action';
import { selectPlayerState } from 'modules/player/player.selector';

import { getRouteMetaDataFromPathName } from 'router/routes.config';
import { SoundSource } from 'modules/order/config/order.config';
import { PlayerStateEnum } from 'modules/player/config/player.config';
import config, { Steps } from 'modules/sound/config/selection.config';

import Progress from 'modules/utils/components/progress.component';
import TopBanner from 'modules/banner/components/topBanner.component';
import BottomBanner from 'modules/banner/components/bottomBanner.component';

import { ValidUpdateSound } from '../../@types/order';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: `calc(var(--vh, 1vh) * 100)`,
    },
    content: {
      display: 'flex',
      flex: 1,
      padding: `${115}px ${theme.spacing(1)}px ${
        59 + theme.spacing(5)
      }px ${theme.spacing(1)}px`,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    next: {
      color: theme.palette.common.white,
    },
    previous: {
      color: theme.palette.common.white,
    },
  })
);

export default function SoundSelection() {
  const [activeStep, setActiveStep] = React.useState(Steps.STEP_1);
  const [selectedSoundId, setSelectedSoundId] = React.useState<string>('');
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const playerState = useSelector(selectPlayerState);

  function handlePreviousClick() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    if (activeStep === Steps.STEP_2) {
      setActiveStep(Steps.STEP_1);
      setSelectedSoundId('');
    } else {
      history.goBack();
    }
  }

  async function handleNextClick() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    const sound: ValidUpdateSound = {
      sound_id: selectedSoundId,
      sound_source: SoundSource.LIBRARY,
    };
    await dispatch(orderAction.saveSound(sound));

    history.goBack();
  }

  return (
    <div id="selection-view" className={classes.container}>
      <TopBanner
        title={routeMetaData && t(`${routeMetaData.name}`)}
        content={t(`${getToBannerContentDependingOnStep(activeStep)}`)}
      />

      <div id="content" className={classes.content}>
        {activeStep &&
          getContentDependingOnStep(
            activeStep,
            setActiveStep,
            selectedSoundId,
            setSelectedSoundId
          )}
      </div>

      <BottomBanner
        progress={<Progress percentage={activeStep / config.nbStep} />}
        Previous={(props: unknown) => (
          <Button
            onClick={handlePreviousClick}
            disabled={activeStep === Steps.STEP_2 ? true : false}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: unknown) => (
          <Button
            type="submit"
            disabled={selectedSoundId === '' ? true : false}
            onClick={handleNextClick}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.next')}
          </Button>
        )}
      />
    </div>
  );
}

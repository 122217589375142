import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Voice } from '../../../@types/state/voice';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  thumbnail: {
    margin: `0 ${theme.spacing(2)}px`,
    borderRadius: '7%',
    width: 83,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
  text: {
    color: theme.palette.text.secondary,
  },
  available: {
    color: theme.palette.success.main,
    flex: 1,
    '&:before': {
      content: '""',
      display: 'inline-block',
      flex: 1,
      marginRight: theme.spacing(1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: '50%',
      verticalAlign: 'top',
      background: theme.palette.success.main,
    },
  },
  notAvailable: {
    color: theme.palette.error.main,
    '&:before': {
      content: '""',
      display: 'inline-block',
      marginRight: theme.spacing(1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: '50%',
      verticalAlign: 'top',
      background: theme.palette.error.main,
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IProps {
  available?: boolean;
  children?: React.ReactChild;
  voice: null | Voice;
  classe?: string;
}

export default function VoiceCard(props: IProps) {
  const { available, children, voice, classe } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card
      id="voice-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <CardContent className={classes.content}>
        {voice && (
          <>
            <img
              src={voice.avatar_url}
              alt="voice"
              className={classes.thumbnail}
            />
            <div className={classes.description}>
              <Typography className={classes.text} variant="h6">
                {voice.name}
              </Typography>
              {available && (
                <>
                  {voice.available && (
                    <Typography className={classes.available} variant="caption">
                      {t('message.voice.available')}
                    </Typography>
                  )}
                  {!voice.available && (
                    <Typography
                      className={classes.notAvailable}
                      variant="caption"
                    >
                      {`${t('message.voice.notAvailable')}`}
                    </Typography>
                  )}
                </>
              )}
              <Typography className={classes.text}>{voice.labels}</Typography>
            </div>
          </>
        )}
      </CardContent>
      {children && <div className={classes.action}>{children}</div>}
    </Card>
  );
}

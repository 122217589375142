import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Endpoint } from 'router/routes.config';
import { replaceParams } from 'router/helpers/router.helpers';
import { useQuery } from 'modules/utils/hook';
import { MessageActions } from 'modules/message/config/message.config';

import { Order } from '../../../@types/state/order';
import { Message } from '../../../@types/state/message';

interface IProps {
  order: Order;
  message?: Message;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

export default function ActionMenu({
  order,
  message,
  anchorEl,
  setAnchorEl,
}: IProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const action = query.get('action');

  // Check with credit if user can do those actions

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMessageNavigate() {
    if (message) {
      const path = `${replaceParams(
        Endpoint.MESSAGE,
        [/:messageId/],
        [message.id.toString()]
      )}`;
      handleClose();
      history.push(path);
    }
  }

  function handleMessageInstall() {
    if (message) {
      const path = `${replaceParams(
        Endpoint.MESSAGE,
        [/:messageId/],
        [message.id.toString()]
      )}?action=${MessageActions.CREATE_INSTAL}`;
      handleClose();
      history.push(path);
    }
  }

  function handleMessageRemix() {
    if (message) {
      const path = `${replaceParams(
        Endpoint.MESSAGE,
        [/:messageId/],
        [message.id.toString()]
      )}?action=${MessageActions.CREATE_REMIX}`;
      handleClose();
      history.push(path);
    }
  }

  function handleOrder() {
    if (!message && order) {
      const path = `${replaceParams(
        Endpoint.ORDER,
        [/:orderId/],
        [order.id.toString()]
      )}`;
      handleClose();
      history.push(path);
    }
  }

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {message && action && action === MessageActions.CREATE_INSTAL && (
        <MenuItem onClick={handleMessageInstall}>
          {t('myMessages.install')}
        </MenuItem>
      )}
      {message && action && action === MessageActions.CREATE_REMIX && (
        <MenuItem onClick={handleMessageRemix}>
          {t('myMessages.remix')}
        </MenuItem>
      )}

      {message && !action && (
        <MenuItem onClick={handleMessageNavigate}>
          {t('myMessages.viewDetails')}
        </MenuItem>
      )}
      {message && !action && (
        <MenuItem onClick={handleMessageInstall}>
          {t('myMessages.install')}
        </MenuItem>
      )}
      {message && !action && (
        <MenuItem onClick={handleMessageRemix}>
          {t('myMessages.remix')}
        </MenuItem>
      )}
      {!message && (
        <MenuItem onClick={handleOrder}>{t('myMessages.viewOrder')}</MenuItem>
      )}
    </Menu>
  );
}

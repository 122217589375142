import { getMusicInstance } from './helpers/axios.helpers';
import { SoundQuery } from '../../@types/sound';

const musicInstance = getMusicInstance();

export async function getSounds(payload: SoundQuery): Promise<any> {
  return musicInstance.post('/resources/search', payload);
}

export async function getSoundsWithSearchParams(
  payload: SoundQuery
): Promise<any> {
  return musicInstance.post('/resources/search', payload);
}

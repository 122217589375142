export enum RecordState {
  NOT_RECORDING = 'NOT_RECORDING',
  RECORDING = 'RECORDING',
}

export enum PlayRecordState {
  NOT_PLAYING = 'NOT_PLAYING',
  PLAYING = 'PLAYING',
}

export enum RecordType {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  COMPANY_NAME = 'COMPANY_NAME',
  CUSTOM_VOICE = 'CUSTOM_VOICE'
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';

import * as playerAction from 'modules/player/player.action';
import * as orderAction from 'modules/order/order.action';
import * as snackbarAction from 'modules/snackbar/snackbar.action';
import * as soundAction from 'modules/sound/sound.action';

import {
  selectMessageById,
  selectFetchPending as selectFetchPendingMessage,
} from 'modules/message/message.selector';
import { selectFetchPending as selectFetchPendingOrder } from 'modules/order/order.selector';
import {
  selectHasCreditInstall,
  selectHasCreditRemix,
} from 'modules/info/info.selector';
import { selectInprogressOrder } from 'modules/order/order.selector';
import { selectPlayerState } from 'modules/player/player.selector';
import { selectInfos } from 'modules/info/info.selector';

import { useQuery } from 'modules/utils/hook';
import { MessageActions } from 'modules/message/config/message.config';
import { PlayerStateEnum } from 'modules/player/config/player.config';
import { Endpoint } from 'router/routes.config';
import { SoundSource } from 'modules/order/config/order.config';
import appConfig from 'application/app.config';

import BottomBanner from 'modules/banner/components/bottomBanner.component';
import MessagePlayerCard from 'modules/message/components/player/card.component';
import MessageCard from 'modules/message/components/card.component';
import Loader from 'modules/utils/components/loader.component';

import { SoundQuery } from '../../@types/sound';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    content: {
      flex: 1,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(10),
    },
    title: {
      fontWeight: 'bold',
    },
    divider: {
      margin: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
    },
    card: {
      marginBottom: theme.spacing(2),
    },
    backdrop: {
      display: 'flex',
      justifyContent: 'center',
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },

    actions: {
      marginTop: theme.spacing(2),
    },
    button: {
      color: theme.palette.common.white,
    },
  })
);

interface ParamTypes {
  messageId: string;
}

export default function Message() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { messageId } = useParams<ParamTypes>();
  const message = useSelector(selectMessageById)(messageId);
  const fetchPendingMessage = useSelector(selectFetchPendingMessage);
  const fetchPendingOrder = useSelector(selectFetchPendingOrder);
  const playerState = useSelector(selectPlayerState);
  const hasCreditInstall = useSelector(selectHasCreditInstall);
  const hasCreditRemix = useSelector(selectHasCreditRemix);
  const inprogressOrder = useSelector(selectInprogressOrder);
  const infos = useSelector(selectInfos);

  const loading = fetchPendingMessage || fetchPendingOrder;

  const hasValidSoundRemix =
    !!inprogressOrder && inprogressOrder.sound_id
      ? true
      : !!inprogressOrder &&
        inprogressOrder.sound_source === SoundSource.NO_SOUND
      ? true
      : false;

  const query = useQuery();
  const action = query.get('action');

  function handlePreviousClick() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    dispatch(orderAction.resetInProgressOrder());
    history.goBack();
  }

  function noCredit() {
    dispatch(
      snackbarAction.enqueueSnackbar({
        message: `${t('snackbar.error.noCredit')}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          action: (key: number) => (
            <Button
              style={{ color: 'white' }}
              onClick={() => dispatch(snackbarAction.closeSnackbar(key))}
            >
              {t('action.close')}
            </Button>
          ),
        },
      })
    );
  }

  async function handleNavigateToRemix() {
    const soundQuery: SoundQuery = {
      query: '',
      tempo_min: 0,
      tempo_max: 5,
      offset: 0,
      size: parseInt(`${appConfig.search.sound.limit}`),
      categories: null,
      types: null,
    };
    await dispatch(soundAction.getSounds(soundQuery));

    if (hasCreditRemix) {
      const path = `${Endpoint.SOUND_SELECTION}`;
      history.push(path);
    } else {
      noCredit();
    }
  }

  function handleValidateRemix() {
    if (hasCreditRemix) {
      if (!message) return;
      if (!inprogressOrder) return;
      if (!infos?.delay_before_install) return;

      const { order_id } = message;

      const payload: orderAction.ICreateRemix = {
        order_id: order_id,
        sound_id: inprogressOrder.sound_id,
        sound_source: inprogressOrder.sound_source,
        install_date: inprogressOrder.install_date,
        delay_before_install:
          infos?.delay_before_install[MessageActions.CREATE_REMIX],
      };
      dispatch(orderAction.createRemix(payload));
    } else {
      noCredit();
    }
  }

  function handleNavigateToSchedule(messageAction: MessageActions) {
    const path = `${Endpoint.SCHEDULER_SELECTION}?action=${messageAction}`;
    history.push(path);
  }

  function handleValidateInstall() {
    if (hasCreditInstall) {
      if (!message) return;
      if (!inprogressOrder) return;
      if (!infos?.delay_before_install) return;

      const payload: orderAction.ICreateInstall = {
        message_id: message.id,
        install_date: inprogressOrder.install_date,
        delay_before_install:
          infos?.delay_before_install[MessageActions.CREATE_INSTAL],
      };

      dispatch(orderAction.createInstall(payload));
    } else {
      noCredit();
    }
  }

  return (
    <div id="message-view" className={classes.container}>
      <div id="content" className={classes.content}>
        {(!message || loading) && (
          <Backdrop className={classes.backdrop} open>
            <Loader />
          </Backdrop>
        )}
        {message && (
          <>
            <section id="message">
              <Typography className={classes.title} variant="h6">
                {t('myMessages.yourMessage')}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              <MessagePlayerCard classe={classes.card} message={message} info />
            </section>

            <section id="details">
              <Typography className={classes.title} variant="h6">
                {t('message.installation.messageDetails')}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              {!action && <MessageCard message={message} />}
              {action && (
                <MessageCard
                  message={message}
                  startignOn={
                    inprogressOrder && inprogressOrder.install_date
                      ? new Date(
                          inprogressOrder.install_date
                        ).toLocaleDateString('fr-Fr', {
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      : t('message.scheduler.defaultMessage')
                  }
                />
              )}
            </section>

            <section id="actions" className={classes.actions}>
              <Grid container spacing={2} direction="column">
                {action && action === MessageActions.CREATE_INSTAL && (
                  <Grid item>
                    <Button
                      onClick={() =>
                        handleNavigateToSchedule(MessageActions.CREATE_INSTAL)
                      }
                      fullWidth
                      variant="contained"
                      color="secondary"
                    >
                      {t('myMessages.startingOn')}
                    </Button>
                  </Grid>
                )}
                {action && action === MessageActions.CREATE_REMIX && (
                  <>
                    <Grid item>
                      <Button
                        onClick={handleNavigateToRemix}
                        fullWidth
                        variant="contained"
                        color="secondary"
                      >
                        {t('message.soundChoice')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() =>
                          handleNavigateToSchedule(MessageActions.CREATE_REMIX)
                        }
                        fullWidth
                        variant="contained"
                        color="secondary"
                      >
                        {t('myMessages.startingOn')}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </section>
          </>
        )}
      </div>
      <BottomBanner
        Previous={(props: unknown) => (
          <Button
            onClick={handlePreviousClick}
            classes={{ root: classes.button }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: unknown) => {
          if (action && action === MessageActions.CREATE_REMIX) {
            return (
              <Button
                onClick={handleValidateRemix}
                disabled={!hasValidSoundRemix}
                classes={{ root: classes.button }}
                {...props}
              >
                {t('action.validate')}
              </Button>
            );
          } else if (action && action === MessageActions.CREATE_INSTAL) {
            return (
              <Button
                onClick={handleValidateInstall}
                classes={{ root: classes.button }}
                {...props}
              >
                {t('action.validate')}
              </Button>
            );
          } else {
            return null;
          }
        }}
      />
    </div>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';

import { selectOrderById } from 'modules/order/order.selector';

import BottomBanner from 'modules/banner/components/bottomBanner.component';
import MessagePlayerCard from 'modules/message/components/player/card.component';
import OrderCard from 'modules/order/components/card.component';
import Loader from 'modules/utils/components/loader.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    content: {
      flex: 1,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(10),
    },
    title: {
      fontWeight: 'bold',
    },
    divider: {
      margin: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
    },
    card: {
      marginBottom: theme.spacing(2),
    },
    backdrop: {
      display: 'flex',
      justifyContent: 'center',
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },

    actions: {
      marginTop: theme.spacing(2),
    },
    button: {
      color: theme.palette.common.white,
    },
  })
);

interface ParamTypes {
  orderId: string;
}

export default function Message() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { orderId } = useParams<ParamTypes>();
  const order = useSelector(selectOrderById)(orderId);

  function handlePreviousClick() {
    history.goBack();
  }

  return (
    <div id="message-view" className={classes.container}>
      <div id="content" className={classes.content}>
        {!order && (
          <Backdrop className={classes.backdrop} open>
            <Loader />
          </Backdrop>
        )}
        {order && (
          <>
            <section id="order">
              <Typography className={classes.title} variant="h6">
                {t('order.yourOrder')}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              <MessagePlayerCard classe={classes.card} isDisabled info />
            </section>

            <section id="details">
              <Typography className={classes.title} variant="h6">
                {t('order.detail')}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              {order && <OrderCard order={order} />}
            </section>
          </>
        )}
      </div>
      <BottomBanner
        Previous={(props: unknown) => (
          <Button
            onClick={handlePreviousClick}
            classes={{ root: classes.button }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: unknown) => {
          return null;
        }}
      />
    </div>
  );
}

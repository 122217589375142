import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '../snackbar.action';
import { selectSnackbarQueue } from '../snackbar.selector';
import { SnackbarMessage } from '../../../@types/state/snackbar';

let displayed: SnackbarMessage[] = [];

const Notifier = () => {
  const dispatch = useDispatch();

  const snackbarQueue = useSelector(selectSnackbarQueue);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    if (!snackbarQueue) return;
    snackbarQueue.forEach(
      ({ key, message, options = {}, dismissed = false }: any) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey as number));
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [closeSnackbar, enqueueSnackbar, dispatch, snackbarQueue]);

  return null;
};

export default Notifier;

import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Sound } from '../../../@types/state/sound';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '100%',
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(0, 2),
  },
  thumbnail: {
    width: 83,
    margin: theme.spacing(0, 2),
  },
  text: {
    color: theme.palette.text.secondary,
    textTransform: 'capitalize',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IProps {
  children?: React.ReactChild;
  sound: null | Sound;
  classe?: string;
}

export default function SoundCard(props: IProps) {
  const { children, sound, classe } = props;
  const classes = useStyles();

  return (
    <Card
      id="sound-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <CardContent className={classes.content}>
        {sound && (
          <>
            <img
              src={sound.thumbnail_mini ? sound.thumbnail_mini : ''}
              alt="sound"
              className={classes.thumbnail}
            />
            <div className={classes.description}>
              <Typography className={classes.text} variant="h6">
                {sound.name}
              </Typography>
              <Typography className={classes.text}>{sound.artist}</Typography>
            </div>
          </>
        )}
      </CardContent>
      {children && <div className={classes.action}>{children}</div>}
    </Card>
  );
}

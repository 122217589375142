import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';

import * as playerAction from '../../../../player/player.action';
import { selectPlayerState } from '../../../../player/player.selector';
import { PlayerStateEnum } from '../../../../player/config/player.config';

import appConfig from '../../../../../application/app.config';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  dialogContent: {
    padding: theme.spacing(4),
    minWidth: '80vw',
  },
}));

interface IProps {
  types: string[];
  setTypes: React.Dispatch<React.SetStateAction<string[]>>;
  categories: string[];
  setCategories: React.Dispatch<React.SetStateAction<string[]>>;
  search: () => void;
  classe?: string;
}

export default function Filter({
  types,
  setTypes,
  categories,
  setCategories,
  classe,
  search,
}: IProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const playerState = useSelector(selectPlayerState);

  function handleClick(event: React.MouseEvent<unknown>) {
    setOpen(true);
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
  }

  function handleClose() {
    setOpen(false);
    if (search) {
      search();
    }
  }

  function handleChangeType(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;

    if (checked) {
      setTypes([...types, name]);
    } else {
      const newTypes = types.filter((type: string) => type !== name);
      setTypes(newTypes);
    }
  }

  function handleChangeCategory(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;

    if (checked) {
      setCategories([...categories, name]);
    } else {
      const newCategories = categories.filter(
        (category: string) => category !== name
      );
      setCategories(newCategories);
    }
  }

  return (
    <>
      <Button
        id="help"
        variant="contained"
        color="secondary"
        className={`${classe ? clsx(classe, classes.button) : classes.button}`}
        onClick={handleClick}
      >
        {types.length === 0 && categories.length === 0 && t('action.filter')}
        {(types.length !== 0 || categories.length !== 0) &&
          t('message.sound.nbFilter', {
            count: types.length + categories.length,
          })}
      </Button>

      <Dialog onClose={handleClose} open={open}>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column" spacing={2}>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="h6">Catégorie</Typography>
              </Grid>
              {appConfig.sound.categories.map(
                (category: { label: string; value: string }) => {
                  const isChecked = !!categories.find(
                    (selected: string) => selected === category.value
                  );

                  return (
                    <FormControlLabel
                      key={category.label}
                      value={category.value}
                      control={
                        <CheckBox
                          name={category.value}
                          checked={isChecked}
                          color="secondary"
                          onChange={handleChangeCategory}
                        />
                      }
                      label={category.label}
                      labelPlacement="start"
                    />
                  );
                }
              )}
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item container direction="column">
              <Grid item>
                <Typography variant="h6">Genre</Typography>
              </Grid>
              {appConfig.sound.types.map(
                (type: { label: string; value: string }) => {
                  const isChecked = !!types.find(
                    (selected: string) => selected === type.value
                  );

                  return (
                    <FormControlLabel
                      key={type.label}
                      value={type.value}
                      control={
                        <CheckBox
                          name={type.value}
                          checked={isChecked}
                          color="secondary"
                          onChange={handleChangeType}
                        />
                      }
                      label={type.label}
                      labelPlacement="start"
                    />
                  );
                }
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('action.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minWidth: '90vw',
    marginBottom: theme.spacing(1),
    '& .MuiCardContent-root': {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'center',
    lineHeight: 'normal',
  },
  messageTitle: {
    textAlign: 'center',
    fontWeight: 500,
  },
}));

export default function TemplateCard() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.title}
          gutterBottom
          color="textSecondary"
        >
          {t('onboarding.step.2.textChoice')}
        </Typography>
        <Typography
          className={classes.messageTitle}
          gutterBottom
          color="textSecondary"
        >
          {t('onboarding.step.2.messageTitle')}
        </Typography>
        <Typography className={classes.text} color="textSecondary">
          {t('onboarding.step.2.message')}
        </Typography>
      </CardContent>
    </Card>
  );
}

import React from 'react';
import ScheduleType, {
  IProps as ScheduleTypeProps,
} from '../components/installTypes.component';
import Scheduler, {
  IProps as SchedulerProps,
} from '../components/scheduler.component';

export enum Steps {
  STEP_1 = 1,
  STEP_2 = 2,
}

export interface Step {
  id: number;
  Content: React.ElementType;
}

export interface Config {
  ndSteps: number;
  steps: Step[];
}

export default {
  nbStep: 2,
  steps: [
    {
      id: Steps.STEP_1,
      Content: (props: ScheduleTypeProps) => (
        <ScheduleType {...props}></ScheduleType>
      ),
    },
    {
      id: Steps.STEP_2,
      Content: (props: SchedulerProps) => <Scheduler {...props}></Scheduler>,
    },
  ],
};

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import {
  MapNotifications,
  Notification
} from '../../@types/state/notification';
import * as notificationServiceApi from '../../services/api/notification.service';
import { NotificationActionTypes } from './types';

export type NotificationAction =
  | GetNotificationPending
  | GetNotificationsSuccess
  | GetNotificationsError;

export interface GetNotificationPending
  extends Action<NotificationActionTypes.GET_NOTIFICATIONS_PENDING> {
  pending: boolean;
}
export interface GetNotificationsSuccess
  extends Action<NotificationActionTypes.GET_NOTIFICATIONS_SUCCESS> {
  data: State['notification']['data'];
}
export interface GetNotificationsError
  extends Action<NotificationActionTypes.GET_NOTIFICATIONS_ERROR> {
  message: any;
}

const getNotificationsPending = (pending: boolean): GetNotificationPending => {
  return { type: NotificationActionTypes.GET_NOTIFICATIONS_PENDING, pending };
};

const getNotificationsSuccess = (
  data: State['notification']['data']
): GetNotificationsSuccess => {
  return { type: NotificationActionTypes.GET_NOTIFICATIONS_SUCCESS, data };
};

const getNotificationsError = (message: string): GetNotificationsError => {
  return { type: NotificationActionTypes.GET_NOTIFICATIONS_ERROR, message };
};

export const getNotifications = (): ThunkAction<
  Promise<void>,
  State,
  {},
  NotificationAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, NotificationAction>
  ): Promise<void> => {
    dispatch(getNotificationsPending(true));
    try {
      const response = await notificationServiceApi.getNotifications();

      if (response.status === 200) {
        const { data } = response;
        const normalizedData = data.reduce(
          (accumulator: MapNotifications, currentValue: Notification) => {
            accumulator[currentValue['id']] = currentValue;

            return accumulator;
          },
          {}
        );
        dispatch(getNotificationsSuccess({ notifications: normalizedData }));
      }
    } catch (error) {
      dispatch(getNotificationsError(error.message));
    }
  };
};

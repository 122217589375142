import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { SoundActionTypes } from './types';
import { SoundAction } from './sound.action';

export const initialState = {
  pending: false,
  data: {
    sounds: [],
    soundsViewMore: []
  },
  initFetch: true,
  errors: undefined,
};

const sound: Reducer<State['sound'], SoundAction> = (
  state: State['sound'] = initialState,
  action: SoundAction
) => {
  switch (action.type) {
    case SoundActionTypes.GET_SOUNDS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case SoundActionTypes.GET_SOUNDS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case SoundActionTypes.GET_SOUNDS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: { sounds: [...state.data.sounds, ...action.data.sounds], soundsViewMore: action.data.soundsViewMore },
        initFetch: false,
      };
    }
    case SoundActionTypes.GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default sound;

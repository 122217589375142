import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import VoiceCard from '../../../../voice/components/card.component';
import appConfig from '../../../../../application/app.config';
import { selectVoicesByLanguage } from '../../../../voice/voice.selector';
import {
  getTemplateIdByLanguage,
  selectTemplateById,
} from '../../../../template/template.selector';
import { selectInprogressOrder } from '../../../../order/order.selector';

import { inProgressMultilingual } from '../../../../../@types/order';
import { Voice as VoiceType } from '../../../../../@types/state/voice';

import OutlinedPlayerButton from '../../../../player/components/button/outlined.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    paddingTop: theme.spacing(2),
  },
  language: {
    padding: theme.spacing(2),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 1),
  },
  divider: {
    margin: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  card: {
    width: '85%',
  },
  selection: {
    width: '15%',
    paddingRight: 0,
  },
}));

interface IProps {
  setIsStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  languages: inProgressMultilingual;
  setLanguages: React.Dispatch<React.SetStateAction<inProgressMultilingual>>;
}

export default function Voice(props: IProps) {
  const { setIsStepValid, languages, setLanguages } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const languagesKeys = Object.keys(languages);
  const getVoicesByLanguage = useSelector(selectVoicesByLanguage);
  const geTemplateIdByLanguage = useSelector(getTemplateIdByLanguage);
  const getTemplateById = useSelector(selectTemplateById);
  const inprogressOrder = useSelector(selectInprogressOrder);

  // valid step when each selected language has voice selected
  React.useEffect(() => {
    for (const [, value] of Object.entries(languages)) {
      if (!value.voice_id && value.language !== appConfig.defaultlanguage) {
        setIsStepValid(false);
      } else {
        setIsStepValid(true);
      }
    }
  }, [languages, setIsStepValid]);

  // valid step only if voice is available
  React.useEffect(() => {
    languagesKeys.forEach((selectedLanguage: string) => {
      const voice = getVoicesByLanguage(selectedLanguage);
      if (voice.length === 0) {
        setIsStepValid(false);
      }
    });
  }, [getVoicesByLanguage, languagesKeys, setIsStepValid]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const decodeValue = JSON.parse(event.target.value);
    const defaultLanguage = languages[appConfig.defaultlanguage];
    // case : default template or user selection when default language is selected
    if (defaultLanguage && defaultLanguage.template_id) {
      const template = getTemplateById(defaultLanguage.template_id.toString());
      if (template) {
        const translation = geTemplateIdByLanguage(
          template.ats_id,
          decodeValue.selectedLanguage
        );

        if (translation) {
          setLanguages({
            ...languages,
            [decodeValue.selectedLanguage]: {
              language: decodeValue.selectedLanguage,
              voice_id: decodeValue.voice_id,
              template_id: translation.template_id,
            },
          });
        }
      }
      // case : default template or user selection when default language is not selected
    } else if (
      !defaultLanguage &&
      inprogressOrder &&
      inprogressOrder.template_id
    ) {
      const template = getTemplateById(inprogressOrder.template_id.toString());
      if (template) {
        const translation = geTemplateIdByLanguage(
          template.ats_id,
          decodeValue.selectedLanguage
        );
        if (translation) {
          setLanguages({
            ...languages,
            [decodeValue.selectedLanguage]: {
              language: decodeValue.selectedLanguage,
              voice_id: decodeValue.voice_id,
              template_id: translation.template_id,
            },
          });
        }
      }
    } else {
      // case: custom template
      setLanguages({
        ...languages,
        [decodeValue.selectedLanguage]: {
          ...languages[decodeValue.selectedLanguage],
          voice_id: decodeValue.voice_id,
        },
      });
    }
  }

  return (
    <div id="voice-selection" className={classes.container}>
      {languagesKeys.map((selectedLanguage: string) => {
        const voicesByLanguage = getVoicesByLanguage(selectedLanguage);

        return (
          <React.Fragment key={selectedLanguage}>
            {selectedLanguage !== appConfig.defaultlanguage && (
              <div className={classes.language}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  {t(`multilingual.${selectedLanguage}`)}
                </Typography>

                <div id="voice-list" className={classes.list}>
                  <Divider className={classes.divider} variant="fullWidth" />

                  {voicesByLanguage && voicesByLanguage.length > 0 ? (
                    voicesByLanguage.map((voice: VoiceType) => {
                      return (
                        <div key={voice.id} className={classes.item}>
                          <VoiceCard
                            available
                            voice={voice}
                            classe={classes.card}
                          >
                            <>
                              {voice.voice_url.length > 0 && (
                                <OutlinedPlayerButton
                                  id={voice.id.toString()}
                                  path={voice.voice_url}
                                />
                              )}
                            </>
                          </VoiceCard>
                          <Radio
                            className={classes.selection}
                            checked={
                              languages[selectedLanguage].voice_id === voice.id
                            }
                            onChange={handleChange}
                            value={JSON.stringify({
                              voice_id: voice.id,
                              selectedLanguage: selectedLanguage,
                            })}
                            name="voice-selection"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <Typography>
                      {t('multilingual.voiceNotSuported')}
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

import { getAuthenticatedInstance } from './helpers/axios.helpers';

const authenticatedInstance = getAuthenticatedInstance();

export async function getTemplates(): Promise<any> {
  return authenticatedInstance.get('/templates');
}

export async function getCategories(): Promise<any> {
  return authenticatedInstance.get('/template_categories');
}

import { getAuthenticatedInstance } from './helpers/axios.helpers';
import { ValidUpdateUserInfo, ValidUpdateOnboarding } from '../../@types/line';

const authenticatedInstance = getAuthenticatedInstance();

export async function setLineUserInfo(
  userInfo: ValidUpdateUserInfo
): Promise<any> {
  return authenticatedInstance.patch('/line_details', userInfo);
}

export async function setOnboarding(
  payload: ValidUpdateOnboarding
): Promise<any> {
  return authenticatedInstance.patch('/line_details', payload);
}

import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';

import { selectOrders, selectFetchPending } from 'modules/order/order.selector';

import OrderList from 'modules/order/components/orderList.components';
import Loader from 'modules/utils/components/loader.component';
import BottomBanner from 'modules/banner/components/bottomBanner.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      padding: theme.spacing(2),
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    previous: {
      color: theme.palette.common.white,
    },
  })
);

export default function Messages() {
  const classes = useStyles();
  const fetchPendingMessages = useSelector(selectFetchPending);
  const orders = useSelector(selectOrders);
  const { t } = useTranslation();
  const history = useHistory();

  const isLoading = fetchPendingMessages;

  return (
    <div id="messages-view" className={classes.container}>
      {isLoading && (
        <Backdrop className={classes.backdrop} open>
          <Loader />
        </Backdrop>
      )}

      {!isLoading && <OrderList orders={orders} />}

      <BottomBanner
        Previous={(props: unknown) => (
          <Button
            onClick={() => history.goBack()}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: unknown) => null}
      />
    </div>
  );
}

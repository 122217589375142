import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {},
  },
  progress: {
    color: theme.palette.text.secondary,
  },
}));

interface IProps {
  classe?: string;
  size?: number;
}

export default function Loader(props: IProps) {
  const { classe, size } = props;
  const classes = useStyles();

  return (
    <div
      id="loader"
      className={`${classe ? clsx(classe, classes.root) : classes.root}`}
    >
      <div>
        <CircularProgress size={size} className={classes.progress} />
      </div>
    </div>
  );
}

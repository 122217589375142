import React from 'react';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import VoicemailRoundedIcon from '@material-ui/icons/VoicemailRounded';
// import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import { RouteName, Endpoint } from '../../../router/routes.config';

export interface ConfigItem {
  id: string | undefined;
  text: string;
  Icon: React.ReactElement;
  endpoint: string;
  disabled: boolean;
}

export const config = [
  {
    id: '1',
    text: RouteName.HOME,
    Icon: <HomeRoundedIcon color="primary" />,
    endpoint: Endpoint.HOME,
    disabled: false,
  },
  {
    id: '2',
    text: RouteName.MESSAGES,
    Icon: <VoicemailRoundedIcon color="primary" />,
    endpoint: Endpoint.MESSAGES,
    disabled: false,
  },
  {
    id: '3',
    text: RouteName.PROFILE_UPDATE,
    Icon: <PersonRoundedIcon color="primary" />,
    endpoint: Endpoint.PROFILE_UPDATE,
    disabled: false,
  },
  // {
  //   id: '4',
  //   text: RouteName.SCHEDULER,
  //   Icon: <AccessTimeRoundedIcon color="primary" />,
  //   endpoint: Endpoint.SCHEDULER,
  //   disabled: true,
  // },
];

import React, { Dispatch, SetStateAction } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';
// import Help from '../../../../help/components/help.component';
import appConfig from '../../../../../application/app.config';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  label: {
    paddingLeft: theme.spacing(0.5),
    marginTop: theme.spacing(2),
  },
  fieldInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  help: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}));

export interface IProps {
  setSelectedTemplateId: Dispatch<SetStateAction<string>>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  setIsStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TemplateCreate(props: IProps) {
  const { content, setContent, setSelectedTemplateId, setIsStepValid } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  /**
   * Valid step
   */
  React.useEffect(() => {
    if (content.length > 0) {
      setIsStepValid(true);
    } else {
      setIsStepValid(false);
    }
  }, [content, setIsStepValid]);

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;
    if (text.length <= appConfig.templateMaxLength) {
      setContent(text);
      setSelectedTemplateId('');
    }
  }

  return (
    <div id="template-create" className={classes.container}>
      <InputLabel htmlFor="lastName" className={classes.label}>
        {t('message.text.create.label')}
      </InputLabel>
      <TextField
        id="content"
        multiline
        rows="3"
        name="content"
        autoFocus
        variant="outlined"
        margin="normal"
        type="text"
        value={content}
        placeholder={'Rédigez votre annonce'}
        onChange={handleOnChange}
        color="secondary"
      />
      <div id="field-info" className={classes.fieldInfo}>
        <Typography color="textSecondary">
          {t('message.text.create.maxChar')}
        </Typography>
        <Typography color="textSecondary">{`${content.length}/${appConfig.templateMaxLength}`}</Typography>
      </div>
      {/* <div className={classes.help}>
        <Help />
      </div> */}
    </div>
  );
}

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import * as newsServiceApi from '../../services/api/news.service';
import { NewsActionTypes } from './types';

export type NewsAction = GetNewsPending | GetNewsSuccess | GetNewsError;

export interface GetNewsPending
  extends Action<NewsActionTypes.GET_NEWS_PENDING> {
  pending: boolean;
}

export interface GetNewsSuccess
  extends Action<NewsActionTypes.GET_NEWS_SUCCESS> {
  data: State['news']['data'];
}

export interface GetNewsError extends Action<NewsActionTypes.GET_NEWS_ERROR> {
  message: any;
}

const getNewsPending = (pending: boolean): GetNewsPending => {
  return { type: NewsActionTypes.GET_NEWS_PENDING, pending };
};

const getNewsSuccess = (data: State['news']['data']): GetNewsSuccess => {
  return { type: NewsActionTypes.GET_NEWS_SUCCESS, data };
};

const getNewsError = (message: string): GetNewsError => {
  return { type: NewsActionTypes.GET_NEWS_ERROR, message };
};

export const getNews = (): ThunkAction<
  Promise<void>,
  State,
  {},
  NewsAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, NewsAction>
  ): Promise<void> => {
    dispatch(getNewsPending(true));
    try {
      const response = await newsServiceApi.getNews();

      if (response.status === 200) {
        const { data } = response;
        dispatch(getNewsSuccess({ news: data }));
      }
    } catch (error) {
      dispatch(getNewsError(error.message));
    }
  };
};

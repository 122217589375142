import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import logo from '../../../../application/images/logo-installation.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100%',
      zIndex: theme.zIndex.drawer + 2,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    descriptionContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '20%',
      backgroundColor: '#FAFAFB',
    },
    logo: {
      marginLeft: theme.spacing(2),
    },
    description: {
      fontWeight: 'bold',
      color: '#585C5D',
      '&::after': {
        content: '"by ATS Studio"',
        display: 'block',
        fontSize: theme.typography.caption.fontSize,
        color: '#808E95DE',
      },
    },
    button: {
      height: '10%',
      backgroundColor: '#1EA0FB',
      color: theme.palette.common.white,
      borderRadius: 0,
    },
  })
);

interface IProps {
  visible: boolean;
}

export default function InstallationPopup(props: IProps) {
  const [clicked, setClicked] = React.useState(false);
  const { t } = useTranslation();
  let { visible } = props;

  const classes = useStyles();

  function handleOnClick() {
    setClicked(true);
    // @ts-ignore
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      return;
    }
    promptEvent.prompt();

    promptEvent.userChoice.then((result: any) => {
      // @ts-ignore
      window.deferredPrompt = null;
    });
  }

  function handleClickBackdrop() {
    setClicked(true);
  }

  return (
    <Backdrop
      open={visible && !clicked}
      className={classes.backdrop}
      onClick={handleClickBackdrop}
    >
      <Slide direction="down" timeout={500} in={visible && !clicked}>
        <div id="installation-button" className={classes.container}>
          <div
            id="app-description-container"
            className={classes.descriptionContainer}
          >
            <img className={classes.logo} src={logo} alt="app-logo" />
            <Typography className={classes.description}>
              {t('installPopup.title')}
            </Typography>
          </div>

          <Button fullWidth className={classes.button} onClick={handleOnClick}>
            {t('installPopup.text')}
          </Button>
        </div>
      </Slide>
    </Backdrop>
  );
}

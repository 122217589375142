import { IDelayBeforeInstall } from '../../@types/state/info';
import { CalendarType } from 'modules/info/config/info.config';

export function addDays(delayBeforeInstall: IDelayBeforeInstall) {
  let today = new Date();
  let date = new Date();

  if (!delayBeforeInstall) return date;

  if (delayBeforeInstall.calendar_type === CalendarType.OPEN_DAYS) {
    date = addBusinessDays(today, delayBeforeInstall.nb_days);
  }

  if (delayBeforeInstall.calendar_type === CalendarType.CALENDAR_DAYS) {
    date.setDate(date.getDate() + delayBeforeInstall.nb_days);
  }

  return date;
}

function addBusinessDays(date: Date, nbDayToAdd: number) {
  date = new Date(date.getTime());
  let startDay = date.getDay();
  date.setDate(
    date.getDate() +
      nbDayToAdd +
      (startDay === 6 ? 2 : +!startDay) +
      Math.floor((nbDayToAdd - 1 + (startDay % 6 || 1)) / 5) * 2
  );

  return date;
}

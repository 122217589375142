export enum MessageActionTypes {
  GET_MESSAGES_PENDING = '@@message/GET_MESSAGES_PENDING',
  GET_MESSAGES_SUCCESS = '@@message/GET_MESSAGES_SUCCESS',
  GET_MESSAGES_ERROR = '@@message/GET_MESSAGES_ERROR',
  SET_CURRENT_MESSAGE_PENDING = '@@message/SET_CURRENT_MESSAGE_PENDING',
  SET_CURRENT_MESSAGE_SUCCESS = '@@message/SET_CURRENT_MESSAGE_SUCCESS',
  SET_CURRENT_MESSAGE_ERROR = '@@message/SET_CURRENT_MESSAGE_ERROR',
  INSTALLATION_MESSAGE_PENDING = '@@message/APPROUVAL_MESSAGE_PENDING',
  INSTALLATION_MESSAGE_SUCCESS = '@@message/APPROUVAL_MESSAGE_SUCCESS',
  INSTALLATION_MESSAGE_ERROR = '@@message/APPROUVAL_MESSAGE_ERROR',
  REFUSE_MESSAGE_PENDING = '@@message/REFUSE_MESSAGE_PENDING',
  REFUSE_MESSAGE_SUCCESS = '@@message/REFUSE_MESSAGE_SUCCESS',
  REFUSE_MESSAGE_ERROR = '@@message/REFUSE_MESSAGE_ERROR',
}

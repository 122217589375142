// import { getLoginResponse } from './mock/auth/auth.mock';
import { getInstance } from './helpers/axios.helpers';
import { getAuthenticatedInstance } from './helpers/axios.helpers';
import {
  ValidAskResetPasswordLinkPayload,
  ValidResetPasswordPayload,
  ValidRefreshToken,
} from '../../@types/auth';

const instance = getInstance();
const authenticatedInstance = getAuthenticatedInstance();

export async function login({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<any> {
  return instance.post('/rpc/login', { login: email, password: password });
}

export async function askResetPasswordLink(
  login: ValidAskResetPasswordLinkPayload
): Promise<any> {
  return instance.post('/rpc/reset_password', login);
}

export async function resetPassword(
  password: ValidResetPasswordPayload
): Promise<any> {
  return authenticatedInstance.post('/rpc/change_password', password);
}

export async function refreshToken(
  refreshToken: ValidRefreshToken
): Promise<any> {
  return instance.post('/rpc/refresh_token', refreshToken);
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import OrderListItem from 'modules/order/components/orderListItem.components';

import { Order } from '../../../@types/state/order';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  })
);

interface IProps {
  orders: Order[];
}

export default function OrderList({ orders }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List
      className={classes.container}
      subheader={
        <ListSubheader style={{ fontSize: 16 }}>
          {t('myMessages.orderList')}
        </ListSubheader>
      }
    >
      {orders.length === 0 && (
        <>
          <ListItem>
            <ListItemText primary="aucun message" />
          </ListItem>
          <Divider />
        </>
      )}

      {orders.length > 0 &&
        orders.map((order: Order) => (
          <OrderListItem key={order.id} order={order} />
        ))}
    </List>
  );
}

import {
  VariableText,
  VariableNumber,
  VariableDate,
  VariableTel,
} from '../components/dynamicVariableFields';

import { DynamicVariableProps, DynamicVariableType } from '../types';

interface Config {
  DYNAMIC_VARIABLE_TYPES: Record<
    string,
    { component: React.ElementType<DynamicVariableProps> }
  >;
}

const config: Config = {
  DYNAMIC_VARIABLE_TYPES: {
    [DynamicVariableType.TEXT]: {
      component: VariableText,
    },
    [DynamicVariableType.NUMBER]: {
      component: VariableNumber,
    },
    [DynamicVariableType.DATE]: {
      component: VariableDate,
    },
    [DynamicVariableType.TEL]: {
      component: VariableTel,
    },
  },
};

export default config;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Endpoint } from '../../../router/routes.config';
import * as orderAction from '../../order/order.action';
import { selectInprogressOrder } from '../../order/order.selector';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

interface IProps {
  classe?: string;
  disabled ?: boolean
}

export default function MultilingualCheckbox(props: IProps) {
  const { classe, disabled } = props;
  const inprogressOrder = useSelector(selectInprogressOrder);
  const [checked, setChecked] = useState(
    inprogressOrder ? inprogressOrder.hasMultilingual : false
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setChecked(event.target.checked);
    dispatch(orderAction.activateMultilingual(event.target.checked));
    if (event.target.checked === true) {
      history.push(Endpoint.MULTILINGUAL);
    } else {
      dispatch(orderAction.saveMultilingual([]));
    }
  }

  return (
    <FormControlLabel
      id="translation"
      className={classe}
      label="Je souhaite ajouter/supprimer des langues"
      control={
        <Checkbox
          disabled={disabled}
          checked={disabled ? true : checked}
          onChange={handleChange}
          name="translation"
          icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
          checkedIcon={<CheckBoxIcon className={classes.icon} />}
        />
      }
    />
  );
}

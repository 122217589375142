import React from 'react';
import { useHistory } from 'react-router';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsAuth,
  selectRefreshtoken,
  selectFetchErrors,
  selectFetchPending,
} from '../../auth.selector';
import { ValidRefreshToken } from '../../../../@types/auth';
import * as authAction from '../../auth.action';
import { Endpoint } from '../../../../router/routes.config';

const AuthRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const refreshToken = useSelector(selectRefreshtoken);
  const fetchPending = useSelector(selectFetchPending);
  const fetchErrors = useSelector(selectFetchErrors);
  const history = useHistory();

  async function getRefreshToken(validRefreshToken: string) {
    const payload: ValidRefreshToken = {
      grant_type: 'refresh_token',
      refresh_token: validRefreshToken,
    };
    await dispatch(authAction.refreshToken(payload));

    if (fetchErrors) {
      history.push(Endpoint.LOGIN);
    }
  }

  React.useEffect(() => {
    if (!isAuth && refreshToken) {
      getRefreshToken(refreshToken);
    } else if (!isAuth && !refreshToken && !fetchPending) {
      history.push(Endpoint.LOGIN);
    }
  });

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        return Component && <Component {...props} />;
      }}
    />
  );
};

export default AuthRoute;

import * as React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as soundAction from 'modules/sound/sound.action';
import { selectSoundById } from 'modules/sound/sound.selector';
import { selectVoiceById } from 'modules/voice/voice.selector';
import { SoundSource } from 'modules/order/config/order.config';

import { Sound } from '../../../@types/state/sound';
import { Order } from '../../../@types/state/order';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `0 ${theme.spacing(2)}px`,
  },
  name: {
    fontWeight: 'bold',
  },
  text: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  scheduler: {
    color: theme.palette.text.disabled,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

interface IProps {
  classe?: string;
  order: Order;
}

export default function OrderCard(props: IProps) {
  const { classe, order } = props;
  const [selectedSoundId, setSelectedSoundId] = React.useState('');
  const [sound, setSound] = React.useState<Sound | null>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getSoundById = useSelector(selectSoundById);
  const voice = useSelector(selectVoiceById)(order.voice_id);

  // Get order sound
  React.useEffect(() => {
    if (order) {
      const { ats_sound_id } = order;
      if (ats_sound_id) {
        dispatch(
          soundAction.getSoundsWithSearchParams({
            search: ats_sound_id,
            types: [],
            categories: [],
          })
        );
        setSelectedSoundId(ats_sound_id);
      }
    }
  }, [dispatch, order, selectedSoundId]);

  // Get sound from store with sound id
  React.useEffect(() => {
    if (selectedSoundId) {
      const searchedSound = getSoundById(selectedSoundId);
      if (searchedSound) {
        setSound(searchedSound);
      }
    }
  }, [getSoundById, selectedSoundId]);

  return (
    <Card
      id="order-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <CardContent className={classes.content}>
        <Typography color="textSecondary">{t('order.title')}</Typography>
        <Typography className={clsx(classes.name, classes.text)} variant="h6">
          {order.title}
        </Typography>

        <Typography color="textSecondary">{t('order.text')}</Typography>
        <Typography className={classes.text} variant="h6">
          {order.content}
        </Typography>

        <Typography color="textSecondary">{t('order.voice')}</Typography>
        {voice && (
          <Typography className={classes.text} variant="h6">
            {voice.name}
          </Typography>
        )}
        {!voice && (
          <Typography className={classes.text} variant="h6">
            {t('order.noVoice')}
          </Typography>
        )}

        <Typography color="textSecondary">
          {t('order.soundSelected')}
        </Typography>
        {order.sound_selection === SoundSource.LIBRARY && sound && (
          <Typography className={classes.text} variant="h6">
            {sound.name} - {sound.artist}
          </Typography>
        )}

        {order.sound_selection === SoundSource.NO_SOUND && sound && (
          <Typography className={classes.text} variant="h6">
            {t('order.noSound')}
          </Typography>
        )}

        {order.sound_selection === SoundSource.CUSTOM ||
          (order.sound_selection === SoundSource.CUSTOM_ATS && (
            <Typography className={classes.text} variant="h6">
              {t('order.predefinedSound')}
            </Typography>
          ))}

        <Typography color="textSecondary">{t('order.orderDate')}</Typography>

        {order && (
          <Typography className={classes.text} variant="h6">
            {moment(order.created_at).format('dddd D MMMM YYYY à HH:mm:ss')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

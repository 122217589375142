import { State } from '../../@types/state';
import { Sound } from '../../@types/state/sound';
import { selectInprogressOrder } from '../order/order.selector';
import { selectInfos } from '../info/info.selector';
import { SoundSource } from '../order/config/order.config';

export function selectInitFetch(state: State): boolean {
  return state.sound.initFetch;
}

export function selectFetchPending(state: State): boolean {
  return state.sound.pending;
}

export function selectSounds(state: State): Sound[] {
  return state.sound.data.sounds;
}

export function selectDefaultSoundId(state: State): string | null {
  const sounds = selectSounds(state);

  if (sounds.length === 0) return null;

  return sounds[0].id;
}

export function selectSoundById(state: State) {
  const sounds = selectSounds(state);

  return function (soundId: string): Sound | null {
    const searchedSound = sounds.find((sound: Sound) => sound.id === soundId);

    if (!searchedSound) return null;

    return searchedSound;
  };
}

export function selectSoundForDisplay(state: State): Sound | null {
  const sounds = selectSounds(state);
  const deploymentInProgress = selectInprogressOrder(state);
  const getSoundById = selectSoundById(state);
  let sound: Sound | null = null;

  if (!sounds) return null;

  if (deploymentInProgress && deploymentInProgress.sound_id) {
    const { sound_id } = deploymentInProgress;

    if (sound_id) {
      sound = getSoundById(sound_id);
    }
  } else {
    const defaultSoundId = selectDefaultSoundId(state);
    if (!defaultSoundId) return null;
    sound = getSoundById(defaultSoundId);
  }

  return sound;
}

export function selectSoundIdAccordingToAccess(state: State) {
  return function (selectedSoundId: string | null): string | null {
    const sounds = selectSounds(state);
    const infos = selectInfos(state);

    if (!sounds || !infos) return null;

    const { access } = infos;

    if (access.all_granted || access.disable_sound_restriction) {
      if (selectedSoundId) {
        return selectedSoundId;
      } else {
        return selectDefaultSoundId(state);
      }
    }

    if (!access.all_granted) {
      if (access.sound_source === SoundSource.LIBRARY) {
        return access.sound_ids[0];
      }
      if (
        access.sound_source === SoundSource.CUSTOM ||
        access.sound_source === SoundSource.NO_SOUND
      ) {
        return null;
      }
    }

    return null;
  };
}

export function selectSoundSourceAccordingToAccess(state: State) {
  return function (soundSource: SoundSource): SoundSource {
    const infos = selectInfos(state);

    if (!infos) return soundSource;

    const { access } = infos;

    return access.sound_source;
  };
}

export function selectSoundsViewMore(state: State): Sound[] {
  return state.sound.data.soundsViewMore;
}

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import * as templateServiceApi from '../../services/api/template.service';
import { TemplateActionTypes } from './types';
import {
  MapTemplates,
  Template,
  MapCategories,
  Categorie,
} from '../../@types/state/template';

export type TemplateAction =
  | GetTemplatesPending
  | GetTemplatesSuccess
  | GetTemplatesError
  | GetCategoriesPending
  | GetCategoriesSuccess
  | GetCategoriesError;

export interface GetCategoriesPending
  extends Action<TemplateActionTypes.GET_CATEGORIES_PENDING> {
  pending: boolean;
}

export interface GetCategoriesSuccess
  extends Action<TemplateActionTypes.GET_CATEGORIES_SUCCESS> {
  categories: State['template']['data']['categories'];
}

export interface GetCategoriesError
  extends Action<TemplateActionTypes.GET_CATEGORIES_ERROR> {
  message: any;
}

const getCategoriesPending = (pending: boolean): GetCategoriesPending => {
  return { type: TemplateActionTypes.GET_CATEGORIES_PENDING, pending };
};

const getCategoriesSuccess = (
  data: State['template']['data']['categories']
): GetCategoriesSuccess => {
  return { type: TemplateActionTypes.GET_CATEGORIES_SUCCESS, categories: data };
};

const getCategoriesError = (message: string): GetCategoriesError => {
  return { type: TemplateActionTypes.GET_CATEGORIES_ERROR, message };
};

export const getCategories = (): ThunkAction<
  Promise<void>,
  State,
  {},
  TemplateAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, TemplateAction>
  ): Promise<void> => {
    dispatch(getCategoriesPending(true));
    try {
      const response = await templateServiceApi.getCategories();

      const { data } = response;

      const dataNormalized = data.reduce(
        (accumulator: MapCategories, currentValue: Categorie) => {
          accumulator[currentValue['id']] = currentValue;

          return accumulator;
        },
        {}
      );

      if (response.status === 200) {
        if (response) {
          dispatch(getCategoriesSuccess(dataNormalized));
        }
      }
    } catch (error) {
      dispatch(getCategoriesError(error.message));
    }
  };
};

export interface GetTemplatesPending
  extends Action<TemplateActionTypes.GET_TEMPLATES_PENDING> {
  pending: boolean;
}

export interface GetTemplatesSuccess
  extends Action<TemplateActionTypes.GET_TEMPLATES_SUCCESS> {
  templates: State['template']['data']['templates'];
}

export interface GetTemplatesError
  extends Action<TemplateActionTypes.GET_TEMPLATES_ERROR> {
  message: any;
}

const getTemplatesPending = (pending: boolean): GetTemplatesPending => {
  return { type: TemplateActionTypes.GET_TEMPLATES_PENDING, pending };
};

const getTemplatesSuccess = (
  data: State['template']['data']['templates']
): GetTemplatesSuccess => {
  return { type: TemplateActionTypes.GET_TEMPLATES_SUCCESS, templates: data };
};

const getTemplatesError = (message: string): GetTemplatesError => {
  return { type: TemplateActionTypes.GET_TEMPLATES_ERROR, message };
};

export const getTemplates = (): ThunkAction<
  Promise<void>,
  State,
  {},
  TemplateAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, TemplateAction>
  ): Promise<void> => {
    dispatch(getTemplatesPending(true));
    try {
      const response = await templateServiceApi.getTemplates();

      const { data } = response;

      const dataNormalized = data.reduce(
        (accumulator: MapTemplates, currentValue: Template) => {
          accumulator[currentValue['id']] = {
            ...currentValue,
            original_content: currentValue.content,
          };

          return accumulator;
        },
        {}
      );

      if (response.status === 200) {
        if (response) {
          await dispatch(getCategories());
          dispatch(getTemplatesSuccess(dataNormalized));
        }
      }
    } catch (error) {
      dispatch(getTemplatesError(error.message));
    }
  };
};

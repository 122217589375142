import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import TopBanner from '../../../../modules/banner/components/topBanner.component';
import BottomBanner from '../../../../modules/banner/components/bottomBanner.component';
import { OrderTitleUpdateFormData } from '../../../../@types/form/order/create';
import Button from '@material-ui/core/Button';
import { getRouteMetaDataFromPathName } from '../../../../router/routes.config';
import { ValidUpdateTitle } from '../../../../@types/order';
import { selectLineDetailId } from '../../../../modules/info/info.selector';
import { selectInprogressOrder } from '../../../../modules/order/order.selector';
import * as orderAction from '../../../../modules/order/order.action';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
    },
    content: {
      flex: 1,
      padding: `${115 + theme.spacing(5)}px ${theme.spacing(1)}px ${
        59 + theme.spacing(5)
      }px ${theme.spacing(1)}px`,
    },
    label: {
      paddingLeft: theme.spacing(0.5),
      marginTop: theme.spacing(2),
    },
    next: {
      color: theme.palette.common.white,
    },
    previous: {
      color: theme.palette.common.white,
    },
  })
);

export default function OrderTitleUpdate() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState } = useForm<
    OrderTitleUpdateFormData
  >({
    mode: 'onChange',
  });
  const lineDetailId = useSelector(selectLineDetailId);
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const inprogressOrder = useSelector(selectInprogressOrder);

  async function onSubmit(data: OrderTitleUpdateFormData) {
    if (lineDetailId) {
      const title: ValidUpdateTitle = {
        title: data.title,
      };
      await dispatch(orderAction.saveTitle(title));

      history.goBack();
    }
  }

  return (
    <form
      id="order-title-create-view"
      className={classes.container}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TopBanner
        title={routeMetaData && t(`${routeMetaData.name}`)}
        content={t('message.title.topBanner')}
      />

      <div id="content" className={classes.content}>
        <InputLabel htmlFor="title" className={classes.label}>
          {t('message.title.field')}
        </InputLabel>
        <TextField
          id="title"
          name="title"
          defaultValue={
            inprogressOrder && inprogressOrder.title
              ? inprogressOrder.title
              : ''
          }
          autoFocus
          variant="outlined"
          margin="normal"
          type="text"
          fullWidth
          autoComplete="off"
          placeholder={t('message.title.placeholder')}
          inputRef={register({ required: true })}
          color="secondary"
        />
      </div>

      <BottomBanner
        Previous={(props: unknown) => (
          <Button
            onClick={() => history.goBack()}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: unknown) => (
          <Button
            type="submit"
            disabled={!formState.isValid}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.next')}
          </Button>
        )}
      />
    </form>
  );
}

import React from 'react';
import moment from 'moment';
import { Order } from '../../../@types/state/order';
import { Message } from '../../../@types/state/message';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import StateCard from './card.component';
import { OrderEvent } from '../../order/config/order.config';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    overflow: 'auto',
  },
}));

interface IProps {
  classe?: string;
  orders: Order[];
  messages: Message[];
}

export default function StateSlider(props: IProps) {
  const { classe, orders, messages } = props;
  const classes = useStyles();
  const states = [...orders, ...messages];

  states.sort((a: Order | Message, b: Order | Message) => {
    const momentA = moment(a.created_at);
    const momentB = moment(b.created_at);

    if (momentA.diff(momentB) > 0) {
      return -1;
    }
    if (momentA.diff(momentB) > 0) {
      return -1;
    }
    return 0;
  });

  return (
    <div
      id="message-state-list"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      {states.map((state: Order | Message, index: number) => {
        // @ts-ignore
        if (state.order_id) {
          return <StateCard key={index} message={state as Message} />;
        } else {
          if (state.state === OrderEvent.PRODUCED) return null;
          return <StateCard key={index} order={state as Order} />;
        }
      })}
    </div>
  );
}

export enum SoundActionTypes {
  GET_SOUNDS_PENDING = '@@sound/GET_SOUNDS_PENDING',
  GET_SOUNDS_SUCCESS = '@@sound/GET_SOUNDS_SUCCESS',
  GET_SOUNDS_ERROR = '@@sound/GET_SOUNDS_ERROR',
  GET_SOUNDS_WITH_SEARCH_PARAMS_PENDING = '@@sound/GET_SOUNDS_WITH_SEARCH_PARAMS_PENDING',
  GET_SOUNDS_WITH_SEARCH_PARAMS_SUCCESS = '@@sound/GET_SOUNDS_WITH_SEARCH_PARAMS_SUCCESS',
  GET_SOUNDS_WITH_SEARCH_PARAMS_ERROR = '@@sound/GET_SOUNDS_WITH_SEARCH_PARAMS_ERROR',
  GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_PENDING = '@@sound/GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_PENDING',
  GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_SUCCESS = '@@sound/GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_SUCCESS',
  GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_ERROR = '@@sound/GET_SOUNDS_WITH_SEARCH_PARAMS_VIEW_MORE_ERROR',
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minWidth: '90vw',
    marginBottom: theme.spacing(1),
    '& .MuiCardContent-root': {
      padding: theme.spacing(1, 2),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    color: '#808E95',
    textAlign: 'center',
  },
  voice: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(10),
  },
  thumbnail: {
    borderRadius: '7%',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    textAlign: 'left',
    '& p': {
      color: '#808E95',
      textAlign: 'left',
    },
  },
}));

export default function Voicecard() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {t('onboarding.step.2.voiceChoice')}
        </Typography>
        <div className={classes.voice}>
          <img
            className={classes.thumbnail}
            src={`${process.env.PUBLIC_URL}/mock/voice/emmanuelle.jpeg`}
            alt="voice"
            width="60"
          />
          <div id="description" className={classes.description}>
            <Typography color="textSecondary">Emmanuelle</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

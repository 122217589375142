import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import config from '../../../config/selection.config';

import { inProgressMultilingual } from '../../../../../@types/order';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
  },
  choice: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  flag: {
    marginRight: theme.spacing(2),
  },
  order: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(4),
    width: theme.spacing(4),
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
  },
}));

interface IProps {
  setIsStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  languages: inProgressMultilingual;
  setLanguages: React.Dispatch<React.SetStateAction<inProgressMultilingual>>;
}

export default function Sort(props: IProps) {
  const { setIsStepValid, languages, setLanguages } = props;
  const languagesKeys = Object.keys(languages);
  let initialState = 1;
  for (const [, value] of Object.entries(languages)) {
    if (value.order) initialState++;
  }
  const [order, setOrder] = React.useState<number>(initialState);
  const classes = useStyles();
  const { t } = useTranslation();

  // valid step when at least each language have an order
  React.useEffect(() => {
    for (const [, value] of Object.entries(languages)) {
      if (!value.order) {
        setIsStepValid(false);

        return;
      } else {
        setIsStepValid(true);
      }
    }
  }, [languages, order, setIsStepValid]);

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    const languageKey = event.target.name;
    if (order <= languagesKeys.length) {
      const { order: orderKey } = languages[languageKey];
      if (!orderKey) {
        setLanguages({
          ...languages,
          [languageKey]: { ...languages[languageKey], order: order },
        });
        setOrder(order + 1);
      }
    }
  }

  function handleResetOrder(event: React.MouseEvent<HTMLElement>) {
    let newLanguages: inProgressMultilingual = {};
    for (const [key, value] of Object.entries(languages)) {
      const { order, ...withoutOrder } = value;

      newLanguages[key] = withoutOrder;
      setOrder(1);
      setIsStepValid(false);
    }
    setLanguages(newLanguages);
  }

  return (
    <div id="order-selection" className={classes.container}>
      <div className="">
        <FormGroup id="language-selection-choice" className={classes.choice}>
          {languagesKeys.map((selectedLanguage: string) => {
            const configContent = config.languages[selectedLanguage];

            return (
              <div className={classes.item} key={selectedLanguage}>
                <img
                  src={configContent.flag}
                  alt="flag"
                  className={classes.flag}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      size="medium"
                      checked={!!languages[selectedLanguage].order}
                      onChange={handleCheck}
                      checkedIcon={
                        <div className={classes.order}>
                          <Typography variant="h6" color="textSecondary">
                            {languages[selectedLanguage].order}
                          </Typography>
                        </div>
                      }
                    />
                  }
                  label={t(`${configContent.label}`)}
                  name={selectedLanguage}
                />
              </div>
            );
          })}
        </FormGroup>
      </div>
      {order > 1 && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleResetOrder}
        >
          {t('multilingual.resetOrder')}
        </Button>
      )}
    </div>
  );
}

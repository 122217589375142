import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import * as voiceServiceApi from '../../services/api/voice.service';
import { VoiceActionTypes } from './types';
import { MapVoices, Voice } from '../../@types/state/voice';

export type VoiceAction = GetVoicesPending | GetVoicesSuccess | GetVoicesError;

export interface GetVoicesPending
  extends Action<VoiceActionTypes.GET_VOICES_PENDING> {
  pending: boolean;
}

export interface GetVoicesSuccess
  extends Action<VoiceActionTypes.GET_VOICES_SUCCESS> {
  data: State['voice']['data'];
}

export interface GetVoicesError
  extends Action<VoiceActionTypes.GET_VOICES_ERROR> {
  message: any;
}

const getVoicesPending = (pending: boolean): GetVoicesPending => {
  return { type: VoiceActionTypes.GET_VOICES_PENDING, pending };
};

const getVoicesSuccess = (data: State['voice']['data']): GetVoicesSuccess => {
  return { type: VoiceActionTypes.GET_VOICES_SUCCESS, data };
};

const getVoicesError = (message: string): GetVoicesError => {
  return { type: VoiceActionTypes.GET_VOICES_ERROR, message };
};

export const getVoices = (): ThunkAction<
  Promise<void>,
  State,
  {},
  VoiceAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, VoiceAction>
  ): Promise<void> => {
    dispatch(getVoicesPending(true));
    try {
      const response = await voiceServiceApi.getVoices();

      const { data } = response;

      const dataNormalized = data.reduce(
        (accumulator: MapVoices, currentValue: Voice) => {
          accumulator[currentValue['id']] = currentValue;

          return accumulator;
        },
        {}
      );

      if (response.status === 200) {
        if (response) {
          dispatch(
            getVoicesSuccess({
              voices: dataNormalized,
            })
          );
        }
      }
    } catch (error) {
      dispatch(getVoicesError(error.message));
    }
  };
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { selectInprogressOrder } from '../../order.selector';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  title: {
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    minhHeight: '100%',
    padding: `0 ${theme.spacing(2)}px`,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IProps {
  children?: React.ReactChild;
  classe?: string;
}

export default function TitleCard(props: IProps) {
  const { children, classe } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const inprogressOrder = useSelector(selectInprogressOrder);

  return (
    <Card
      id="order-title-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <Typography className={classes.title}>
        {t('message.title.field')}
      </Typography>

      <CardContent className={classes.content}>
        {inprogressOrder && (
          <Typography className={classes.text}>
            {inprogressOrder.title}
          </Typography>
        )}
      </CardContent>
      {children && <div className={classes.action}>{children}</div>}
    </Card>
  );
}

import moment from 'moment';
import { State } from '../../@types/state';
import { MapOrders, Order } from '../../@types/state/order';
import { InProgressOrder } from '../../@types/order';

export function selectInitFetch(state: State): boolean {
  return state.order.initFetch;
}

export function selectFetchPending(state: State): boolean {
  return state.order.pending;
}

export function selectFetchErrors(state: State): string | undefined {
  return state.order.errors;
}

export function selectOrdersMap(state: State): MapOrders | null {
  return state.order.data.orders;
}

export function selectOrders(state: State): Order[] {
  const mapOrders = selectOrdersMap(state);

  if (!mapOrders) return [];

  const orders = Object.values(mapOrders);

  return orders.sort((a: Order, b: Order) => {
    const momentA = moment(a.created_at);
    const momentB = moment(b.created_at);

    return momentB.diff(momentA);
  });
}

export function selectInprogressOrder(state: State): InProgressOrder | null {
  const inProgressOrder = state.order.data.inProgressOrder;

  if (!inProgressOrder) return null;

  return inProgressOrder;
}

export function selectOrderById(state: State) {
  const ordersMap = selectOrdersMap(state);

  return function (orderId: number | string): Order | null {
    if (!ordersMap) return null;

    return ordersMap[orderId.toString()];
  };
}

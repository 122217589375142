import { State } from '../../@types/state';
import { Infos } from '../../@types/state/info';

export const selectInitFetch = (state: State): boolean => {
  return state.info.initFetch;
};

export function selectFetchErrors(state: State): string | undefined {
  return state.info.errors;
}

export const selectFetchPending = (state: State): boolean => {
  return state.info.pending;
};

export const selectInfos = (state: State): Infos | null => {
  const { infos } = state.info.data;

  if (!infos) return null;

  return infos;
};

export function selectThemeFromInfos(state: State): any {
  const infos = selectInfos(state);

  if (!infos) return null;

  // const { style } = infos;

  // if (!style) return null;

  return infos.style;
}

export function selectLineDetailId(state: State): number | null {
  const infos = selectInfos(state);

  if (!infos) return null;

  return infos.line_detail_id;
}

export function selectHasCreditCreate(state: State): boolean {
  const infos = selectInfos(state);

  if (!infos) return false;

  return infos.has_credit_create;
}

export function selectHasCreditInstall(state: State): boolean {
  const infos = selectInfos(state);

  if (!infos) return false;

  return infos.has_credit_install;
}

export function selectHasCreditRemix(state: State): boolean {
  const infos = selectInfos(state);

  if (!infos) return false;

  return infos.has_credit_remix;
}

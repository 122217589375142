import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 115,
    zIndex: 1200,
  },
  title: {
    paddingTop: theme.spacing(1),
    width: '100%',
    color: theme.palette.common.white,
    background: theme.palette.common.black,
    '& h6': {
      fontWeight: 'bold',
    },
  },
  content: {
    width: '100%',
    padding: theme.spacing(1, 2, 2),
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

interface IPropsScroll {
  children: React.ReactElement;
}

function HideOnScroll(props: IPropsScroll) {
  const { children } = props;

  const trigger = useScrollTrigger();

  return <Collapse in={!trigger}>{children}</Collapse>;
}

export interface IProps {
  title?: string;
  content: string;
  children?: React.ReactNode;
}

export default function TopBanner(props: IProps) {
  const { title, content, children } = props;
  const classes = useStyles();

  return (
    <div id="top-banner" className={classes.container}>
      <div id="title" className={classes.title}>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
      </div>
      <HideOnScroll {...props}>
        <div id="content" className={classes.content}>
          <Typography align="center">{content}</Typography>
          {children}
        </div>
      </HideOnScroll>
    </div>
  );
}

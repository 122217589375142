import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { LineActionTypes } from './types';
import { LineAction } from './line.action';

export const initialState = {
  pending: false,
  data: {},
  initFetch: true,
  errors: undefined,
};

const line: Reducer<State['line'], LineAction> = (
  state: State['line'] = initialState,
  action: LineAction
) => {
  switch (action.type) {
    case LineActionTypes.SET_LINE_USER_INFO_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case LineActionTypes.SET_LINE_USER_INFO_SUCCESS: {
      return {
        ...state,
        pending: false,
        initFetch: false,
      };
    }
    case LineActionTypes.SET_LINE_USER_INFO_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case LineActionTypes.SET_ONBOARDING_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case LineActionTypes.SET_ONBOARDING_SUCCESS: {
      return {
        ...state,
        pending: false,
        initFetch: false,
      };
    }
    case LineActionTypes.SET_ONBOARDING_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default line;

import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import OperatorPopover from './operator/popover.component';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  label: {
    paddingLeft: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
  },
  operator: {
    textDecoration: 'underline',
    marginBottom: theme.spacing(2),
  },
  rgpd: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

interface IProps {
  classe?: string;
  voiceMailPin: number | string;
  setVoiceMailPin: Dispatch<SetStateAction<string>>;
  savePin: boolean;
  setSavePin: Dispatch<SetStateAction<boolean>>;
}

export default function VoiceMailPin(props: IProps) {
  const { classe, voiceMailPin, setVoiceMailPin, savePin, setSavePin } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { t } = useTranslation();
  const classes = useStyles();
  const regex = /^\d+$/;

  function handleCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value;
    if (newValue === '') {
      setVoiceMailPin(newValue);
      return;
    }
    if (regex.test(newValue)) {
      setVoiceMailPin(newValue);
    }
  }

  function handleConsentChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSavePin(event.target.checked);
  }

  function handleClickOperatorOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClickOperatorClose() {
    setAnchorEl(null);
  }

  return (
    <Card
      id="message-voiceMailPin"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <Typography variant="h6">
        {t('message.installation.messagingCode.title')}
      </Typography>

      <InputLabel htmlFor="lastName" className={classes.label}>
        {t('message.installation.messagingCode.fieldName')}
      </InputLabel>
      <TextField
        id="messagingCode"
        name="messagingCode"
        variant="outlined"
        margin="normal"
        type="password"
        autoComplete="off"
        fullWidth
        value={voiceMailPin}
        onChange={handleCodeChange}
        placeholder={t('message.installation.messagingCode.placeholder')}
        color="secondary"
      />

      <Typography
        className={classes.operator}
        onClick={handleClickOperatorOpen}
      >
        {t('message.installation.messagingCode.operatorPopover')}
      </Typography>
      <OperatorPopover anchorEl={anchorEl} onClose={handleClickOperatorClose} />

      {/* TODO: make a rgpd component and combine with success deployment page */}
      <Typography className={classes.rgpd}>
        {t('message.installation.messagingCode.rgpdText')}
      </Typography>

      <FormControlLabel
        id="savePin"
        className={`${classe ? classe : null}`}
        label={t('message.installation.messagingCode.rgpdAction')}
        control={
          <Checkbox
            checked={savePin}
            onChange={handleConsentChange}
            name="rdpgAction"
            icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
            checkedIcon={<CheckBoxIcon className={classes.icon} />}
            color="secondary"
          />
        }
      />
    </Card>
  );
}

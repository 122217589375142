import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

// MUI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

// Store
import { selectTemplateById } from '../template.selector';
import { selectInfos } from 'modules/info/info.selector';

// Others
import {
  updateTemplateForDisplay,
  updateContentWithDynamicVariableValues,
} from '../helpers/textFormat.helpers';

import { Template } from '../../../@types/state/template';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    maxWidth: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px`,
  },
  text: {
    color: theme.palette.text.secondary,
    '&>strong': {
      '&:after': {
        color: theme.palette.text.primary,
        content: `attr(data-content)`,
      },
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IProps {
  title?: boolean;
  children?: React.ReactChild;
  templateId?: Template['id'];
  dynamicVariables?: Record<string, string>;
  contentCustom?: string;
  classe?: string;
}

export default function TemplateCardDisplay(props: IProps) {
  const {
    title,
    children,
    classe,
    templateId,
    contentCustom,
    dynamicVariables,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  // Store
  const template = useSelector(selectTemplateById)(String(templateId));
  const infos = useSelector(selectInfos);

  // State
  const content = React.useMemo(() => {
    if (template && infos) {
      let interpolatedContent = updateTemplateForDisplay(
        template,
        infos
      ).content;
      if (dynamicVariables) {
        interpolatedContent = updateContentWithDynamicVariableValues(
          interpolatedContent,
          dynamicVariables
        );
      }

      return interpolatedContent;
    }
  }, [dynamicVariables, infos, template]);

  // dangerouslySetInnerHTML is secured by css pseudo element and data-content attribute
  return (
    <Card
      id="template-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      {title && (
        <Typography className={classes.title}>
          {t('message.text.yourText')}
        </Typography>
      )}

      <CardContent className={classes.content}>
        {!contentCustom && content && (
          <Typography
            className={classes.text}
            dangerouslySetInnerHTML={{
              __html: content || '',
            }}
          />
        )}
        {contentCustom && (
          <Typography className={classes.text}>{contentCustom}</Typography>
        )}
      </CardContent>
      {children && <div className={classes.action}>{children}</div>}
    </Card>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import NewsList from '../../modules/news/components/list.component';
import Menu from '../../modules/message/components/menu.component';
import {
  selectNews,
  // selectFetchPending as selectFetchPendingNews,
} from '../../modules/news/news.selector';
import {
  selectInfos,
  // selectFetchPending as selectFetchPendingUser,
} from '../../modules/info/info.selector';
import {
  selectOrders,
  selectFetchPending as selectFetchPendingOrders,
} from '../../modules/order/order.selector';
import {
  selectMessages,
  selectFetchPending as selectFetchPendingMessages,
} from '../../modules/message/message.selector';

import Loader from '../../modules/utils/components/loader.component';
import StateSlider from '../../modules/state/components/slider.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      padding: theme.spacing(2),
      background: '#ECEFF1',
    },
    divider: {
      margin: `${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(
        3
      )}px ${theme.spacing(4)}px`,
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
  })
);

export default function Home() {
  const classes = useStyles();
  const { t } = useTranslation();
  // const fetchPendingNews = useSelector(selectFetchPendingNews);
  // const fetchPendingUser = useSelector(selectFetchPendingUser);
  const fetchPendingMessages = useSelector(selectFetchPendingMessages);
  const fetchPendingOrders = useSelector(selectFetchPendingOrders);
  const infos = useSelector(selectInfos);
  const news = useSelector(selectNews);
  const messages = useSelector(selectMessages);
  const orders = useSelector(selectOrders);

  const isLoading = fetchPendingOrders || fetchPendingMessages;

  return (
    <div id="home-view" className={classes.container}>
      {isLoading && (
        <Backdrop className={classes.backdrop} open>
          <Loader />
        </Backdrop>
      )}
      {!isLoading && infos && infos.first_name && (
        <Typography
          color="textPrimary"
          variant="h5"
          className={classes.title}
          align="center"
        >
          {`${t('home.hello')} ${infos.first_name}`} !
        </Typography>
      )}

      {!isLoading && messages && (
        <StateSlider orders={orders} messages={messages} />
      )}

      {!isLoading && <Divider classes={{ root: classes.divider }} />}

      {!isLoading && news && <NewsList news={news} />}

      <Menu />
    </div>
  );
}

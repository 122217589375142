import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar as MuiAppBar } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useLocation } from 'react-router-dom';
import { getRouteMetaDataFromPathName } from '../../../router/routes.config';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

interface IPropsScroll {
  children: React.ReactElement;
}

function HideOnScroll(props: IPropsScroll) {
  const { children } = props;

  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

interface IProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AppBar(props: IProps) {
  const { setIsDrawerOpen, isDrawerOpen } = props;
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);

  return (
    <div id="app-bar">
      <HideOnScroll {...props}>
        <MuiAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {routeMetaData && routeMetaData.name && t(routeMetaData.name)}
            </Typography>
          </Toolbar>
        </MuiAppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}

import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { selectPlayerPath, selectPlayerState } from '../player.selector';
import { PlayerStateEnum } from '../config/player.config';
import * as PlayerAction from '../player.action';

const useStyles = makeStyles(() => ({
  player: {
    display: 'none',
  },
}));

export default function PlayerButtonRounded() {
  const playerRef = useRef<HTMLAudioElement | null>(null);
  const classes = useStyles();
  const playerPath = useSelector(selectPlayerPath);
  const playerState = useSelector(selectPlayerState);
  const dispatch = useDispatch();

  useEffect(() => {
    playerRef.current?.addEventListener('ended', (event) => {
      dispatch(PlayerAction.stop());
    });
  });

  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;

      if (playerState === PlayerStateEnum.PLAYING) {
        player.play();
      } else if (playerState === PlayerStateEnum.PAUSE) {
        player.pause();
      } else if (playerState === PlayerStateEnum.STOPPED) {
        player.pause();
      }
    }
  }, [playerState]);

  return (
    <>
      {playerPath && (
        <audio
          ref={playerRef}
          className={classes.player}
          controls
          src={playerPath}
        ></audio>
      )}
    </>
  );
}

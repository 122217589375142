import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { State } from '../../@types/state';
import * as lineServiceApi from '../../services/api/line.service';
import { LineActionTypes } from './types';
import { ValidUpdateUserInfo, ValidUpdateOnboarding } from '../../@types/line';
import * as infoAction from '../info/info.action';
import { waitFor } from '../../services/api/helpers/various.helpers';
import appConfig from '../../application/app.config';
import { Endpoint } from '../../router/routes.config';

export type LineAction =
  | SetLineUserInfoPending
  | SetLineUserInfoSuccess
  | SetLineUserInfoError
  | SetOnboardingPending
  | SetOnboardingSuccess
  | SetOnboardingError
  | CallHistoryMethodAction;

export interface SetLineUserInfoPending
  extends Action<LineActionTypes.SET_LINE_USER_INFO_PENDING> {
  pending: boolean;
}

export interface SetLineUserInfoSuccess
  extends Action<LineActionTypes.SET_LINE_USER_INFO_SUCCESS> {}

export interface SetLineUserInfoError
  extends Action<LineActionTypes.SET_LINE_USER_INFO_ERROR> {
  message: any;
}

const setLineUserInfoPending = (pending: boolean): SetLineUserInfoPending => {
  return { type: LineActionTypes.SET_LINE_USER_INFO_PENDING, pending };
};

const setLineUserInfoSuccess = (): SetLineUserInfoSuccess => {
  return { type: LineActionTypes.SET_LINE_USER_INFO_SUCCESS };
};

const setLineUserInfoError = (message: string): SetLineUserInfoError => {
  return { type: LineActionTypes.SET_LINE_USER_INFO_ERROR, message };
};

export const setLineUserInfo = (
  userInfo: ValidUpdateUserInfo
): ThunkAction<Promise<void>, State, {}, LineAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, LineAction>
  ): Promise<void> => {
    dispatch(setLineUserInfoPending(true));
    try {
      const response = await lineServiceApi.setLineUserInfo(userInfo);

      if (response.status === 204) {
        await dispatch(infoAction.getInfos());
        dispatch(setLineUserInfoSuccess());
      }
    } catch (error) {
      dispatch(setLineUserInfoError(error.message));
    }
  };
};

export interface SetOnboardingPending
  extends Action<LineActionTypes.SET_ONBOARDING_PENDING> {
  pending: boolean;
}

export interface SetOnboardingSuccess
  extends Action<LineActionTypes.SET_ONBOARDING_SUCCESS> {}

export interface SetOnboardingError
  extends Action<LineActionTypes.SET_ONBOARDING_ERROR> {
  message: any;
}

const setOnboardingPending = (pending: boolean): SetOnboardingPending => {
  return {
    type: LineActionTypes.SET_ONBOARDING_PENDING,
    pending,
  };
};

const setOnboardingSuccess = (): SetOnboardingSuccess => {
  return {
    type: LineActionTypes.SET_ONBOARDING_SUCCESS,
  };
};

const setOnboardingError = (message: string): SetOnboardingError => {
  return {
    type: LineActionTypes.SET_ONBOARDING_ERROR,
    message,
  };
};

export const setOnboarding = (
  payload: ValidUpdateOnboarding
): ThunkAction<Promise<void>, State, {}, LineAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, LineAction>
  ): Promise<void> => {
    dispatch(setOnboardingPending(true));
    try {
      const response = await lineServiceApi.setOnboarding(payload);

      await waitFor(appConfig.waitForTime);

      if (response.status === 204) {
        if (response) {
          dispatch(setOnboardingSuccess());
          dispatch(infoAction.getInfos());
          dispatch(push(Endpoint.ORDER_CREATE));
        }
      }
    } catch (error) {
      dispatch(setOnboardingError(error.message));
    }
  };
};

import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Sound } from '../../../../../@types/state/sound';
import Radio from '@material-ui/core/Radio';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Help from '../../../../help/components/help.component';
import Filter from './filter.component';
import SoundCard from '../../card.component';
import Search from './search.component';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import OutlinedPlayerButton from '../../../../player/components/button/outlined.component';
import appConfig from '../../../../../application/app.config';
import { useScrollBottom } from '../../../../utils/hook';
import * as soundAction from '../../../sound.action';
import * as playerAction from '../../../../player/player.action';
import { PlayerStateEnum } from '../../../../player/config/player.config';
import {
  selectSounds,
  selectDefaultSoundId,
  selectSoundsViewMore,
} from '../../../sound.selector';
import { selectPlayerState } from '../../../../player/player.selector';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  controls: (props: IStyledProps) => ({
    position: 'fixed',
    top: props.trigger ? 39 : 130,
    left: 0,
    right: 0,
    padding: theme.spacing(1, 2),
    background: `linear-gradient(${theme.palette.background.paper} 80%, transparent)`,
    zIndex: 1200,
  }),
  control: {
    marginBottom: theme.spacing(2),
  },
  result: (props: IStyledProps) => ({
    padding: `${props.paddingTopResult + theme.spacing(1)}px ${theme.spacing(
      1
    )}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  }),
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  card: {
    width: '85%',
  },
  selection: {
    width: '15%',
    paddingRight: 0,
  },
  viewMore: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 80,
    width: '90%',
    zIndex: 1000,
  },
  help: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}));

export interface IStyledProps {
  paddingTopResult: number;
  trigger: boolean;
}

export interface IProps {
  selectedSoundId: string;
  setSelectedSoundId: Dispatch<SetStateAction<string>>;
}

export default function SoundsList(props: IProps) {
  const { selectedSoundId, setSelectedSoundId } = props;
  const [paddingTopResult, setpaddingTopResult] = useState(0);
  const [offset, setOffset] = useState(appConfig.search.sound.offset);
  const [resultsIds, setResultsIds] = useState<string[]>([]);
  const [params, setParams] = useState('');
  const [types, setTypes] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const trigger = useScrollTrigger();
  const controlsEl = useRef<HTMLElement | null>(null);
  const classes = useStyles({ paddingTopResult, trigger });
  const { t } = useTranslation();
  const soundsEl = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const sounds = useSelector(selectSounds);
  const defaultSoundId = useSelector(selectDefaultSoundId);
  const playerState = useSelector(selectPlayerState);
  const soundsViewMore = useSelector(selectSoundsViewMore);

  const isBottom = useScrollBottom(soundsEl);

  // set padding dynamically according to the header size
  useEffect(() => {
    const { current } = controlsEl;
    if (current) {
      setpaddingTopResult(current.clientHeight);
    }
  }, []);

  // select the first sound by default
  useEffect(() => {
    if (defaultSoundId) {
      setSelectedSoundId(defaultSoundId);
    }
  }, [defaultSoundId, setSelectedSoundId]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const soundId = event.target.value.toString();
    setSelectedSoundId(soundId);
  }

  const handleSeeMore = React.useCallback(async () => {
    const soundsIds = sounds.map((sound: Sound) => sound.id);
    setResultsIds(soundsIds);
    const newOffset = offset + appConfig.search.sound.limit;
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }

    await dispatch(
      soundAction.getSoundsWithSearchParamsViewMore({
        search: params,
        types: types,
        categories: categories,
        ...(newOffset && { offset: newOffset }),
      })
    );
    setOffset(newOffset);
  }, [categories, dispatch, offset, params, playerState, sounds, types]);

  const handleSearchFilter = React.useCallback(async () => {
    const soundsIds = sounds.map((sound: Sound) => sound.id);
    setResultsIds(soundsIds);
    window.scrollTo(0, 0);
    setOffset(appConfig.search.sound.offset);

    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }

    await dispatch(
      soundAction.getSoundsWithSearchParams({
        search: params,
        types: types,
        categories: categories,
        offset: appConfig.search.sound.offset,
      })
    );
  }, [categories, dispatch, params, playerState, sounds, types]);

  return (
    <div id="sound-list" className={classes.list}>
      <section id="controls" className={classes.controls} ref={controlsEl}>
        <Search
          classe={classes.control}
          setSelectedSoundId={setSelectedSoundId}
          params={params}
          setParams={setParams}
          offset={offset}
          setOffset={setOffset}
          types={types}
          categories={categories}
        />
        <Filter
          types={types}
          setTypes={setTypes}
          categories={categories}
          setCategories={setCategories}
          search={handleSearchFilter}
          classe={classes.control}
        />
      </section>

      <section id="sound-list-content" className={classes.result}>
        {sounds &&
          sounds.map((sound: Sound, index: number) => {
            const isInclude = resultsIds.includes(sound.id);

            return offset === 0 ? (
              <div
                key={`sounds-${sound.id}`}
                id={`sounds-${sound.id}`}
                className={classes.item}
                ref={soundsEl}
              >
                <SoundCard sound={sound as Sound} classe={classes.card}>
                  <OutlinedPlayerButton
                    id={sound.id}
                    path={sound.file_30s ? sound.file_30s : ''}
                  />
                </SoundCard>
                <Radio
                  className={classes.selection}
                  checked={selectedSoundId === sound.id.toString()}
                  onChange={handleChange}
                  value={sound.id}
                  name="sound-selection"
                />
              </div>
            ) : (
              isInclude && (
                <div
                  key={`sounds-${sound.id}`}
                  id={`sounds-${sound.id}`}
                  className={classes.item}
                >
                  <SoundCard sound={sound as Sound} classe={classes.card}>
                    <OutlinedPlayerButton
                      id={sound.id}
                      path={sound.file_30s ? sound.file_30s : ''}
                    />
                  </SoundCard>
                  <Radio
                    className={classes.selection}
                    checked={selectedSoundId === sound.id.toString()}
                    onChange={handleChange}
                    value={sound.id}
                    name="sound-selection"
                  />
                </div>
              )
            );
          })}

        {isBottom && offset === 0 && (
          <Button
            className={classes.viewMore}
            variant="contained"
            onClick={handleSeeMore}
          >
            {t('search.seeMore')}
          </Button>
        )}
        {isBottom && offset > 0 && soundsViewMore.length > 0 && (
          <Button
            className={classes.viewMore}
            variant="contained"
            onClick={handleSeeMore}
          >
            {t('search.seeMore')}
          </Button>
        )}

        {sounds &&
          sounds.map((sound: Sound) => {
            const isInclude = resultsIds.includes(sound.id);

            return (
              !isInclude &&
              offset !== 0 && (
                <div
                  key={`sounds-more-${sound.id}`}
                  id={`sounds-more-${sound.id}`}
                  className={classes.item}
                  ref={soundsEl}
                >
                  <SoundCard sound={sound as Sound} classe={classes.card}>
                    <OutlinedPlayerButton
                      id={sound.id}
                      path={sound.file_30s ? sound.file_30s : ''}
                    />
                  </SoundCard>
                  <Radio
                    className={classes.selection}
                    checked={selectedSoundId === sound.id.toString()}
                    onChange={handleChange}
                    value={sound.id}
                    name="sound-selection"
                  />
                </div>
              )
            );
          })}
      </section>

      {/* <section id="help" className={classes.help}>
        <Help />
      </section> */}
    </div>
  );
}

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: 3,
    background: theme.palette.text.primary,
    zIndex: 1200,
  },
  bar: {
    height: 3,
    background: theme.palette.secondary.main,
    zIndex: 1200,
  },
}));

interface IProps {
  percentage: number;
}
export default function Progress(props: IProps) {
  const { percentage } = props;
  const classes = useStyles();

  return (
    <Box id="progress" className={classes.container}>
      <Box
        id="progress-bar"
        className={classes.bar}
        style={{ width: `${percentage * 100}%` }}
      ></Box>
    </Box>
  );
}

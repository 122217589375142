import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import appConfig from '../../../../../application/app.config';
import config from '../../../config/selection.config';
import { selectInprogressOrder } from '../../../../order/order.selector';
import {
  selectIsTranslated,
  selectTemplateById,
} from '../../../../../modules/template/template.selector';

import { inProgressMultilingual } from '../../../../../@types/order';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
  },
  choice: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  flag: {
    marginRight: theme.spacing(2),
  },
}));

interface IProps {
  setIsStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  languages: inProgressMultilingual;
  setLanguages: React.Dispatch<React.SetStateAction<inProgressMultilingual>>;
}

export default function Language(props: IProps) {
  const { languages, setLanguages, setIsStepValid } = props;
  const inProgressOrder = useSelector(selectInprogressOrder);
  const getTemplateById = useSelector(selectTemplateById);
  const getIsTranslated = useSelector(selectIsTranslated);
  // anaylse wich traslation are allowed in each case
  const initialState = appConfig.languages.reduce(
    (
      accumulator: {
        [index: string]: boolean;
      },
      currentValue: string
    ) => {
      // case : default message or template select by user
      if (inProgressOrder && inProgressOrder.template_id) {
        const template = getTemplateById(
          inProgressOrder.template_id.toString()
        );
        if (template) {
          const isTranslated = getIsTranslated(template.ats_id, currentValue);
          if (isTranslated) {
            accumulator[currentValue] = !!languages[currentValue];
          } else {
            return accumulator;
          }
        }
      }

      // case : default message or template select by user
      accumulator[currentValue] = !!languages[currentValue];

      return accumulator;
    },
    {}
  );

  const [state, setState] = React.useState(initialState);
  const classes = useStyles();
  const { t } = useTranslation();
  const stateLanguageKeys = Object.keys(state);

  // valid step when at least one language is selected and < than the maximum
  React.useEffect(() => {
    const languagesKeys = Object.keys(languages);
    const minLanguage = languagesKeys.filter(
      (languageKey: string) => languageKey !== appConfig.defaultlanguage
    );
    if (
      minLanguage.length > 0 &&
      languagesKeys.length <= appConfig.maxLanguage
    ) {
      setIsStepValid(true);
    } else {
      setIsStepValid(false);
    }
  }, [languages, setIsStepValid, state]);

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    const languageKey = event.target.name;
    setState({ ...state, [languageKey]: event.target.checked });
    const searchedKey = languages[languageKey];

    if (searchedKey) {
      const { [languageKey]: value, ...withoutValue } = languages;
      setLanguages(withoutValue);
    } else {
      if (languageKey === appConfig.defaultlanguage) {
        setLanguages({
          ...languages,
          [languageKey]: {
            language: languageKey,
            voice_id: inProgressOrder?.voice_id ?? null,
            template_id: inProgressOrder?.template_id ?? null,
          },
        });
      } else {
        setLanguages({
          ...languages,
          [languageKey]: {
            language: languageKey,
          },
        });
      }
    }
  }

  return (
    <div id="language-selection" className={classes.container}>
      <FormGroup id="language-selection-choice" className={classes.choice}>
        {stateLanguageKeys.map((availableLanguage: string) => {
          const configContent = config.languages[availableLanguage];

          return (
            <div className={classes.item} key={availableLanguage}>
              <img
                src={configContent.flag}
                alt="flag"
                className={classes.flag}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state[availableLanguage]}
                    onChange={handleCheck}
                  />
                }
                label={t(`${configContent.label}`)}
                name={availableLanguage}
              />
            </div>
          );
        })}
      </FormGroup>
    </div>
  );
}

import { OrderActionTypes } from '../../../modules/order/types';

export interface AnchorOrigin {
  vertical: string;
  horizontal: string;
}

export interface Content {
  message: string;
  variant: string;
  anchorOrigin: AnchorOrigin;
}

interface Config {
  [index: string]: Content;
}

const config: Config = {
  [`Not enough credits`]: {
    message: 'snackbar.error.noCredit',
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [`duplicate key value violates unique constraint "orders_title_line_id_key"`]:
    {
      message: 'snackbar.error.sameTitle',
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    },
  [OrderActionTypes.CREATE_ORDER_SUCCESS]: {
    message: 'snackbar.success.orderCreate',
    variant: 'success',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [OrderActionTypes.CREATE_ORDER_ERROR]: {
    message: 'snackbar.error.createOrder',
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [OrderActionTypes.CREATE_INSTALL_SUCCESS]: {
    message: 'snackbar.success.createInstall',
    variant: 'success',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [OrderActionTypes.CREATE_INSTALL_ERROR]: {
    message: 'snackbar.error.createInstall',
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [OrderActionTypes.CREATE_REMIX_SUCCESS]: {
    message: 'snackbar.success.createRemix',
    variant: 'success',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [OrderActionTypes.CREATE_REMIX_ERROR]: {
    message: 'snackbar.error.createRemix',
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
};

export default config;

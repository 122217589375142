import React, { useEffect } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as serviceWorker from '../../../serviceWorker';
import { isIos } from '../../utils/display';

export default function ServiceWorkerWrapper() {
  const [showReload, setShowReload] = React.useState(false);
  const [
    waitingWorker,
    setWaitingWorker,
  ] = React.useState<ServiceWorker | null>(null);
  const { t } = useTranslation();

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    if (isIos()) {
      serviceWorker.unregister();
    } else {
      serviceWorker.register({ onUpdate: onSWUpdate });
    }
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Snackbar
      open={showReload}
      message={`${t('pwa.text')}`}
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          {t('action.reload')}
        </Button>
      }
    />
  );
}

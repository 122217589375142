import React from 'react';
import TypeSound, {
  IProps as TypeSoundProps,
} from '../components/selection/content/types.components';
import SoundsList, {
  IProps as SoundsListProps,
} from '../components/selection/content/list.component';

export enum Steps {
  STEP_1 = 1,
  STEP_2 = 2,
}

export enum From {
  MESSAGE = 'message',
}

export interface Step {
  id: number;
  Content: React.ElementType;
}

export interface Config {
  ndSteps: number;
  steps: Step[];
}

export default {
  nbStep: 2,
  steps: [
    {
      id: Steps.STEP_1,
      Content: (props: TypeSoundProps) => <TypeSound {...props}></TypeSound>,
    },
    {
      id: Steps.STEP_2,
      Content: (props: SoundsListProps) => <SoundsList {...props}></SoundsList>,
    },
  ],
};

import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useInterval = function (callback: any, delay: number) {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useTimeout = function (callback: any, delay: number) {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout(tick, delay);

      return () => clearTimeout(id);
    }
  }, [delay]);
};

export function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return height;
}

export function useScrollBottom(element: any) {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    function isBottomReached(el: HTMLDivElement) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
    function handleScroll() {
      if (element && element.current && isBottomReached(element.current)) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    }
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [element]);

  return isBottom;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

import { State } from '../../@types/state';
import { MapVoices, Voice } from '../../@types/state/voice';
import { selectInprogressOrder } from '../order/order.selector';
import appConfig from '../../application/app.config';

export function selectInitFetch(state: State): boolean {
  return state.voice.initFetch;
}

export function selectFetchPending(state: State): boolean {
  return state.voice.pending;
}

export function selectMapVoices(state: State): MapVoices | null {
  return state.voice.data.voices;
}

export function selectVoicesByLanguage(state: State) {
  return function (language: string): Voice[] {
    const mapVoices = selectMapVoices(state);
    if (!mapVoices) return [];

    const voices = Object.values(mapVoices);

    return voices
      .filter((voice: Voice) => {
        return voice.language === language;
      })
      .sort((a: Voice, b: Voice) => {
        if (a.priority < b.priority) {
          return -1;
        }
        if (a.priority > b.priority) {
          return 1;
        }

        return 0;
      });
  };
}

export function selectDefaultVoiceId(state: State): number | null {
  const voices = selectVoicesByLanguage(state)(appConfig.defaultlanguage);

  if (voices.length === 0) return null;

  return voices[0].id;
}

export function selectVoiceById(state: State) {
  return function (voiceId: string | number): Voice | null {
    const mapVoices = selectMapVoices(state);
    if (!mapVoices) return null;

    return mapVoices[voiceId];
  };
}

export function selectVoiceForDisplay(state: State): Voice | null {
  const voices = selectVoicesByLanguage(state)(appConfig.defaultlanguage);
  const orderInProgress = selectInprogressOrder(state);
  const getVoiceById = selectVoiceById(state);
  let voice: Voice | null = null;

  if (!voices) return null;

  if (orderInProgress && orderInProgress.voice_id) {
    const { voice_id } = orderInProgress;

    if (voice_id) {
      voice = getVoiceById(voice_id.toString());
    }
  } else {
    const defaultVoiceId = selectDefaultVoiceId(state);
    if (!defaultVoiceId) return null;
    voice = getVoiceById(defaultVoiceId.toString());
  }

  return voice;
}

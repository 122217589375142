import { selectRole } from '../../../modules/auth/auth.selector';
import { store } from '../../../Store';
import roleConfig from '../../../application/role.config';

export default function injectRole(config: any) {
  const updatedConfig = config;
  const state = store.getState();
  const role = selectRole(state);
  const { roleMapping } = roleConfig;

  if (role) {
    updatedConfig.headers['Accept-Profile'] = `${roleMapping[role]}`;
    updatedConfig.headers['Content-Profile'] = `${roleMapping[role]}`;
  }

  return updatedConfig;
}

import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import multilingualConfig from 'modules/multilingual/config/selection.config';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  content: {
    padding: theme.spacing(2),
  },
  img: {
    margin: theme.spacing(0, 2),
  },
}));

interface IProps {
  languages: string[];
  classe?: string;
}

export default function MultilingualCard(props: IProps) {
  const { languages, classe } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card
      id="custom-voice-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <CardContent className={classes.content}>
        {languages.map((language: string, index: number) => {
          const languageConfig = multilingualConfig.languages[language];

          return (
            <React.Fragment key={index}>
              {languageConfig && (
                <Grid container direction="column">
                  <Grid item container alignItems="center">
                    <img
                      alt="flag"
                      src={languageConfig.flag}
                      className={classes.img}
                    />
                    <Typography variant="caption" color="textSecondary">
                      {t(languageConfig.label)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </CardContent>
    </Card>
  );
}

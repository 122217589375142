import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import { Infos } from '../../@types/state/info';
import * as infoServiceApi from '../../services/api/info.service';
import { InfoActionTypes } from './types';

export type InfoAction = GetInfosPending | GetInfosSuccess | GetInfosError;

export interface GetInfosPending
  extends Action<InfoActionTypes.GET_INFOS_PENDING> {
  pending: boolean;
}

export interface GetInfosSuccess
  extends Action<InfoActionTypes.GET_INFOS_SUCCESS> {
  data: State['info']['data'];
}

export interface GetInfosError extends Action<InfoActionTypes.GET_INFOS_ERROR> {
  message: any;
}

const getInfosPending = (pending: boolean): GetInfosPending => {
  return { type: InfoActionTypes.GET_INFOS_PENDING, pending };
};

const getInfosSuccess = (data: State['info']['data']): GetInfosSuccess => {
  return { type: InfoActionTypes.GET_INFOS_SUCCESS, data };
};

const getInfosError = (message: string): GetInfosError => {
  return { type: InfoActionTypes.GET_INFOS_ERROR, message };
};

// TODO: update to getUsers
export const getInfos = (): ThunkAction<
  Promise<void>,
  State,
  {},
  InfoAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, InfoAction>
  ): Promise<void> => {
    dispatch(getInfosPending(true));
    try {
      const response = await infoServiceApi.getInfos();

      if (response.status === 200) {
        const { data } = response;
        const infos: Infos = data[0];
        const infoData = {
          infos: infos,
        };
        dispatch(getInfosSuccess(infoData));
        localStorage.setItem('infos', JSON.stringify(infoData));
      }
    } catch (error) {
      dispatch(getInfosError(error.message));
    }
  };
};

import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { selectInprogressOrder } from 'modules/order/order.selector';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },

  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IProps {
  children?: React.ReactChild;
  classe?: string;
}

export default function PlanningCard(props: IProps) {
  const { children, classe } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const inprogressOrder = useSelector(selectInprogressOrder);

  return (
    <Card
      id="planning-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <CardContent className={classes.content}>
        <Typography color="textSecondary">
          {t('message.scheduler.duration')}
        </Typography>
        {inprogressOrder && inprogressOrder.install_date && (
          <Typography color="textSecondary" variant="h6">
            {new Date(inprogressOrder.install_date).toLocaleDateString(
              'fr-FR',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }
            )}
          </Typography>
        )}
        {inprogressOrder && !inprogressOrder.install_date && (
          <Typography color="textSecondary" variant="h6">
            {t('message.scheduler.defaultMessage')}
          </Typography>
        )}
      </CardContent>
      {children && <div className={classes.action}>{children}</div>}
    </Card>
  );
}

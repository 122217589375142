import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { InfoActionTypes } from './types';
import { InfoAction } from './info.action';

export const initialState = {
  pending: false,
  data: {
    infos: null,
  },
  initFetch: true,
  errors: undefined,
};

const info: Reducer<State['info'], InfoAction> = (
  state: State['info'] = initialState,
  action: InfoAction
) => {
  switch (action.type) {
    case InfoActionTypes.GET_INFOS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case InfoActionTypes.GET_INFOS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case InfoActionTypes.GET_INFOS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default info;

import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TopBanner from '../../banner/components/topBanner.component';
import BottomBanner from '../../banner/components/bottomBanner.component';
import { RecordState, PlayRecordState } from '../config/recorder.config';
import * as recorderAction from '../recorder.action';
import * as uploadService from '../../../services/api/upload.service';
import { selectRecordState } from '../recorder.selector';
import Typography from '@material-ui/core/Typography';
import MicRoundedIcon from '@material-ui/icons/MicRounded';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
// use of polyfill for browser compatibility
// https://github.com/ai/audio-recorder-polyfill
import AudioRecorder from 'audio-recorder-polyfill';
// @ts-ignore
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';

import { Template } from '../../../@types/state/template';

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
window.MediaRecorder = AudioRecorder;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2000,
    background: theme.palette.background.paper,
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    marginTop: 'calc(var(--vh, 1vh) * 40)',
  },
  playRecord: {
    display: 'flex',
    flexDirection: 'column',
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 80,
    width: '100%',
  },
  remove: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    marginLeft: theme.spacing(16),
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
    borderRadius: '100%',
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },
  time: {
    margin: 'auto',
  },
  text: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    '&>strong': {
      '&:after': {
        color: theme.palette.text.primary,
        content: `attr(data-content)`,
      },
    },
  },
  player: {
    display: 'none',
  },
  next: {
    color: theme.palette.common.white,
  },
  previous: {
    color: theme.palette.common.white,
  },
  mainButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
    borderRadius: '100%',
    '& .MuiSvgIcon-root': {
      fontSize: '4rem',
    },
  },
}));

interface IProps {
  topBannerTitle: string;
  topBannerContent: string;
  setRecordUrl?: React.Dispatch<React.SetStateAction<string>>;
  Progress?: React.ElementType;
  updatedTemplate?: null | Template | string;
  onCustomVoiceSave?: (customVoiceUrl: string) => void;
}

export default function Recorder(props: IProps) {
  const {
    topBannerTitle,
    topBannerContent,
    setRecordUrl,
    Progress,
    updatedTemplate,
    onCustomVoiceSave,
  } = props;
  const [mediaRecorder, setMediaRecorder] = React.useState<any>(null);
  const [playState, setPlayState] = React.useState<PlayRecordState>(
    PlayRecordState.NOT_PLAYING
  );
  const [duration, setDuration] = React.useState<number>(0);
  const [mp3, setMp3] = React.useState<Blob | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recordState = useSelector(selectRecordState);
  const audioEl = React.useRef<HTMLAudioElement>(null);

  // stop player at the end of the recording
  React.useEffect(() => {
    audioEl.current?.addEventListener('ended', (event) => {
      if (audioEl && audioEl.current && playState === PlayRecordState.PLAYING) {
        setPlayState(PlayRecordState.NOT_PLAYING);
        audioEl.current.pause();
      }
    });
  });

  // increment duration on each new record
  React.useEffect(() => {
    function increment() {
      const newValue = duration + 1;
      setDuration(newValue);
    }
    if (recordState === RecordState.RECORDING) {
      let id = setInterval(increment, 1000);

      return () => clearInterval(id);
    }
  }, [duration, recordState, setDuration]);

  // create a MediaRecorder instance on component mount
  React.useEffect(() => {
    let ignore = false;
    async function initMediaRecorder() {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const newMediaRecorder = new MediaRecorder(stream);
      setMediaRecorder(newMediaRecorder);
    }

    if (!ignore) initMediaRecorder();

    return () => {
      ignore = true;
      setMediaRecorder(null);
    };
  }, []);

  function handlePreviousClick() {
    // stop playing record
    if (playState === PlayRecordState.PLAYING) {
      if (audioEl && audioEl.current) {
        setPlayState(PlayRecordState.PLAYING);
        audioEl.current.pause();
      }
    }
    dispatch(recorderAction.setRecorderOpen(false));
  }

  function handleRecord() {
    if (mediaRecorder) {
      mediaRecorder.start();
      dispatch(recorderAction.setRecording());

      let blob: Blob | null = null;

      mediaRecorder.addEventListener('dataavailable', (event: any) => {
        blob = event.data;
        if (mediaRecorder.state === 'inactive' && blob) {
          setMp3(blob);
          if (audioEl && audioEl.current) {
            audioEl.current.src = URL.createObjectURL(blob);
          }
        }
      });
    }
  }

  function handleStopRecording() {
    if (mediaRecorder) {
      mediaRecorder.stop();
      dispatch(recorderAction.setStopRecording());
      setDuration(0);
    }
  }

  function handlePlayRecording() {
    if (audioEl && audioEl.current) {
      setPlayState(PlayRecordState.PLAYING);
      audioEl.current.play();
    }
  }

  function handlePauseRecording() {
    if (audioEl && audioEl.current && playState === PlayRecordState.PLAYING) {
      setPlayState(PlayRecordState.NOT_PLAYING);
      audioEl.current.pause();
    }
  }

  function handleRemoveRecord() {
    // stop playing record
    if (playState === PlayRecordState.PLAYING) {
      if (audioEl && audioEl.current) {
        setPlayState(PlayRecordState.PLAYING);
        audioEl.current.pause();
      }
    }
    setMp3(null);
  }

  async function handleNextClick() {
    if (mp3) {
      // call upload service for mp3 file saving
      const formData = new FormData();
      formData.append('file_0', mp3, 'record.mp3');

      const uploadResponse = await uploadService.upload(formData);

      if (uploadResponse && uploadResponse.data) {
        // used for identity prononciation record
        if (setRecordUrl) {
          setRecordUrl(uploadResponse.data[0]);
        }
        // used for custom voice record
        if (onCustomVoiceSave) {
          onCustomVoiceSave(uploadResponse.data[0]);
        }
      }
      // stop playing record
      if (playState === PlayRecordState.PLAYING) {
        if (audioEl && audioEl.current) {
          setPlayState(PlayRecordState.PLAYING);
          audioEl.current.pause();
        }
      }
    }

    dispatch(recorderAction.setRecorderOpen(false));
  }

  return (
    <div id="recorder" className={classes.container}>
      <TopBanner title={topBannerTitle} content={topBannerContent} />

      <div id="record-button" className={classes.button}>
        {!mp3 && recordState === RecordState.NOT_RECORDING && (
          <>
            <div onClick={handleRecord} className={classes.mainButton}>
              <MicRoundedIcon />
            </div>
            <div id="recorder-control" className={classes.control}></div>
          </>
        )}
        {!mp3 && recordState === RecordState.RECORDING && (
          <>
            <div onClick={handleStopRecording} className={classes.mainButton}>
              <StopRoundedIcon />
            </div>
            <div id="recorder-control" className={classes.control}>
              <Typography variant="h2" className={classes.time}>
                {moment.utc(duration * 1000).format('HH:mm:ss')}
              </Typography>
            </div>
          </>
        )}
        {mp3 && playState === PlayRecordState.NOT_PLAYING && (
          <>
            <div onClick={handlePlayRecording} className={classes.mainButton}>
              <PlayArrowRoundedIcon />
            </div>
            <div id="recorder-control" className={classes.control}>
              <div id="recorder-control" className={classes.control}>
                <div onClick={handleRemoveRecord} className={classes.remove}>
                  <DeleteRoundedIcon />
                </div>
              </div>
            </div>
          </>
        )}
        {mp3 && playState === PlayRecordState.PLAYING && (
          <>
            <div onClick={handlePauseRecording} className={classes.mainButton}>
              <PauseRoundedIcon />
            </div>
            <div id="recorder-control" className={classes.control}>
              <div onClick={handleRemoveRecord} className={classes.remove}>
                <DeleteRoundedIcon />
              </div>
            </div>
          </>
        )}
        {updatedTemplate && (
          <Typography
            className={classes.text}
            dangerouslySetInnerHTML={{
              __html:
                typeof updatedTemplate === 'string'
                  ? updatedTemplate
                  : updatedTemplate.content,
            }}
          ></Typography>
        )}
      </div>

      <audio ref={audioEl} controls={true} className={classes.player}></audio>

      <BottomBanner
        progress={Progress ? <Progress /> : null}
        Previous={(props: unknown) => (
          <Button
            onClick={handlePreviousClick}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.cancel')}
          </Button>
        )}
        Next={(props: unknown) => (
          <Button
            onClick={handleNextClick}
            disabled={mp3 ? false : true}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.save')}
          </Button>
        )}
      />
    </div>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { News } from '../../../@types/state/news';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {},
    card: {
      marginBottom: theme.spacing(2),
    },
    media: {
      height: '15em',
    },
    button: {
      textTransform: 'none',
      color: 'black',
    },
  })
);

interface IProps {
  news: News[];
}

export default function List(props: IProps) {
  const { news } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  function handleLinkClick(link: null | string) {
    if (link) {
      window.open(link, '_blank', 'rel="noopener noreferrer"');
    }
  }

  return (
    <div id="news-list" className={classes.list}>
      {news &&
        news.map((news: News) => {
          return (
            <Card key={news.id} className={classes.card}>
              <CardActionArea onClick={() => handleLinkClick(news.link)}>
                {news.image_url && (
                  <CardMedia
                    className={classes.media}
                    image={news.image_url}
                    title={news.title}
                  />
                )}
                <CardContent>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {news.title}
                  </Typography>
                  <Typography component="p" color="textSecondary">
                    {news.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              {news.link && (
                <CardActions>
                  <Button
                    className={classes.button}
                    onClick={() => handleLinkClick(news.link)}
                  >
                    {t('viewMore')}
                  </Button>
                </CardActions>
              )}
            </Card>
          );
        })}
    </div>
  );
}

import { Languages } from '../../modules/multilingual/config/selection.config';
const fr = {
  fr: {
    translations: {
      dynamicVariables: {
        add: `saisir les informations`,
        title: `Veuillez saisir les informations manquantes de votre message`,
        accept: `Valider`,
        cancel: `Annuler`,
        tel: `Veuillez saisir un numéro de téléphone`,
        required: `* Ce champ est requis`,
        allRequired: `Tous les champs sont obligatoires`,
      },
      order: {
        noVoice: `Aucune voix`,
        voice: `Choix d'une voix`,
        title: `Titre de l'annonce`,
        text: `Mon texte`,
        orderDate: `Date de commande`,
        noSound: `Aucun fond musical`,
        predefinedSound: `Son prédéfini`,
        soundSelected: `Choix d'un fond musical`,
        yourOrder: `Votre commande d'annonce`,
        detail: `Détails`,
      },
      viewMore: `En savoir plus`,
      multilinguage: `Langue prédéfinie`,
      multilinguage_plural: `Langues prédéfinies`,
      myMessages: {
        inProduction: `Annonce en cours de production`,
        viewOrder: `Voir la commande`,
        plannedAt: `Liste des planifications : `,
        noPlannification: `Aucune planification`,
        installedAt: `Liste des installations : `,
        noInstall: `Aucune installation`,
        yourMessage: `Votre annonce`,
        remix: `Changer la musique de cette annonce`,
        install: `Remettre en place cette annonce`,
        viewDetails: `Voir les détails de cette annonce`,
        orderList: `Liste des demandes d'annonce`,
        orderCreatedAt: `Demande de production le : `,
        producedAt: `Annonce produite le : `,
        startingOn: `Programmer l'installation`,
      },
      scheduler: {
        action: `Programmer`,
        now: `Dès que possible`,
      },
      profile: {
        recorderLastName: `Prononcer votre nom`,
        recorderFirstName: `Prononcer votre prénom`,
        recorderCompany: `Prononcer votre raison sociale`,
        company: `Nom de votre société`,
        male: `Masculin`,
        female: `Féminin`,
        civility: `Civilité`,
        firstName: `Prénom`,
        lastName: `Nom`,
        general: `Général`,
        line: `Informations sur la ligne`,
        operator: `Nom de votre opérateur`,
        voiceMailPin: `Code de votre messagerie`,
        businessName: `Service opéré par `,
      },
      multilingual: {
        voiceNotSuported: `Aucun comédien référencé pour traduire votre texte, merci de désélectionner cette langue à l'étape du choix des langues.`,
        playOrder: `Ordre de lecture`,
        content: `Contenu de l'annonce`,
        language: `Langue`,
        resetOrder: `Réinitialiser l'ordre`,
        customText: `Voici votre texte personnalisé en français`,
        customTextTranslate: `Traduction du texte`,
        [Languages.FR]: `Français`,
        [Languages.EN]: `Anglais`,
        [Languages.ES]: `Espagnol`,
        [Languages.IT]: `Italien`,
        [Languages.DE]: `Allemand`,
        step: {
          language: {
            topBannerTitle: `Choix des langues`,
            topBannerContent: `Sélectionnez la ou les langues que vous souhaitez pour votre annonce`,
          },
          text: {
            topBannerTitle: `Choix de texte`,
            topBannerContent: `Traduisez votre texte choisi dans les langues sélectionnées`,
          },
          voice: {
            topBannerTitle: `Choix de voix`,
            topBannerContent: `Sélectionnez le genre des comédiens et comédiennes selon les langues que vous avez sélectionnées`,
          },
          order: {
            topBannerTitle: `Ordre des message`,
            topBannerContent: `Sélectionnez l'ordre dans lequel les textes seront joués dans votre annonce`,
          },
          validation: {
            topBannerTitle: `Votre récapitulatif`,
            topBannerContent: `Vérifiez que toutes les informations vous correspondent et validez`,
          },
        },
      },
      recorder: {
        phoneticRecord: `Enregistrer sa prononciation`,
        lastName: {
          topBannerTitle: `Votre identité`,
          topBannerContent: `Lancez l'enregistrement en cliquant sur le micro. Pensez à bien articuler`,
        },
        firstName: {
          topBannerTitle: `Votre identité`,
          topBannerContent: `Lancez l'enregistrement en cliquant sur le micro. Pensez à bien articuler`,
        },
        companyName: {
          topBannerTitle: `Votre identité`,
          topBannerContent: `Lancez l'enregistrement en cliquant sur le micro. Pensez à bien articuler`,
        },
        customVoice: {
          topBannerTitle: `Enregistrer votre texte`,
          topBannerContent: `Lancez l'enregistrement en cliquant sur le micro. Pensez à bien articuler`,
        },
      },
      legal: {
        link: '© Ats Studios 2021 - Mentions légales',
        title: 'Mentions légales',
        credit: 'Crédits',
        identity: 'Identification',
        cgu: `Conditions générales d'utilisation`,
        cgulink: `Voir les CGU`,
        development: `Création du site web`,
        social: `Dénomination sociale`,
        headQuarter: `Adresse du siège social`,
        phone: ` Numéro de téléphone`,
        mail: `Adresse de courrier électronique`,
        rcs: `N° RCS`,
        siret: `SIRET`,
      },
      fab: {
        orderCreate: `Créer une annonce`,
        orderInstall: `Remettre une annonce en place`,
        orderRemix: `Changer la musique d'une annonce`,
      },
      search: {
        seeMore: 'Afficher plus',
      },
      installPopup: {
        title: `Mes annonces`,
        text: `+ installer l'application`,
      },
      installPopupIos: {
        title: `Mes annonces`,
        text1: `Cette application peut être ajoutée à l'écran d'accueil en appuyant sur`,
        text2: `puis sur`,
      },
      menu: {
        title: `Mes annonces`,
      },
      snackbar: {
        error: {
          noCredit: `Vous n'avez plus assez de crédit.`,
          title: `Veuillez indiquer le nom de votre annonce.`,
          sameTitle: `Ce nom d'annonce existe déja.`,
          createOrder: `Un problème est survenu lors de la demande de création de votre annonce`,
          createInstall: `Un problème est survenu lors de la demande d'installation de votre annonce`,
          createRemix: `Un problème est survenu lors de la demande de remixage de votre annonce`,
        },
        success: {
          orderCreate: `Votre demande de création d'annonce est enregistrée`,
          createInstall: `Votre demande d'installation d'annonce est enregistrée`,
          createRemix: `Votre demande de remixage d'annonce est enregistrée`,
        },
      },
      yes: `oui`,
      no: `non`,
      credit: {
        available: `Crédit disponible`,
      },
      onboarding: {
        step: {
          1: {
            hello: `Bienvenue !`,
            help: `Créez vos annonces de répondeur professionnel en toute simplicité`,
            demo: `Pour vous donner une idée`,
          },
          2: {
            how: `Comment est composée cette annonce ?`,
            textChoice: `Choix d'un texte`,
            messageTitle: `Annonce par défaut 1`,
            message: `Bonjour, vous êtes sur la messagerie de Thomas Nova,
          laissez votre message et votre numéro, il vous rappellera dès que
          possible. Merci au revoir et à bientôt`,
            voiceChoice: `Choix d'une voix`,
            soundChoice: `Choix d'un fond musical`,
            scheduler: `Programmation`,
            soon: `Fonctionnalité à venir`,
          },
        },
      },
      starting: ``,
      auth: {
        identification: `Identification en cours`,
        notFound: `Utilisateur non reconnu`,
      },
      home: {
        hello: `Bonjour`,
      },
      event: {
        order: {
          inProduction: {
            title: `Annonce en production`,
            description: `Votre annonce <strong data-content='{{name}}'></strong> est en cours de production. Elle sera bientôt disponible.`,
          },
          error: {
            title: `Problème production `,
            description: `Votre annonce <strong data-content='{{name}}'></strong> n’a pas pue être produite. Veuillez recréer votre annonce. Vous ne serez pas débité.`,
          },
        },
        message: {
          created: {
            title: `Créée et non installée`,
            description: `Votre annonce <strong data-content='{{name}}'></strong> est disponible mais non installée sur votre messagerie`,
          },
          installing: {
            title: `En cours de mise en place`,
            description: `Votre annonce <strong data-content='{{name}}'></strong> est en cours d’installation. Encore un peu de patience…`,
          },
          installed: {
            title: `Votre annonce est en service`,
            description: `Votre annonce <strong data-content='{{name}}'></strong> a bien été mise en place dans votre boîte vocale`,
          },
          scheduled: {
            title: `Votre annonce est programmée`,
            description: `Votre annonce <strong data-content='{{name}}'></strong> est disponible et sera mise en place à la date prévue`,
          },
          error: {
            title: `Problème installation annonce`,
            description: `Votre annonce <strong data-content='{{name}}'></strong> est disponible mais une erreur est survenue lors de sa mise en place`,
          },
          messagingCodeMissing: {
            title: `Code de messagerie manquant`,
            description: `Votre annonce <strong data-content='{{name}}'></strong> est disponible mais votre code est nécessaire pour sa mise en place`,
          },
          waitForValidation: {
            title: `En attente de votre validation`,
          },
          refused: {
            title: `Annonce refusée`,
            description: `Vous avez refusé l'annonce <strong data-content='{{name}}'></strong>`,
          },
        },
      },
      needHelp: `Besoin d'aide ?`,
      route: {
        schedulerSelection: `Programmation`,
        starting: `Lancement de l'application`,
        login: `Connexion`,
        home: `Accueil`,
        messages: `Mes annonces`,
        scheduler: `Programmation`,
        userUpdate: `Votre identité`,
        order: `Votre commande`,
        deploymentCreate: `Votre annonce`,
        templateSelection: `Choix d'un texte`,
        voiceSelection: `Choix d'une voix`,
        soundSelection: `Choix d'un fond musical`,
        messageInstallation: `Validation d'annonce`,
        orderTitleUpdate: `Editer le nom de l’annonce`,
        legal: `Mentions légales`,
        multilingual: `Choix des langues`,
        profile: `Mon compte`,
      },
      user: {
        male: `masculin`,
        female: `féminin`,
        maleHe: `Masculin (il)`,
        femaleShe: `Féminin (elle)`,
        he: `il`,
        she: `elle`,
      },
      action: {
        edit: `éditer`,
        update: `modifier`,
        previous: `retour`,
        next: `suivant`,
        end: `terminer`,
        listen: `ecouter`,
        filter: `filtrer`,
        reload: `recharger`,
        display: `afficher`,
        start: `commencer`,
        close: `fermer`,
        cancel: `annuler`,
        save: `enregistrer`,
        validate: `valider`,
        confirm: `confirmer`,
      },
      pwa: {
        text: `Nouvelle version disponible`,
      },
      message: {
        titleChoice: `Nom de votre annonce (requis)`,
        textChoice: `Choix d'un texte`,
        topBannerContent: `Personnalisez les éléments de votre annonce pour demander sa réalisation.`,
        voiceChoice: `Choix d'une voix`,
        soundChoice: `Choix d'un fond musical`,
        scheduling: `Programmation`,
        temporaryMessage: `Message provisoire`,
        user: {
          userBannerContent: `Saisissez vos informations et leurs prononciations pour un message vocal adapté`,
          identity: `Votre identité`,
          companyLabel: `Votre société (requise)`,
          companyPlaceorder: `Votre société`,
          companyPhoneticLabel: `Sa phonétique`,
          companyPhoneticPlaceorder: `Ex : é li asse pour Elias`,
          firstNameLabel: `Votre prénom (requis)`,
          firstNamePlaceorder: `Votre prénom`,
          firstNamePhoneticLabel: `Sa phonétique`,
          firstNamePhoneticPlaceorder: `Ex : é li asse pour Elias`,
          lastNameLabel: `Votre nom (requis)`,
          lastNamePlaceorder: `Votre nom`,
          lastNamePhoneticLabel: `Sa phonétique`,
          lastNamePhoneticPlaceorder: `Ex : é li asse pour Elias`,
          gender: `Votre civilité (requise)`,
          genderExemple: `Exemple : Thomas Nova n'est pas disponible, il vous joindra dès que possible.`,
        },
        title: {
          topBanner: `Modifier le nom de votre annonce`,
          field: `Nom de votre annonce (requis)`,
          placeholder: `annonce par défaut`,
        },
        text: {
          yourText: `Votre texte`,
          topBanner: {
            content: {
              '1': `Sélectionnez le type d'annonce que vous souhaitez créer`,
              '2': `Sélectionnez une annonce selon votre choix`,
              '3': `Rédigez votre annonce en fonction de vos choix personnels.`,
              '4': `Rédigez votre annonce en fonction de vos choix personnels.`,
              default: `Nouvelle étape`,
            },
          },
          LEAVE: `Congés`,
          DEFAULT: `Annonce par défaut`,
          CUSTOM_MESSAGE: `Créez votre propre message`,
          create: {
            label: `Contenu de votre annonce`,
            maxChar: `Caractères maximum`,
          },
        },
        voice: {
          card: {
            predefined: `Voix prédéfinie`,
            predefinedText: `Voix définie par l’entreprise`,
            customVoice: `Voix enregistrée`,
            customVoiceText: `Voix enregistrée par l'utilisateur`,
          },
          topBanner: {
            content: {
              '1': `Sélectionnez un type de voix pour votre annonce`,
              '2': `Sélectionnez la comédienne ou le commédien qui s’adressera à vos interlocuteurs`,
              default: `Nouvelle étape`,
            },
          },
          ATS_VOICE: `Choisir un ou une comédienne`,
          CUSTOM_VOICE: `Enregister votre voix`,
          RESTRICTED_VOICE: `Voix selectionnée par l'entreprise`,
          available: `Disponible`,
          notAvailable: `Non Disponible`,
          french: `Français`,
        },
        sound: {
          nbFilter: `{{ count }} filtre`,
          nbFilter_plural: `{{ count }} filtres`,
          card: {
            predefined: `Musique prédéfinie`,
            predefinedText: `Musique de l’entreprise`,
            noSound: `Pas de musique`,
            noSoundText: `Aucune musique jouée`,
          },
          topBanner: {
            content: {
              '1': `Sélectionnez le type de fond musical que vous souhaitez jouer`,
              '2': `Sélectionnez un morceau du catalogue. Utilisez les filtres pour faciliter votre choix.`,
              default: `Nouvelle étape`,
            },
          },
          ATS_SOUND: `Voir le catalogue`,
          NO_SOUND: `Pas de fond musical`,
          UPLOAD_SOUND: `Charger un fichier`,
          search: {
            placeholder: `Rechercher un titre, un style, un artiste`,
          },
        },
        state: {
          refused: `Refusé`,
        },
        scheduler: {
          topBanner: {
            content: {
              '1': `Sélectionnez quand votre annonce sera installée`,
              '2': `Sélectionnez le jour et l'heure ou votre annonce sera installée`,
              default: `Nouvelle étape`,
            },
          },
          duration: `L'annonce sera installée `,
          defaultMessage: `Dès que possible`,
        },
        success: {
          title: `C'est noté !`,
          controls: `Découvrir l'application`,
          controlsStandalone: `Ecran d'accueil`,
          text: `Votre demande à bien été prise en compte`,
          footer: `Pour plus de précisions sur le service, vous pouvez consulter nos mentions légales.`,
        },
        installation: {
          topBannerContent: `Vérifiez l'annonce proposée et validez par votre code de messagerie pour sa mise en place`,
          messageNew: `Votre nouvelle annonce`,
          messageDetails: `Détails de l'annonce`,
          newMessage: {
            askDate: `Demande d'annonce le {{date}}`,
            createdAt: `Annonce produite le {{date}}`,
            name: `Titre de l'annonce`,
            text: `Mon texte`,
            voice: `Choix d'une voix`,
            sound: `Choix d'un fond musical`,
            noSound: `Pas de son selectionné`,
            scheduler: `Planification`,
            deploymentCreatedAt: `Date d'envoi`,
          },
          messagingCode: {
            title: `Pour mettre en place votre annonce dans votre boîte vocale :`,
            fieldName: `Votre code de messagerie (requis)`,
            placeholder: `Votre code de messagerie`,
            operatorPopover: `Demander le code à votre opérateur`,
            popover: {
              title: `Pour obtenir votre code de messagerie, composez selon votre opérateur:`,
              help: `Vous ne connaissez pas votre opérateur ?`,
              helpContent: `Composez les numéros cités plus haut jusqu'à reconnaissance de votre compte client`,
            },
            rgpdText: `Dans le cadre de l'utilisation de vos données personnelles, nous souhaiterions avoir votre consentement pour :`,
            rgpdAction: `Enregistrer votre code de messagerie pour faciliter la mise en place de vos annonces dans votre boîte vocale`,
            footer: ` Pour plus de précisions sur le service, vous pouvez consulter nos mentions légales.`,
          },
          action: {
            refuse: `Je refuse ce message`,
            accept: `Je valide ce message`,
          },
          dialogTitleRefuseMessage: `Pour quelle raison souhaitez-vous refuser ce message ?`,
          temporary: {
            topBanner: `Dans le cadre de votre offre de personnalisation de votre messagerie vocale, nous avons fait plusieurs tentatives pour vous accompagner dans cette démarche.`,
            topBanner2: `Vous trouverez ci-dessous une proposition de message qui pourrait vous plaire. Si tel est le cas, vous pouvez la télécharger sur votre installation en validant ce message, afin de l'utiliser pour accueillir vos correspondants.`,
            topBanner3: `Si ce message ne correspond pas à vos attentes, vous avez la possibilité de le refuser et de nous contacter. Nous pourrons ainsi élaborer ensemble un message plus en accord avec vos souhaits.`,
          },
        },
      },
    },
  },
};

export default fr;

import React from 'react';
import TypeVoice, {
  IProps as TypeVoiceProps,
} from '../components/selection/content/types.components';
import VoicesList, {
  IProps as VoicesListProps,
} from '../components/selection/content/list.component';

export enum Steps {
  STEP_1 = 1,
  STEP_2 = 2,
}

export interface Step {
  id: number;
  Content: React.ElementType;
}

export interface Config {
  ndSteps: number;
  steps: Step[];
}

export default {
  nbStep: 2,
  steps: [
    {
      id: Steps.STEP_1,
      Content: (props: TypeVoiceProps) => <TypeVoice {...props}></TypeVoice>,
    },
    {
      id: Steps.STEP_2,
      Content: (props: VoicesListProps) => <VoicesList {...props}></VoicesList>,
    },
  ],
};

import { getAuthenticatedInstance } from './helpers/axios.helpers';
import { Message } from '../../@types/state/message';
import { ValidInstallation } from '../../@types/message';

const authenticatedInstance = getAuthenticatedInstance();

export async function getMessages(): Promise<any> {
  return authenticatedInstance.get('/messages');
}

export async function getRefusedReasons(): Promise<any> {
  return authenticatedInstance.get('/refused_reasons');
}

export async function installationMessage(
  installation: ValidInstallation
): Promise<any> {
  return authenticatedInstance.post('/rpc/install_message', installation);
}

export async function setCurrentMessage(message: Message): Promise<any> {
  //   return authenticatedInstance.post('/messages', message);
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve({
        status: 200,
      });
    }, 2000);
  });
}

export async function refuseMessage(
  messageId: Message['id'],
  reasonId: number | null
) {
  return authenticatedInstance.post('/rpc/refuse_message', {
    message_id: messageId,
    reason_id: reasonId,
  });
}

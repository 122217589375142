import * as newsAction from '../modules/news/news.action';
import * as notificationAction from '../modules/notification/notification.action';
import * as infoAction from '../modules/info/info.action';
import * as orderAction from '../modules/order/order.action';
import * as messageAction from '../modules/message/message.action';
import * as planningAction from '../modules/scheduler/planning.action';

export async function loadAllData(dispatch: React.Dispatch<any>) {
  dispatch(infoAction.getInfos());
  dispatch(orderAction.getOrders());
  dispatch(messageAction.getMessages());
  dispatch(planningAction.getPlannings());
  dispatch(newsAction.getNews());
  dispatch(notificationAction.getNotifications());
}

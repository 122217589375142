export enum OrderActionTypes {
  SAVE_PHONE_NUMBER = '@@order/SAVE_PHONE_NUMBER',
  SAVE_TITLE = '@@order/SAVE_TITLE',
  SAVE_VOICE = '@@order/SAVE_VOICE',
  SAVE_TEMPLATE = '@@order/SAVE_TEMPLATE',
  SAVE_TEMPLATE_DYNAMIC_VARIABLES = '@@order/SAVE_TEMPLATE_DYNAMIC_VARIABLES',
  SAVE_SOUND = '@@order/SAVE_SOUND',
  ACTIVATE_MULTILINGUAL = '@@order/ACTIVATE_MULTILINGUAL',
  SAVE_MULTILINGUAL = '@@order/SAVE_MULTILINGUAL',
  RESET_IN_PROGRESS_ORDER = '@@order/RESET_IN_PROGRESS_ORDER',
  CREATE_ORDER_PENDING = '@@order/CREATE_ORDER_PENDING',
  CREATE_ORDER_SUCCESS = '@@order/CREATE_ORDER_SUCCESS',
  CREATE_ORDER_ERROR = '@@order/CREATE_ORDER_ERROR',
  GET_ORDERS_PENDING = '@@order/GET_ORDER_PENDING',
  GET_ORDERS_SUCCESS = '@@order/GET_ORDER_SUCCESS',
  GET_ORDERS_ERROR = '@@order/GET_ORDER_ERROR',
  SET_NO_SOUND = '@@order/SET_NO_SOUND',
  SET_SCHEDULER = '@@order/SET_SCHEDULER',
  CREATE_REMIX_PENDING = '@@order/CREATE_REMIX_PENDING',
  CREATE_REMIX_SUCCESS = '@@order/CREATE_REMIX_SUCCESS',
  CREATE_REMIX_ERROR = '@@order/CREATE_REMIX_ERROR',
  CREATE_INSTALL_PENDING = '@@order/CREATE_INSTALL_PENDING',
  CREATE_INSTALL_SUCCESS = '@@order/CREATE_INSTALL_SUCCESS',
  CREATE_INSTALL_ERROR = '@@order/CREATE_INSTALL_ERROR',
}

import * as React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// MUI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Store
import * as soundAction from '../../sound/sound.action';
import { selectVoiceById } from '../../voice/voice.selector';
import { selectOrderById } from '../../order/order.selector';
import { selectSoundById } from '../../sound/sound.selector';
import { selectInprogressOrder } from 'modules/order/order.selector';

// Components
import TemplateCardDisplay from '../../template/components/cardDisplay.component';

// Others
import { SoundSource } from 'modules/order/config/order.config';

import { Sound } from '../../../@types/state/sound';
import { Message } from '../../../@types/state/message';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `0 ${theme.spacing(2)}px`,
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  name: {
    fontWeight: 'bold',
  },
  text: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  scheduler: {
    color: theme.palette.text.disabled,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

interface IProps {
  classe?: string;
  message: Message;
  startignOn?: string | null;
}

export default function MessageCard(props: IProps) {
  const { classe, message, startignOn } = props;
  const [selectedSoundId, setSelectedSoundId] = React.useState('');
  const [sound, setSound] = React.useState<Sound | null>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const voice = useSelector(selectVoiceById)(message.voice_id.toString());
  const order = useSelector(selectOrderById)(message.order_id);
  const getSoundById = useSelector(selectSoundById);
  const inProgressOrder = useSelector(selectInprogressOrder);
  const noSoundSelected =
    !!inProgressOrder && inProgressOrder.sound_source === SoundSource.NO_SOUND;

  /*
    Message validation : search sound
    Message remix : use the in progress sound id
  */
  React.useEffect(() => {
    if (order && inProgressOrder && !inProgressOrder.sound_id) {
      const { ats_sound_id } = order;
      if (ats_sound_id) {
        dispatch(
          soundAction.getSoundsWithSearchParams({
            search: ats_sound_id,
            types: [],
            categories: [],
          })
        );
        setSelectedSoundId(ats_sound_id);
      }
    }
    if (inProgressOrder && inProgressOrder.sound_id) {
      setSelectedSoundId(inProgressOrder.sound_id);
    }
  }, [dispatch, order, selectedSoundId, inProgressOrder]);

  React.useEffect(() => {
    if (selectedSoundId) {
      const searchedSound = getSoundById(selectedSoundId);
      if (searchedSound) {
        setSound(searchedSound);
      }
    }
  }, [getSoundById, selectedSoundId]);

  return (
    <Card
      id="message-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <CardContent className={classes.content}>
        <Typography color="textSecondary">
          {t('message.installation.newMessage.name')}
        </Typography>
        <Typography className={clsx(classes.name, classes.text)} variant="h6">
          {message.title}
        </Typography>

        <Typography color="textSecondary">
          {t('message.installation.newMessage.text')}
        </Typography>

        <TemplateCardDisplay
          classe={classes.message}
          templateId={
            order?.template_id ? Number(order.template_id) : undefined
          }
          dynamicVariables={order?.dynamic_variables}
          contentCustom={!order?.template_id ? message.content : undefined}
        />

        <Typography color="textSecondary">
          {t('message.installation.newMessage.voice')}
        </Typography>
        {voice && (
          <Typography className={classes.text} variant="h6">
            {voice.name}
          </Typography>
        )}

        <Typography color="textSecondary">
          {t('message.installation.newMessage.sound')}
        </Typography>
        {sound && !noSoundSelected && (
          <Typography className={classes.text} variant="h6">
            {sound.name} - {sound.artist}
          </Typography>
        )}
        {(!sound || noSoundSelected) && (
          <Typography className={classes.text} variant="h6">
            {t('message.installation.newMessage.noSound')}
          </Typography>
        )}

        {/* <Typography>
          {t('message.installation.newMessage.scheduler')}
        </Typography>
        <Typography className={classes.scheduler} variant="h6">
          Fonction à venir
        </Typography> */}

        <Typography color="textSecondary">
          {t('message.installation.newMessage.deploymentCreatedAt')}
        </Typography>

        {order && !startignOn && (
          <Typography className={classes.text} variant="h6">
            {moment(order.created_at).format('dddd D MMMM YYYY à HH:mm:ss')}
          </Typography>
        )}
        {startignOn && <Typography>{startignOn}</Typography>}
      </CardContent>
    </Card>
  );
}

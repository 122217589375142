import React, { Dispatch, SetStateAction } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as soundAction from '../../../sound.action';
import * as playerAction from '../../../../player/player.action';
import { PlayerStateEnum } from '../../../../player/config/player.config';
import { selectPlayerState } from '../../../../player/player.selector';
import Loader from '../../../../utils/components/loader.component';
import { selectFetchPending } from '../../../sound.selector';
import config from '../../../../../application/app.config';

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(7),
    padding: theme.spacing(1),
    color: 'rgba(128, 142, 149, 0.38)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
  },
  inputRoot: {
    width: '100%',
  },
  inputInput: {
    color: theme.palette.text.secondary,
    textOverflow: 'ellipsis',
  },
}));

interface IProps {
  classe?: string;
  setSelectedSoundId: Dispatch<SetStateAction<string>>;
  params: string;
  setParams: Dispatch<SetStateAction<string>>;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  types: string[];
  categories: string[];
}

export default function Search(props: IProps) {
  const {
    classe,
    setSelectedSoundId,
    params,
    setParams,
    offset,
    setOffset,
    types,
    categories,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const playerState = useSelector(selectPlayerState);
  const fetchPending = useSelector(selectFetchPending);
  const search = React.useRef(
    debounce((options: soundAction.IOptions) => {
      dispatch(soundAction.getSoundsWithSearchParams(options));
    }, config.search.debounceTime)
  );

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const params = event.target.value;
    window.scrollTo(0, 0);
    setParams(params);
    setSelectedSoundId('');
    setOffset(config.search.sound.offset);
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    search.current({
      search: params,
      types: types,
      categories: categories,
      ...(offset && { offset: offset }),
    });
  }

  return (
    <div
      id="sound-search"
      className={classe ? clsx(classe, classes.search) : classes.search}
    >
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onChange={handleSearch}
        value={params}
        placeholder={t('message.sound.search.placeholder')}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        endAdornment={fetchPending ? <Loader size={30} /> : null}
      />
    </div>
  );
}

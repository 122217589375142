import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import { green, red } from '@material-ui/core/colors';

import TopBanner from '../../modules/banner/components/topBanner.component';
import BottomBanner from '../../modules/banner/components/bottomBanner.component';
import MessagePlayerCard from '../../modules/message/components/player/card.component';
import MessageCard from '../../modules/message/components/card.component';
// import Help from '../../modules/help/components/help.component';
import Loader from '../../modules/utils/components/loader.component';
import VoiceMailPin from '../../modules/message/components/voiceMailPin.component';
import { RefuseMessageDialog } from 'modules/message/components/refuseMessageDialog.component';

import {
  selectMessageById,
  selectFetchPending,
  selectFetchErrors,
} from '../../modules/message/message.selector';
import { selectPlayerState } from '../../modules/player/player.selector';

import * as messageAction from '../../modules/message/message.action';
import * as playerAction from '../../modules/player/player.action';

import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import { Endpoint } from '../../router/routes.config';
import { PlayerStateEnum } from '../../modules/player/config/player.config';
import { MessageEvent } from '../../modules/message/config/message.config';
import appConfig from '../../application/app.config';

import { Message } from '../../@types/state/message';
import { ValidInstallation } from '../../@types/message';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: `calc(var(--vh, 1vh) * 100)`,
    },
    content: {
      flex: 1,
      padding: `${115 + theme.spacing(2)}px ${theme.spacing(1)}px ${
        59 + theme.spacing(5)
      }px ${theme.spacing(1)}px`,
    },
    announcement: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    title: {
      fontWeight: 'bold',
    },
    divider: {
      margin: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
    },
    dividerCode: {
      margin: theme.spacing(2, 0),
    },
    voiceMailPin: {
      marginBottom: theme.spacing(1),
    },
    card: {
      marginBottom: theme.spacing(2),
    },
    translation: {
      margin: theme.spacing(3),
    },
    backdrop: {
      display: 'flex',
      justifyContent: 'center',
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    other: {
      '& p': {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(5),
      },
      '& strong': {
        textDecoration: 'underline',
      },
    },
    help: {
      display: 'flex',
      justifyContent: 'center',
    },
    previous: {
      color: theme.palette.common.white,
    },
    decisionBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    next: {
      color: theme.palette.common.white,
    },
  })
);

const RefuseButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const ConfirmButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

interface ParamTypes {
  messageId: string;
}

export default function MessageInstallation() {
  const [message, setMessage] = useState<Message | null>(null);
  const [voiceMailPin, setVoiceMailPin] = useState<string>('');
  const [savePin, setSavePin] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { messageId } = useParams<ParamTypes>();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const getMessageById = useSelector(selectMessageById);
  const fetchPending = useSelector(selectFetchPending);
  const fetchErrors = useSelector(selectFetchErrors);
  const playerState = useSelector(selectPlayerState);
  const [openRefuseMessageDialog, setOpenRefuseMessageDialog] = useState(false);
  const [openOnboardMessage, setOpenOnboardMessage] = React.useState(true);

  useEffect(() => {
    if (messageId) {
      const searchedMessage = getMessageById(parseInt(messageId));
      if (searchedMessage) {
        if (searchedMessage.state !== MessageEvent.ERROR_CODE) {
          history.push(Endpoint.HOME);
        }
        setMessage(searchedMessage);
      }
    }
  }, [getMessageById, history, messageId]);

  function handlePreviousClick() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    history.push(Endpoint.HOME);
  }

  async function handleInstallation() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    if (messageId) {
      const installation: ValidInstallation = {
        message_id: parseInt(messageId),
        voicemail_pin: voiceMailPin.toString(),
        save_pin: savePin,
      };

      await dispatch(messageAction.installationMessage(installation));
      if (!fetchErrors) {
        // TODO: use snackbar for display error info to user
      }
      history.push(Endpoint.HOME);
    }
  }

  function onKeyDown(e: React.KeyboardEvent) {
    if (e.keyCode === 13) {
      if (voiceMailPin && voiceMailPin.length > 0) {
        handleInstallation();
      }
    }
  }

  const isTemporaryMessage = message?.is_temporary;

  return (
    <div
      id="message-installation-view"
      className={classes.container}
      onKeyDown={onKeyDown}
    >
      <TopBanner
        title={routeMetaData && t(`${routeMetaData.name}`)}
        content={t('message.installation.topBannerContent')}
      />

      <div id="content" className={classes.content}>
        {(!message || fetchPending) && (
          <Backdrop className={classes.backdrop} open>
            <Loader />
          </Backdrop>
        )}
        {message && (
          <>
            <section id="new-message">
              <Typography className={classes.title} variant="h6">
                {t('message.installation.messageNew')}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              <MessagePlayerCard classe={classes.card} message={message} />
            </section>

            <section id="details">
              <Typography className={classes.title} variant="h6">
                {t('message.installation.messageDetails')}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              <MessageCard message={message} />
            </section>

            {/* VOICE MAIL PIN */}
            {message.with_code && (
              <section id="messaging-voiceMailPin">
                <VoiceMailPin
                  classe={classes.voiceMailPin}
                  voiceMailPin={voiceMailPin}
                  setVoiceMailPin={setVoiceMailPin}
                  savePin={savePin}
                  setSavePin={setSavePin}
                />
              </section>
            )}

            <Divider className={classes.dividerCode} />

            {isTemporaryMessage && (
              <section className={classes.decisionBox}>
                <ConfirmButton
                  disabled={message.with_code && !voiceMailPin}
                  variant="contained"
                  size="small"
                  onClick={handleInstallation}
                >
                  {t('message.installation.action.accept')}
                </ConfirmButton>
                <RefuseButton
                  variant="contained"
                  size="small"
                  onClick={() => setOpenRefuseMessageDialog(true)}
                >
                  {t('message.installation.action.refuse')}
                </RefuseButton>
              </section>
            )}

            {/* LEGAL  */}
            {appConfig.featureFlip.legal && (
              <section id="other" className={classes.other}>
                <Typography color="textSecondary">
                  {t('message.success.footer')}
                </Typography>
                {/* <div className={classes.help}>
                <Help />
              </div> */}
              </section>
            )}
          </>
        )}
      </div>

      <BottomBanner
        Previous={(props: any) => (
          <Button
            onClick={handlePreviousClick}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={
          isTemporaryMessage
            ? () => null
            : (props: any) => (
                <Button
                  onClick={() => handleInstallation()}
                  disabled={message?.with_code && !voiceMailPin}
                  classes={{ root: classes.next }}
                  {...props}
                >
                  {t('action.next')}
                </Button>
              )
        }
      />

      {/* Refuse Message Dialog */}
      {openRefuseMessageDialog && (
        <RefuseMessageDialog
          open={openRefuseMessageDialog}
          setOpen={setOpenRefuseMessageDialog}
          onConfirm={() => history.push(Endpoint.HOME)}
        />
      )}

      {/* Message dialog */}
      <Dialog
        open={!!isTemporaryMessage && openOnboardMessage}
        onClose={() => setOpenOnboardMessage(false)}
      >
        <DialogContent>
          <Typography className={classes.announcement}>
            {t('message.installation.temporary.topBanner')}
          </Typography>
          <Typography className={classes.announcement}>
            {t('message.installation.temporary.topBanner2')}
          </Typography>
          <Typography className={classes.announcement}>
            {t('message.installation.temporary.topBanner3')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenOnboardMessage(false)}>
            {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(4),
  },
  title: {
    margin: theme.spacing(3),
  },

  button: {},
}));

export default function Legal() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);

  return (
    <div id="legal-view" className={classes.container}>
      {routeMetaData && (
        <Typography variant="h6" className={classes.title}>
          {t(routeMetaData.name as string)}
        </Typography>
      )}
      <div className={classes.content}>
        <Typography variant="h6" gutterBottom>
          {t('legal.credit')}
        </Typography>

        <Typography variant="caption" color="textSecondary">
          {t('legal.development')}
        </Typography>
        <Typography paragraph>Everblix</Typography>

        <Typography variant="h6" gutterBottom>
          {t('legal.identity')}
        </Typography>

        <Typography variant="caption" color="textSecondary">
          {t('legal.social')}
        </Typography>
        <Typography>ATS Studios</Typography>

        <Typography variant="caption" color="textSecondary">
          {t('legal.headQuarter')}
        </Typography>
        <Typography>32, quai JAYR – 69009 LYON</Typography>

        <Typography variant="caption" color="textSecondary">
          {t('legal.phone')}
        </Typography>
        <Typography>04 72 53 72 72</Typography>

        <Typography variant="caption" color="textSecondary">
          {t('legal.mail')}
        </Typography>
        <Typography paragraph>
          <a href="mailto:communication@ats-studios.com">
            communication@ats-studios.com
          </a>
        </Typography>

        <Typography variant="caption" color="textSecondary">
          {t('legal.rcs')}
        </Typography>
        <Typography>RCS Lyon 401 067 657</Typography>

        <Typography variant="caption" color="textSecondary">
          {t('legal.siret')}
        </Typography>
        <Typography paragraph>401 067 657 00015</Typography>

        <Typography variant="h6" gutterBottom>
          {t('legal.cgu')}
        </Typography>

        <a
          href="https://api.mobilitebyats.com/resources/media/cgu_20201117.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('legal.cgulink')}
        </a>
      </div>

      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => history.goBack()}
      >
        {t('action.previous')}
      </Button>
    </div>
  );
}

export const planningsResponse = new Promise(function (resolve) {
  setTimeout(function () {
    resolve({
      data: [
        {
          id: 4,
          is_default: true,
          description: 'planning test default new mobility',
          created_at: '2020-04-15T17:25:46+00:00',
          start_date: null,
          stop_date: null,
          week_days: [
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
          ],
        },
        {
          id: 42,
          is_default: false,
          description: 'planning test specific new mobility',
          created_at: '2020-04-15T17:25:46+00:00',
          start_date: '2020-04-15T17:25:46+00:00',
          stop_date: '2020-04-30T17:25:46+00:00',
          week_days: [
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
            [
              {
                stop_time: '23:59:59',
                start_time: '00:00:00',
              },
            ],
          ],
        },
      ],
      status: 200,
    });
  }, 1000);
});

import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
  thumbnail: {
    fontSize: theme.spacing(8),
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.text.primary,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IProps {
  children?: React.ReactChild;
  classe?: string;
}

export default function CustomVoiceCard(props: IProps) {
  const { children, classe } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card
      id="custom-voice-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <CardContent className={classes.content}>
        <PersonOutlineRoundedIcon className={classes.thumbnail} />
        <div className={classes.description}>
          <Typography className={classes.text} variant="h6">
            {t('message.voice.card.customVoice')}
          </Typography>

          <Typography className={classes.text}>
            {t('message.voice.card.customVoiceText')}
          </Typography>
        </div>
      </CardContent>
      {children && <div className={classes.action}>{children}</div>}
    </Card>
  );
}

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { config } from './i18n.config';
import fr from './lang/fr';
import en from './lang/fr';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ...config,
    resources: {
      ...fr,
      ...en
    },
  });

export default i18n;

import React from 'react';
import { Step } from '@material-ui/core';
import Categories, {
  IProps as CategoriesProps,
} from '../components/selection/content/categories.components';
import TemplatesList, {
  IProps as TemplatesListProps,
} from '../components/selection/content/list.component';
import TemplateCreate, {
  IProps as TemplatesCreateProps,
} from '../components/selection/content/create.component';
import TemplateUpdate, {
  IProps as TemplatesUpdateProps,
} from '../components/selection/content/update.component';

export enum Steps {
  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
}

export interface Step {
  Content: React.ElementType;
  nbStep: number;
  nextStep: null | number;
  previousStep: null | number;
}

export interface Config {
  steps: { [index: number]: Step };
}

const config: Config = {
  steps: {
    [Steps.STEP_1]: {
      Content: (props: CategoriesProps) => <Categories {...props}></Categories>,
      nbStep: 2,
      nextStep: null,
      previousStep: null,
    },
    [Steps.STEP_2]: {
      Content: (props: TemplatesListProps) => (
        <TemplatesList {...props}></TemplatesList>
      ),
      nbStep: 2,
      nextStep: null,
      previousStep: Steps.STEP_1,
    },
    [Steps.STEP_3]: {
      Content: (props: TemplatesCreateProps) => (
        <TemplateCreate {...props}></TemplateCreate>
      ),
      nbStep: 3,
      nextStep: null,
      previousStep: Steps.STEP_1,
    },
    [Steps.STEP_4]: {
      Content: (props: TemplatesUpdateProps) => (
        <TemplateUpdate {...props}></TemplateUpdate>
      ),
      nbStep: 4,
      nextStep: null,
      previousStep: Steps.STEP_2,
    },
  },
};

export default config;

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Template } from '../../../@types/state/template';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    maxWidth: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px`,
  },
  text: {
    color: theme.palette.text.secondary,
    '&>strong': {
      '&:after': {
        color: theme.palette.text.primary,
        content: `attr(data-content)`,
      },
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IProps {
  title?: boolean;
  children?: React.ReactChild;
  updatedTemplate: null | Template | string;
  isCustomTemplate: boolean | null;
  classe?: string;
}

export default function TemplateCard(props: IProps) {
  const { title, children, updatedTemplate, classe, isCustomTemplate } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  // dangerouslySetInnerHTML is secured by css pseudo element and data-content attribute
  return (
    <Card
      id="template-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      {title && (
        <Typography className={classes.title}>
          {t('message.text.yourText')}
        </Typography>
      )}

      <CardContent className={classes.content}>
        {!isCustomTemplate &&
          updatedTemplate &&
          typeof updatedTemplate !== 'string' && (
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: updatedTemplate.content || '',
              }}
            />
          )}
        {isCustomTemplate &&
          updatedTemplate &&
          typeof updatedTemplate === 'string' && (
            <Typography className={classes.text}>{updatedTemplate}</Typography>
          )}
      </CardContent>
      {children && <div className={classes.action}>{children}</div>}
    </Card>
  );
}

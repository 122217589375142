import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PlayerButtonRounded from '../../../player/components/button/rounded.component';
import { Message } from '../../../../@types/state/message';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  player: {
    margin: theme.spacing(2, 0),
  },
  info: {
    alignSelf: 'stretch',
  },
}));

interface IProps {
  classe?: string;
  message?: Message;
  info?: boolean;
  isDisabled?: boolean;
}

export default function MessagePlayerCard(props: IProps) {
  const { classe, message, info, isDisabled } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card
      id="message-player-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <CardContent classes={{ root: classes.content }}>
        <PlayerButtonRounded
          id={`${message?.id ?? 'noMessage'}`}
          path={message?.message_url ?? ''}
          classe={classes.player}
          {...(isDisabled && { isDisabled: true })}
        />
        {info && message && (
          <>
            <Typography
              color="textSecondary"
              align="right"
              variant="caption"
              className={classes.info}
            >
              {t('message.installation.newMessage.askDate', {
                date: moment(message.created_at).format('DD/MM/YYYY'),
              })}
            </Typography>
            <Typography
              color="textSecondary"
              align="right"
              variant="caption"
              className={classes.info}
            >
              {t('message.installation.newMessage.createdAt', {
                date: moment(message.produced_at).format('DD/MM/YYYY'),
              })}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}

import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { PlanningActionTypes } from './types';
import { PlanningAction } from './planning.action';

export const initialState = {
  pending: false,
  data: {
    plannings: null,
  },
  initFetch: true,
  errors: undefined,
};

const planning: Reducer<State['planning'], PlanningAction> = (
  state: State['planning'] = initialState,
  action: PlanningAction
) => {
  switch (action.type) {
    case PlanningActionTypes.GET_PLANNINGS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case PlanningActionTypes.GET_PLANNINGS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case PlanningActionTypes.GET_PLANNINGS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default planning;

import { Action } from 'redux';
import { RecorderActionTypes } from './types';
import {RecordState} from './config/recorder.config';

export type RecorderAction = SetRecorderOpen | SetRecording | SetStopRecording;

export interface SetRecorderOpen
  extends Action<RecorderActionTypes.SET_RECORDER_OPEN> {
  open: boolean;
}

export function setRecorderOpen(open: boolean): SetRecorderOpen {
  return { type: RecorderActionTypes.SET_RECORDER_OPEN, open };
}

export interface SetRecording
  extends Action<RecorderActionTypes.SET_RECORDING> {
  recordState: RecordState;
}
export function setRecording(): SetRecording {
  return { type: RecorderActionTypes.SET_RECORDING, recordState: RecordState.RECORDING };
}

export interface SetStopRecording
  extends Action<RecorderActionTypes.SET_STOP_RECORDING> {
  recordState: RecordState;
}
export function setStopRecording(): SetStopRecording {
  return { type: RecorderActionTypes.SET_STOP_RECORDING, recordState: RecordState.NOT_RECORDING };
}

import React, { Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';

import * as recorderAction from '../../../../recorder/recorder.action';
import * as orderAction from '../../../../order/order.action';
import { selectOpen } from '../../../../recorder/recorder.selector';
import { selectUpdatedTemplateForDisplay } from '../../../../template/template.selector';
import { selectInfos } from 'modules/info/info.selector';

import Progress from '../../../../utils/components/progress.component';
import Recorder from '../../../../recorder/components/recorder.component';
import { Endpoint } from 'router/routes.config';

import { ValidUpdateVoice } from '../../../../../@types/order';

enum voiceActions {
  ATS_VOICE = 'ATS_VOICE',
  CUSTOM_VOICE = 'CUSTOM_VOICE',
  RESTRICTED_VOICE = 'RESTRICTED_VOICE',
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    minHeight: theme.spacing(8),
    marginBottom: theme.spacing(3),
  },
}));

export interface IProps {
  activeStepId: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export default function VoiceTypes(props: IProps) {
  const { activeStepId, setActiveStep } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isRecorderOpen = useSelector(selectOpen);
  const updatedTemplateForDisplay = useSelector(
    selectUpdatedTemplateForDisplay
  );
  const infos = useSelector(selectInfos);
  const { access } = infos || {};

  const canChooseVoice =
    access?.all_granted || access?.disable_voice_restriction;

  const canRegisterCustomMessage =
    access?.with_custom_voice ||
    access?.all_granted ||
    access?.disable_voice_restriction;

  const canUsePredefinedVoice =
    access &&
    access?.voice_ids_female &&
    access?.voice_ids_female?.length > 0 &&
    access?.voice_ids_male &&
    access?.voice_ids_male?.length > 0;

  function handleDefaultClick() {
    setActiveStep(activeStepId + 1);
  }

  function handleRecord() {
    dispatch(recorderAction.setRecorderOpen(true));
  }

  function handleSelectPredefinedVoice() {
    const accessVoiceId =
      access &&
      access?.[`voice_ids_${infos?.gender ? infos?.gender : 'male'}`]?.[0];
    if (accessVoiceId) {
      dispatch(
        orderAction.saveVoice({
          voice_id: accessVoiceId,
          custom_voice_url: null,
        })
      );
      history.push(Endpoint.ORDER_CREATE);
    }
  }

  async function handleCustomVoiceSave(customVoiceUrl: string) {
    const voice: ValidUpdateVoice = {
      voice_id: null,
      custom_voice_url: customVoiceUrl,
    };
    await dispatch(orderAction.saveVoice(voice));

    await history.goBack();
  }

  return (
    <div id="voice-types" className={classes.container}>
      {canChooseVoice && (
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          disableElevation
          size="large"
          onClick={handleDefaultClick}
          value={voiceActions.ATS_VOICE}
        >
          {t(`message.voice.${voiceActions.ATS_VOICE}`)}
        </Button>
      )}

      {canRegisterCustomMessage && (
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          disableElevation
          size="large"
          value={voiceActions.CUSTOM_VOICE}
          onClick={handleRecord}
        >
          {t(`message.voice.${voiceActions.CUSTOM_VOICE}`)}
        </Button>
      )}

      {canUsePredefinedVoice && (
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          disableElevation
          size="large"
          value={voiceActions.RESTRICTED_VOICE}
          onClick={handleSelectPredefinedVoice}
        >
          {t(`message.voice.${voiceActions.RESTRICTED_VOICE}`)}
        </Button>
      )}

      {isRecorderOpen && (
        <Recorder
          topBannerTitle={t('recorder.customVoice.topBannerTitle')}
          topBannerContent={t('recorder.customVoice.topBannerContent')}
          updatedTemplate={updatedTemplateForDisplay}
          Progress={() => <Progress percentage={1} />}
          onCustomVoiceSave={handleCustomVoiceSave}
        />
      )}
    </div>
  );
}

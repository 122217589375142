import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import VoiceCard from '../../../../voice/components/card.component';
import CustomVoiceCard from '../../../../voice/components/cardCustomVoice.component';
import TemplateCardDisplay from '../../../../template/components/cardDisplay.component';
import { selectVoiceById } from '../../../../voice/voice.selector';

import {
  inProgressMultilingual,
  sortInProgressMultilingual,
} from '../../../../../@types/order';

import { Voice } from '../../../../../@types/state/voice';
import { selectInprogressOrder } from 'modules/order/order.selector';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  item: {
    padding: theme.spacing(2),
  },
  voice: {
    padding: theme.spacing(2),
  },
  text: {
    padding: theme.spacing(2),
  },
}));

interface IProps {
  setIsStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  languages: inProgressMultilingual;
  setLanguages: React.Dispatch<React.SetStateAction<inProgressMultilingual>>;
  sortLanguages: sortInProgressMultilingual[];
  setSortLanguages: React.Dispatch<
    React.SetStateAction<sortInProgressMultilingual[]>
  >;
}

export default function Validation(props: IProps) {
  const { setIsStepValid, languages, sortLanguages, setSortLanguages } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const getVoiceById = useSelector(selectVoiceById);

  const inProgrogressOrder = useSelector(selectInprogressOrder);

  // sort language with order key
  React.useEffect(() => {
    let ignore = false;
    function sortLanguage() {
      const deepCopy = JSON.parse(JSON.stringify(languages));

      const languagesArray: sortInProgressMultilingual[] =
        Object.values(deepCopy);
      languagesArray
        .sort(
          (a: sortInProgressMultilingual, b: sortInProgressMultilingual) => {
            if (!a.order || !b.order) return 0;
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;

            return 0;
          }
        )
        .map((language: sortInProgressMultilingual) => delete language.order);

      setSortLanguages(languagesArray);
    }

    if (!ignore) sortLanguage();

    return () => {
      ignore = true;
    };
  }, [languages, setSortLanguages]);

  // Valid step on display
  React.useEffect(() => {
    setIsStepValid(true);
  }, [setIsStepValid]);

  return (
    <div id="validation-selection" className={classes.container}>
      {sortLanguages.map((lang: sortInProgressMultilingual) => {
        let voice: Voice | null = null;

        // Use content of in progress order for default language
        const customContent =
          !lang.content && !lang.template_id
            ? inProgrogressOrder?.content
            : lang.content;

        if (lang && lang.voice_id) {
          voice = getVoiceById(lang.voice_id.toString());
        }

        return (
          <div key={lang.language}>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              {t(`multilingual.${lang.language}`)}
            </Typography>
            <Divider />
            {voice && (
              <div className={classes.voice}>
                <VoiceCard available voice={voice} />
              </div>
            )}
            {!voice && (
              <div className={classes.voice}>
                <CustomVoiceCard />
              </div>
            )}
            <div className={classes.text}>
              <TemplateCardDisplay
                title
                templateId={
                  lang.template_id ? Number(lang.template_id) : undefined
                }
                dynamicVariables={inProgrogressOrder?.dynamic_variables}
                contentCustom={customContent}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export enum MessageEvent {
  CREATED = 'created',
  TO_INSTALL = 'to_install',
  INSTALLING = 'installing',
  INSTALLED = 'installed',
  ERROR = 'error',
  ERROR_CODE = 'error_code',
  SCHEDULED = 'scheduled',
  DELETED = 'deleted',
  REFUSED = 'refused',
}

export enum MessageActions {
  CREATE_ORDER = 'create_order',
  CREATE_INSTAL = 'create_install',
  CREATE_REMIX = 'create_remix',
}

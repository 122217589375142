import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Endpoint } from '../../../router/routes.config';
import { isRunningStandalone, isIos } from '../../../modules/utils/display';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.background.paper,
      height: `calc(var(--vh, 1vh) * 100)`,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 2,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1, 9),
      '& p': {
        textAlign: 'center',
      },
      '& h4': {
        color: theme.palette.text.primary,
        padding: theme.spacing(2),
      },
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      '& button': {
        minHeight: theme.spacing(8),
      },
    },
  })
);

export default function OrderCreateSuccess() {
  const [runningStandalone, setRunningStandalone] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (isRunningStandalone()) {
      setRunningStandalone(true);
    }
  }, []);

  function handleClick() {
    history.push(Endpoint.HOME);
  }

  return (
    <div id="order-create-success-view" className={classes.container}>
      <div id="content" className={classes.content}>
        <Typography variant="h4">{t('message.success.title')}</Typography>
        <Typography>{t('message.success.text')}</Typography>
      </div>
      <div id="controls" className={classes.controls}>
        {!runningStandalone && !isIos() && (
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            onClick={handleClick}
          >
            {t('message.success.controls')}
          </Button>
        )}
        {(runningStandalone || isIos()) && (
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            onClick={handleClick}
          >
            {t('message.success.controlsStandalone')}
          </Button>
        )}
      </div>
    </div>
  );
}

import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

import { selectInfos } from 'modules/info/info.selector';

import { Gender } from 'modules/info/config/info.config';

import BottomBanner from 'modules/banner/components/bottomBanner.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(4),
  },
  label: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  previous: {
    color: theme.palette.common.white,
  },
}));

export default function Profile() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const infos = useSelector(selectInfos);

  return (
    <div id="legal-view" className={classes.container}>
      <div className={classes.content}>
        <Typography variant="h6" gutterBottom>
          {t('profile.general')}
        </Typography>

        <form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={3}>
                <InputLabel htmlFor="civility" className={classes.label}>
                  {t('profile.civility')}
                </InputLabel>
                <TextField
                  id="civility"
                  defaultValue={
                    (infos?.gender && infos.gender === Gender.FEMALE
                      ? `${t('profile.female')}`
                      : `${t('profile.male')}`) || ''
                  }
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor="firstName" className={classes.label}>
                {t('profile.firstName')}
              </InputLabel>
              <TextField
                id="firstName"
                defaultValue={(infos?.first_name && infos.first_name) || ''}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor="lastName" className={classes.label}>
                {t('profile.lastName')}
              </InputLabel>
              <TextField
                id="lastName"
                defaultValue={(infos?.last_name && infos.last_name) || ''}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="companyName" className={classes.label}>
                {t('profile.company')}
              </InputLabel>
              <TextField
                id="companyName"
                defaultValue={
                  (infos?.lm_company_name && infos.lm_company_name) || ''
                }
                disabled
              />
            </Grid>
          </Grid>
        </form>

        <Typography variant="h6" gutterBottom>
          {t('profile.line')}
        </Typography>

        <form>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <InputLabel htmlFor="businessName" className={classes.label}>
                {t('profile.businessName')}
              </InputLabel>
              <TextField
                id="businessName"
                defaultValue={
                  (infos?.business_name && infos.business_name) || ''
                }
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={8}>
              <InputLabel htmlFor="operatorName" className={classes.label}>
                {t('profile.operator')}
              </InputLabel>
              <TextField
                id="operatorName"
                defaultValue={
                  (infos?.operator_name && infos.operator_name) || ''
                }
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="voiceMailPin" className={classes.label}>
                {t('profile.voiceMailPin')}
              </InputLabel>
              <TextField
                id="voiceMailPin"
                defaultValue={
                  (infos?.voicemail_pin && infos.voicemail_pin) || ''
                }
                disabled
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <BottomBanner
        Previous={(props: unknown) => (
          <Button
            onClick={() => history.goBack()}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: unknown) => null}
      />
    </div>
  );
}

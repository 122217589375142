import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TopBanner from '../../modules/banner/components/topBanner.component';
import BottomBanner from '../../modules/banner/components/bottomBanner.component';
import { getTopBannerContentDependingOnStep } from '../../modules/multilingual/helpers/selection.helpers';
import config, {
  Steps,
} from '../../modules/multilingual/config/selection.config';
import Progress from '../../modules/utils/components/progress.component';
import * as orderAction from '../../modules/order/order.action';
import { selectInprogressOrder } from '../../modules/order/order.selector';

import {
  inProgressMultilingual,
  sortInProgressMultilingual,
} from '../../@types/order';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: `calc(var(--vh, 1vh) * 100)`,
    },
    content: {
      flex: 1,
      padding: `${115 + theme.spacing(2)}px ${theme.spacing(1)}px ${
        59 + theme.spacing(5)
      }px ${theme.spacing(1)}px`,
    },
    next: {
      color: theme.palette.common.white,
    },
    previous: {
      color: theme.palette.common.white,
    },
  })
);

export default function MultilingualSelection() {
  const [activeStep, setActiveStep] = useState(Steps.STEP_1);
  const [isStepValid, setIsStepValid] = useState(false);
  const inProgressOrder = useSelector(selectInprogressOrder);
  const [sortLanguages, setSortLanguages] = React.useState<
    sortInProgressMultilingual[]
  >([]);
  const [languages, setLanguages] = useState<inProgressMultilingual>({});
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const activeStepData = config.steps[activeStep];
  const { Content } = activeStepData;
  const isTemplateCustom = inProgressOrder && !inProgressOrder.template_id;

  function handlePreviousClick() {
    const { previousStep } = activeStepData;
    if (activeStep === Steps.STEP_1) {
      dispatch(orderAction.activateMultilingual(false));
      history.goBack();
    }

    if (activeStep === Steps.STEP_3) {
      if (isTemplateCustom) {
        setActiveStep(Steps.STEP_2);
      } else {
        setActiveStep(Steps.STEP_1);
      }
    } else {
      if (previousStep) {
        setActiveStep(previousStep);
      }
    }
  }

  function handleNextClick() {
    const { nextStep } = activeStepData;
    if (isStepValid) {
      if (activeStep === Steps.STEP_5) {
        // validation step : save language into inProgressOrder and go back
        dispatch(orderAction.saveMultilingual(sortLanguages));
        history.goBack();
      }

      if (activeStep === Steps.STEP_1) {
        // custom template : fill translation text and choose voice gender
        if (isTemplateCustom) {
          setActiveStep(Steps.STEP_2);
        } else {
          setActiveStep(Steps.STEP_3);
        }
      } else {
        if (nextStep) {
          setActiveStep(nextStep);
        }
      }
    }
  }

  return (
    <div id="mutiligual-selection-view" className={classes.container}>
      <TopBanner
        title={t(`${getTopBannerContentDependingOnStep(activeStep).title}`)}
        content={t(`${getTopBannerContentDependingOnStep(activeStep).content}`)}
      />

      <div id="content" className={classes.content}>
        <Content
          setIsStepValid={setIsStepValid}
          languages={languages}
          setLanguages={setLanguages}
          sortLanguages={sortLanguages}
          setSortLanguages={setSortLanguages}
        />
      </div>

      <BottomBanner
        progress={<Progress percentage={activeStep / activeStepData.nbStep} />}
        Previous={(props: unknown) => (
          <Button
            onClick={handlePreviousClick}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: unknown) => (
          <Button
            type="submit"
            disabled={!isStepValid}
            onClick={handleNextClick}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.next')}
          </Button>
        )}
      />
    </div>
  );
}

import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { selectCategories } from '../../../template.selector';
import { selectInfos } from '../../../../info/info.selector';

import { Steps } from '../../../config/selection.config';

import { Categorie } from '../../../../../@types/state/template';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    minHeight: theme.spacing(8),
    marginBottom: theme.spacing(3),
  },
}));

export interface IProps {
  setActiveStep: Dispatch<SetStateAction<number>>;
  setSelectedCategorieId: Dispatch<SetStateAction<string>>;
}

export default function Categories(props: IProps) {
  const { setActiveStep, setSelectedCategorieId } = props;
  const categories = useSelector(selectCategories);
  const infos = useSelector(selectInfos);
  const { access } = infos || {};
  const classes = useStyles();
  const { t } = useTranslation();

  const canCreateCustomMessage =
    access?.all_granted ||
    access?.with_custom_template ||
    access?.disable_template_restriction;

  function handleTypeSelection(event: React.MouseEvent<HTMLButtonElement>) {
    const selectedCategorie: Categorie = JSON.parse(event.currentTarget.value);
    setSelectedCategorieId(selectedCategorie.id.toString());
    setActiveStep(Steps.STEP_2);
  }

  function handleCustomTemplateSelection(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    setActiveStep(Steps.STEP_3);
  }

  return (
    <div id="templates-categories" className={classes.container}>
      {categories &&
        categories.map((categorie: Categorie) => {
          return (
            <Button
              key={categorie.id}
              className={classes.button}
              variant="contained"
              color="secondary"
              disableElevation
              size="large"
              onClick={handleTypeSelection}
              value={JSON.stringify(categorie)}
            >
              {categorie.name}
            </Button>
          );
        })}

      {canCreateCustomMessage && (
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          disableElevation
          size="large"
          value=""
          onClick={handleCustomTemplateSelection}
        >
          {t('message.text.CUSTOM_MESSAGE')}
        </Button>
      )}
    </div>
  );
}

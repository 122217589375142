import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { SnackbarActionTypes } from './types';
import { SnackbarAction } from './snackbar.action';

export const initialState = {
  snackbarQueue: [],
};

const snackbar: Reducer<State['snackbar'], SnackbarAction> = (
  state: State['snackbar'] = initialState,
  action: SnackbarAction
) => {
  switch (action.type) {
    case SnackbarActionTypes.ENQUEUE_SNACKBAR: {
      return {
        ...state,
        snackbarQueue: [
          ...state.snackbarQueue,
          {
            ...action.snackbarQueue,
          },
        ],
      };
    }
    case SnackbarActionTypes.CLOSE_SNACKBAR: {
      return {
        ...state,
        snackbarQueue: state.snackbarQueue.map((message: any) =>
          action.dismissAll || message.key === action.key
            ? { ...message, dismissed: true }
            : { ...message }
        ),
      };
    }
    case SnackbarActionTypes.REMOVE_SNACKBAR: {
      return {
        ...state,
        snackbarQueue: state.snackbarQueue.filter(
          (message: any) => message.key !== action.key
        ),
      };
    }

    default: {
      return state;
    }
  }
};

export default snackbar;

import axios from 'axios';
import injectToken from '../interceptor/injectToken.interceptor';
import injectRole from '../interceptor/injectRole.interceptor';

// import { authorized, unauthorized } from '../interceptor/checkAuth';
import {
  checkResponse,
  checkResponseNotLogged,
} from '../interceptor/auth.interceptor';

const API_URL = process.env.REACT_APP_API_SERVER;
const REACT_APP_API_UPLOAD = process.env.REACT_APP_API_UPLOAD;
const API_MUSIC_URL = process.env.REACT_APP_API_MUSIC;

export function getInstance() {
  const instance = axios.create({
    baseURL: API_URL,
  });
  instance.interceptors.response.use(
    checkResponseNotLogged.success,
    checkResponseNotLogged.faillure
  );

  return instance;
}

export function getAuthenticatedInstance() {
  const instance = axios.create({
    baseURL: API_URL,
  });

  instance.interceptors.response.use(
    checkResponse.success,
    checkResponse.faillure
  );
  instance.interceptors.request.use(injectToken);
  instance.interceptors.request.use(injectRole);

  return instance;
}

export function getMusicInstance() {
  return axios.create({
    baseURL: API_MUSIC_URL,
  });
}

export function getAuthenticatedUploadInstance() {
  const instance = axios.create({
    baseURL: REACT_APP_API_UPLOAD,
    headers: { 'Content-type': 'multipart/form-data' },
  });

  instance.interceptors.request.use(injectToken);

  return instance;
}

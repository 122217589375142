import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { VoiceActionTypes } from './types';
import { VoiceAction } from './voice.action';

export const initialState = {
  pending: false,
  data: {
    voices: null,
  },
  initFetch: true,
  errors: undefined,
};

const voice: Reducer<State['voice'], VoiceAction> = (
  state: State['voice'] = initialState,
  action: VoiceAction
) => {
  switch (action.type) {
    case VoiceActionTypes.GET_VOICES_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case VoiceActionTypes.GET_VOICES_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case VoiceActionTypes.GET_VOICES_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default voice;

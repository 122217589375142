import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import appConfig from '../../../../../application/app.config';
import { selectUpdatedTemplateForDisplay } from '../../../../template/template.selector';

import { inProgressMultilingual } from '../../../../../@types/order';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
  },
  fieldInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  customText: {
    padding: theme.spacing(2),
    '& hr': {
      margin: theme.spacing(2, 0),
    },
  },
  item: {
    padding: theme.spacing(2),
  },
}));

interface IProps {
  setIsStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  languages: inProgressMultilingual;
  setLanguages: React.Dispatch<React.SetStateAction<inProgressMultilingual>>;
}

export default function Text(props: IProps) {
  const { setIsStepValid, languages, setLanguages } = props;
  const languagesKeys = Object.keys(languages);
  const classes = useStyles();
  const { t } = useTranslation();
  const updatedTemplateForDisplay = useSelector(
    selectUpdatedTemplateForDisplay
  );

  // valid step when each selected language has one content
  React.useEffect(() => {
    for (const [, value] of Object.entries(languages)) {
      if (!value.content) {
        setIsStepValid(false);
      } else {
        setIsStepValid(true);
      }
    }
  }, [languages, setIsStepValid]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const text = event.target.value;
    if (text.length <= appConfig.templateMaxLength) {
      setLanguages({
        ...languages,
        [name]: { ...languages[name], content: text, template_id: null },
      });
    }
  }

  return (
    <div id="text-selection" className={classes.container}>
      {updatedTemplateForDisplay && (
        <div id="custom-text" className={classes.customText}>
          <Typography variant="h6" color="textPrimary" paragraph>
            {t(`multilingual.customText`)}
          </Typography>
          <Typography>{updatedTemplateForDisplay}</Typography>
          <Divider />
          <Typography variant="h6" color="textPrimary">
            {t(`multilingual.customTextTranslate`)}
          </Typography>
        </div>
      )}

      {languagesKeys.map((selectedLanguage: string) => {
        const { content } = languages[selectedLanguage];

        // if (selectedLanguage === appConfig.defaultlanguage) return null;

        return (
          <React.Fragment key={selectedLanguage}>
            {selectedLanguage !== appConfig.defaultlanguage && (
              <div className={classes.item}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  {t(`multilingual.${selectedLanguage}`)}
                </Typography>
                <TextField
                  id="content"
                  multiline
                  fullWidth
                  rows="3"
                  name={selectedLanguage}
                  variant="outlined"
                  margin="normal"
                  type="text"
                  value={content}
                  placeholder={'Rédigez votre annonce'}
                  onChange={handleChange}
                  color="secondary"
                />
                <div id="field-info" className={classes.fieldInfo}>
                  <Typography color="textSecondary">
                    {t('message.text.create.maxChar')}
                  </Typography>
                  <Typography color="textSecondary">{`${
                    content ? content.length : 0
                  }/${appConfig.templateMaxLength}`}</Typography>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

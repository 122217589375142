import { Action } from 'redux';
import { PlayerActionTypes } from './types';

export type PlayerAction = Play | Pause | Stop | TimeUpdate;

export interface Play extends Action<PlayerActionTypes.PLAY> {
  id: string;
  path: string;
}

export function play(id: string, path: string): Play {
  return { type: PlayerActionTypes.PLAY, id, path };
}

export interface Pause extends Action<PlayerActionTypes.PAUSE> {
  id: string;
  path: string;
}

export function pause(id: string, path: string): Pause {
  return { type: PlayerActionTypes.PAUSE, id, path };
}

export interface Stop extends Action<PlayerActionTypes.STOP> {}

export function stop(): Stop {
  return { type: PlayerActionTypes.STOP };
}

export interface TimeUpdate extends Action<PlayerActionTypes.TIME_UPDATE> {
  time: number;
}

export function timeUpdate(time: number): TimeUpdate {
  return { type: PlayerActionTypes.TIME_UPDATE, time: time };
}

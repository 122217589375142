import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { TemplateActionTypes } from './types';
import { TemplateAction } from './template.action';

export const initialState = {
  pending: false,
  data: {
    templates: null,
    categories: null,
  },
  initFetch: true,
  errors: undefined,
};

const template: Reducer<State['template'], TemplateAction> = (
  state: State['template'] = initialState,
  action: TemplateAction
) => {
  switch (action.type) {
    case TemplateActionTypes.GET_TEMPLATES_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case TemplateActionTypes.GET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: { ...state.data, templates: action.templates },
        initFetch: false,
      };
    }
    case TemplateActionTypes.GET_TEMPLATES_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case TemplateActionTypes.GET_CATEGORIES_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case TemplateActionTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: { ...state.data, categories: action.categories },
        initFetch: false,
      };
    }
    case TemplateActionTypes.GET_CATEGORIES_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }

    default: {
      return state;
    }
  }
};

export default template;

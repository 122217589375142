import { History } from 'history';

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { State } from '../@types/state';
import auth from '../modules/auth/auth.reducer';
import info from '../modules/info/info.reducer';
import message from '../modules/message/message.reducer';
import news from '../modules/news/news.reducer';
import notification from '../modules/notification/notification.reducer';
import recorder from '../modules/recorder/recorder.reducer';
import template from '../modules/template/template.reducer';
import voice from '../modules/voice/voice.reducer';
import sound from '../modules/sound/sound.reducer';
import planning from '../modules/scheduler/planning.reducer';
import order from '../modules/order/order.reducer';
import player from '../modules/player/player.reducer';
import line from '../modules/line/line.reducer';
import snackbar from '../modules/snackbar/snackbar.reducer';

const createRootReducer = (history: History) =>
  combineReducers<State>({
    auth,
    info,
    message,
    news,
    notification,
    recorder,
    template,
    voice,
    sound,
    planning,
    order,
    player,
    line,
    snackbar,
    router: connectRouter(history),
  });

export default createRootReducer;

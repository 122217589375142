import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as OrderAction from '../../../../order/order.action';

enum soundActions {
  ATS_SOUND = 'ATS_SOUND',
  NO_SOUND = 'NO_SOUND',
  UPLOAD_SOUND = 'UPLOAD_SOUND',
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    minHeight: theme.spacing(8),
    marginBottom: theme.spacing(3),
  },
}));

export interface IProps {
  activeStepId: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export default function SoundTypes(props: IProps) {
  const { activeStepId, setActiveStep } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  function handleSelectMusic() {
    setActiveStep(activeStepId + 1);
  }

  function handleDefaultClick() {
    dispatch(OrderAction.setNoSound());
    history.goBack();
  }

  return (
    <div id="sound-types" className={classes.container}>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        disableElevation
        size="large"
        onClick={handleSelectMusic}
        value={soundActions.ATS_SOUND}
      >
        {t(`message.sound.${soundActions.ATS_SOUND}`)}
      </Button>

      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        disableElevation
        size="large"
        onClick={handleDefaultClick}
        value={soundActions.NO_SOUND}
      >
        {t(`message.sound.${soundActions.NO_SOUND}`)}
      </Button>

      {/* <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        disableElevation
        size="large"
        value={soundActions.UPLOAD_SOUND}
        disabled
      >
        {t(`message.sound.${soundActions.UPLOAD_SOUND}`)}
      </Button> */}
    </div>
  );
}

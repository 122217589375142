import { store } from '../../../Store';
import { push } from 'connected-react-router';
import { Endpoint } from '../../../router/routes.config';
import axios from 'axios';

import { getNewToken } from '../helpers/various.helpers';

export const checkResponse = {
  success(response: any) {
    return response;
  },
  faillure(error: any) {
    const originalRequest = error.config;

    if (error.response.status === 409) {
      return new Promise((resolve, reject) => {
        console.error(
          '[💡] thinks about 409 error code on order create (identical title)'
        );
        reject(error);
      });
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return getNewToken().then((newToken) => {
        if (newToken) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
        }

        // retry network querie after renew token
        return new Promise((resolve, reject) => {
          axios
            .request(originalRequest)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              store.dispatch(push(Endpoint.LOGIN));
              reject(error);
            });
        });
      });
    }

    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  },
};

export const checkResponseNotLogged = {
  success(response: any) {
    return response;
  },
  faillure(error: any) {
    if (error.response.status === 401) {
      store.dispatch(push(Endpoint.LOGIN));
      localStorage.removeItem('auth');
      localStorage.removeItem('infos');
    }
  },
};

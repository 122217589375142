import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Order } from '../../../@types/state/order';
import { Message } from '../../../@types/state/message';
import { config } from '../config/state.config';
import { MessageEvent } from '../../message/config/message.config';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '67vw',
    minHeight: 270,
    flexShrink: 0,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    boxSizing: 'border-box',
  },
  content: {
    display: 'flex',
    minHeight: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    '& h6': {
      marginBottom: theme.spacing(2),
      lineHeight: '80%',
    },
    '& p': {
      flexGrow: 1,
    },
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
    '& strong': {
      '&:after': {
        color: theme.palette.text.primary,
        content: `attr(data-content)`,
      },
    },
  },
  icon: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  action: {
    alignSelf: 'flex-end',
  },
}));

interface IProps {
  classe?: string;
  order?: Order;
  message?: Message;
}

export default function StateCard(props: IProps) {
  const { classe, order, message } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  let cardConfig = null;

  if (order && order.state) {
    cardConfig = config.states.order[order.state];
  }

  if (message && message.state) {
    if (
      (message.is_temporary || !message.with_code) &&
      message.state === MessageEvent.ERROR_CODE
    ) {
      cardConfig = {
        ...config.states.message[message.state],
        title: t('event.message.waitForValidation.title'),
      };
    } else {
      cardConfig = config.states.message[message.state];
    }
  }

  if (!cardConfig) return null;

  let { Icon, title, description, Action } = cardConfig;

  // translation of order title before html render
  if (order && order.title) {
    order.title = order.title.replace(/["']/gi, '&#39;');
    description = t(`${description}`, { name: order.title });
  }

  // translation of message title before html render
  if (message && message.title) {
    message.title = message.title.replace(/["']/gi, '&#39;');
    description = t(`${description}`, { name: message.title });
  }

  // dangerouslySetInnerHTML is secured by css pseudo element and data-content attribute
  return (
    <>
      {title && (
        <Card
          id="message-state-item"
          className={
            classe ? clsx(classe, classes.container) : classes.container
          }
        >
          <CardContent classes={{ root: classes.content }}>
            {Icon && <Icon className={classes.icon} fontSize="large" />}
            {title && (
              <Typography variant="h6" color="textSecondary">
                {t(`${title}`)}
              </Typography>
            )}
            {order && description && (
              <Typography
                paragraph
                dangerouslySetInnerHTML={{
                  __html: `${description}`,
                }}
              ></Typography>
            )}

            {message && description && (
              <Typography
                paragraph
                dangerouslySetInnerHTML={{
                  __html: `${description}`,
                }}
              ></Typography>
            )}

            <div className={classes.actionGroup}>
              {order && Action && (
                <Action
                  className={classes.action}
                  history={history}
                  order={order}
                />
              )}

              {/* Is temporary message */}
              {message?.is_temporary &&
              ![
                MessageEvent.SCHEDULED,
                MessageEvent.INSTALLING,
                MessageEvent.INSTALLED,
              ].includes(message.state) ? (
                <Typography variant="caption">
                  {t('message.temporaryMessage')}
                </Typography>
              ) : (
                <div></div>
              )}

              {message && Action && (
                <Action
                  className={classes.action}
                  history={history}
                  message={message}
                />
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
}

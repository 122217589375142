import React from 'react';
import { Step } from '@material-ui/core';
import Language from '../components/selection/content/language.component';
import Text from '../components/selection/content/text.component';
import Voice from '../components/selection/content/voice.component';
import Sort from '../components/selection/content/sort.component';
import Validation from '../components/selection/content/validation.component';
import FlagFr from '../../../application/images/fr.png';
import FlagEn from '../../../application/images/en.png';
import FlagEs from '../../../application/images/es.png';
import FlagIt from '../../../application/images/it.png';
import FlagDe from '../../../application/images/de.png';

export enum Languages {
  FR = 'FRF',
  EN = 'ANG',
  ES = 'ESP',
  IT = 'ITA',
  DE = 'ALL',
}

export enum Steps {
  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
  STEP_5 = 5,
}

export interface Step {
  Content: React.ElementType;
  nbStep: number;
  nextStep: null | number;
  previousStep: null | number;
}

export interface Config {
  steps: { [index: number]: Step };
  languages: {
    [index: string]: {
      label: string;
      flag: string;
    };
  };
}

const config: Config = {
  steps: {
    [Steps.STEP_1]: {
      Content: (props: any) => <Language {...props}></Language>,
      nbStep: 5,
      nextStep: null,
      previousStep: null,
    },
    [Steps.STEP_2]: {
      Content: (props: any) => <Text {...props}></Text>,
      nbStep: 5,
      nextStep: 3,
      previousStep: Steps.STEP_1,
    },
    [Steps.STEP_3]: {
      Content: (props: any) => <Voice {...props}></Voice>,
      nbStep: 5,
      nextStep: Steps.STEP_4,
      previousStep: null,
    },
    [Steps.STEP_4]: {
      Content: (props: any) => <Sort {...props}></Sort>,
      nbStep: 5,
      nextStep: Steps.STEP_5,
      previousStep: Steps.STEP_3,
    },
    [Steps.STEP_5]: {
      Content: (props: any) => <Validation {...props}></Validation>,
      nbStep: 5,
      nextStep: null,
      previousStep: Steps.STEP_4,
    },
  },
  languages: {
    [Languages.FR]: {
      label: `multilingual.${Languages.FR}`,
      flag: FlagFr,
    },
    [Languages.EN]: {
      label: `multilingual.${Languages.EN}`,
      flag: FlagEn,
    },
    [Languages.ES]: {
      label: `multilingual.${Languages.ES}`,
      flag: FlagEs,
    },
    [Languages.IT]: {
      label: `multilingual.${Languages.IT}`,
      flag: FlagIt,
    },
    [Languages.DE]: {
      label: `multilingual.${Languages.DE}`,
      flag: FlagDe,
    },
  },
};

export default config;

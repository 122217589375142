import { selectToken } from '../../../modules/auth/auth.selector';
import { store } from '../../../Store';

export default function injectToken(config: any) {
  const updatedConfig = config;
  const state = store.getState();
  const token = selectToken(state);

  if (token) {
    updatedConfig.headers.Authorization = `Bearer ${token}`;
  }

  return updatedConfig;
}

const config = {
  waitForTime: 2000,
  startingTime: 3000,
  templateMaxLength: 350,
  maxSnackbarMessage: 3,
  search: {
    debounceTime: 250,
    sound: {
      offset: 0,
      limit: 10,
    },
  },
  defaultlanguage: 'FRF',
  languages: ['FRF', 'ALL', 'ANG', 'ESP', 'ITA'],
  maxLanguage: 3,
  featureFlip: {
    installIos: true,
    fabButton: false,
    legal: true,
  },
  sound: {
    categories: [
      { label: 'Commerce', value: 'commerce' },
      { label: 'Libre de droits', value: 'libre de droits' },
      { label: 'VOIP', value: 'VOIP' },
    ],
    types: [
      { label: 'Pop', value: 'pop' },
      { label: 'Rock', value: 'rock' },
      { label: 'Dance', value: 'dance' },
      { label: 'Electronique', value: 'electronique' },
      { label: 'Jazz', value: 'jazz' },
      { label: 'Variété', value: 'Variété' },
      { label: 'Soul', value: 'soul' },
      { label: 'Classique', value: 'Classique' },
      { label: 'Originale', value: 'originale' },
      { label: 'Alternative', value: 'alternative' },
      { label: 'Monde', value: 'monde' },
    ],
  },
};

export default config;

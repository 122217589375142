import { createMuiTheme } from '@material-ui/core';

export const defaultTheme = {
  shape: {
    borderRadius: 5,
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      light: '#000000',
      main: '#231f20',
      dark: '#4b4647',
      contrastText: '#fff',
    },
    secondary: {
      light: '#c7c000',
      main: '#FFF200',
      dark: '#ffff57',
      contrastText: '#231f20',
    },
    text: {
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: '#000000',
      disabled: 'rgba(0, 0, 0, 0.38)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
      action: {
      disabled: 'rgba(128, 142, 149, 0.38)',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },

};
const createTheme = (theme: any = defaultTheme) => createMuiTheme(theme);

export default createTheme;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TopBanner from '../../modules/banner/components/topBanner.component';
import BottomBanner from '../../modules/banner/components/bottomBanner.component';
import {
  getContentDependingOnStep,
  getToBannerContentDependingOnStep,
} from '../../modules/voice/helpers/selection.helpers';
import config, { Steps } from '../../modules/voice/config/selection.config';
import Progress from '../../modules/utils/components/progress.component';
import { ValidUpdateVoice } from '../../@types/order';
import * as orderAction from '../../modules/order/order.action';
import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import * as PlayerAction from '../../modules/player/player.action';
import { PlayerStateEnum } from '../../modules/player/config/player.config';
import { selectPlayerState } from '../../modules/player/player.selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
      height: `calc(var(--vh, 1vh) * 100)`,
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      padding: `${115 + theme.spacing(2)}px ${theme.spacing(1)}px ${
        59 + theme.spacing(5)
      }px ${theme.spacing(1)}px`,
    },
    next: {
      color: theme.palette.common.white,
    },
    previous: {
      color: theme.palette.common.white,
    },
  })
);

export default function VoiceSelection() {
  const [activeStep, setActiveStep] = useState(Steps.STEP_1);
  const [selectedVoiceId, setSelectedVoiceId] = useState<string>('');
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const playerState = useSelector(selectPlayerState);

  function handlePreviousClick() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(PlayerAction.stop());
    }

    if (activeStep === Steps.STEP_2) {
      setActiveStep(Steps.STEP_1);
      setSelectedVoiceId('');
    } else {
      history.goBack();
    }
  }

  async function handleNextClick() {
    const voice: ValidUpdateVoice = {
      voice_id: parseInt(selectedVoiceId),
      custom_voice_url: null,
    };
    await dispatch(orderAction.saveVoice(voice));
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(PlayerAction.stop());
    }
    history.goBack();
  }

  return (
    <div id="selection-view" className={classes.container}>
      <TopBanner
        title={routeMetaData && t(`${routeMetaData.name}`)}
        content={t(`${getToBannerContentDependingOnStep(activeStep)}`)}
      />

      <div id="content" className={classes.content}>
        {activeStep &&
          getContentDependingOnStep(
            activeStep,
            setActiveStep,
            selectedVoiceId,
            setSelectedVoiceId
          )}
      </div>

      <BottomBanner
        progress={<Progress percentage={activeStep / config.nbStep} />}
        Previous={(props: any) => (
          <Button
            onClick={handlePreviousClick}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: any) => (
          <Button
            type="submit"
            disabled={selectedVoiceId === '' ? true : false}
            onClick={handleNextClick}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.next')}
          </Button>
        )}
      />
    </div>
  );
}

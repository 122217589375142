import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { NewsActionTypes } from './types';
import { NewsAction } from './news.action';

export const initialState = {
  pending: false,
  data: {
    news: [],
  },
  initFetch: true,
  errors: undefined,
};

const news: Reducer<State['news'], NewsAction> = (
  state: State['news'] = initialState,
  action: NewsAction
) => {
  switch (action.type) {
    case NewsActionTypes.GET_NEWS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case NewsActionTypes.GET_NEWS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case NewsActionTypes.GET_NEWS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default news;

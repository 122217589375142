import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// MUI
import Radio from '@material-ui/core/Radio';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// Store
import { selectTemplatesDependingOnTemplateCategorie } from '../../../template.selector';
import { selectInfos } from '../../../../info/info.selector';

// Components
import TemplateCard from '../../card.component';

// Others
import { Steps } from '../../../config/selection.config';

import { Template } from '../../../../../@types/state/template';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  help: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}));

export interface IProps {
  selectedCategorieId: string;
  selectedTemplateId: string;
  setSelectedTemplateId: React.Dispatch<React.SetStateAction<string>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  setIsStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TemplatesList(props: IProps) {
  const {
    selectedCategorieId,
    selectedTemplateId,
    setSelectedTemplateId,
    setActiveStep,
    setContent,
    setIsStepValid,
  } = props;
  const [templates, setTemplates] = React.useState<Template[] | null>(null);
  const getTemplatesDependingOnTemplateCategorie = useSelector(
    selectTemplatesDependingOnTemplateCategorie
  );
  const infos = useSelector(selectInfos);
  const classes = useStyles();
  const { t } = useTranslation();

  /**
   * Valid step
   */
  React.useEffect(() => {
    if (selectedTemplateId.length > 0) {
      setIsStepValid(true);
    } else {
      setIsStepValid(false);
    }
  }, [selectedTemplateId.length, setIsStepValid]);

  /**
   * Get templates of the selected categorie
   */
  React.useEffect(() => {
    if (selectedCategorieId) {
      const templatesDependingOnCategorie =
        getTemplatesDependingOnTemplateCategorie(parseInt(selectedCategorieId));
      if (templatesDependingOnCategorie) {
        setTemplates(templatesDependingOnCategorie);
      }
    }
  }, [
    getTemplatesDependingOnTemplateCategorie,
    selectedCategorieId,
    selectedTemplateId,
    setSelectedTemplateId,
  ]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const textId = event.currentTarget.value.toString();
    setSelectedTemplateId(textId);
    setContent('');
  }

  function handleUpdateTemplate(event: React.MouseEvent<HTMLButtonElement>) {
    const textId = event.currentTarget.value.toString();
    setSelectedTemplateId(textId);
    setActiveStep(Steps.STEP_4);
  }

  return (
    <div id="texts-list" className={classes.list}>
      {templates &&
        templates.map((template: Template) => {
          return (
            <div key={template.id} className={classes.item}>
              <TemplateCard
                updatedTemplate={template as Template}
                isCustomTemplate={false}
              >
                <>
                  {infos && infos.access && infos.access.all_granted && (
                    <Button onClick={handleUpdateTemplate} value={template.id}>
                      {t('action.edit')}
                    </Button>
                  )}
                </>
              </TemplateCard>

              <Radio
                checked={selectedTemplateId === template.id.toString()}
                onChange={handleChange}
                value={template.id}
                name="template-selection"
              />
            </div>
          );
        })}

      {/* <div className={classes.help}>
        <Help />
      </div> */}
    </div>
  );
}

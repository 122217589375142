import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import { MapMessages, Message } from '../../@types/state/message';
import { ValidInstallation } from '../../@types/message';
import * as messageServiceApi from '../../services/api/message.service';
import { MessageActionTypes } from './types';
import { waitFor } from '../../services/api/helpers/various.helpers';
import appConfig from '../../application/app.config';
import * as orderActions from '../order/order.action';

export type MessageAction =
  | GetMessagesPending
  | GetMessagesSuccess
  | GetMessagesError
  | SetCurrentMessagePending
  | SetCurrentMessageSuccess
  | SetCurrentMessageError
  | InstallationMessagePending
  | InstallationMessageSuccess
  | InstallationMessageError
  | RefuseMessagePending
  | RefuseMessageSuccess
  | RefuseMessageError;

export interface GetMessagesPending
  extends Action<MessageActionTypes.GET_MESSAGES_PENDING> {
  pending: boolean;
}

export interface GetMessagesSuccess
  extends Action<MessageActionTypes.GET_MESSAGES_SUCCESS> {
  data: State['message']['data'];
}

export interface GetMessagesError
  extends Action<MessageActionTypes.GET_MESSAGES_ERROR> {
  message: any;
}

const getMessagesPending = (pending: boolean): GetMessagesPending => {
  return { type: MessageActionTypes.GET_MESSAGES_PENDING, pending };
};

const getMessagesSuccess = (
  data: State['message']['data']
): GetMessagesSuccess => {
  return { type: MessageActionTypes.GET_MESSAGES_SUCCESS, data };
};

const getMessagesError = (message: string): GetMessagesError => {
  return { type: MessageActionTypes.GET_MESSAGES_ERROR, message };
};

export const getMessages = (): ThunkAction<
  Promise<void>,
  State,
  {},
  MessageAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, MessageAction>
  ): Promise<void> => {
    dispatch(getMessagesPending(true));
    try {
      const response = await messageServiceApi.getMessages();

      if (response.status === 200) {
        const { data } = response;
        const normalizedData = data.reduce(
          (accumulator: MapMessages, currentValue: Message) => {
            accumulator[currentValue['id']] = currentValue;

            return accumulator;
          },
          {}
        );
        dispatch(getMessagesSuccess({ messages: normalizedData }));
        // TODO: dispatch getCurrentMessage for menu component
      }
    } catch (error) {
      dispatch(getMessagesError((error as Error).message));
    }
  };
};

export interface RefuseMessagePending
  extends Action<MessageActionTypes.REFUSE_MESSAGE_PENDING> {
  pending: boolean;
}

export interface RefuseMessageError
  extends Action<MessageActionTypes.REFUSE_MESSAGE_ERROR> {
  message: string;
}

export interface RefuseMessageSuccess
  extends Action<MessageActionTypes.REFUSE_MESSAGE_SUCCESS> {
  messageId: number;
}

const refuseMessagePending = (pending: boolean): RefuseMessagePending => {
  return { type: MessageActionTypes.REFUSE_MESSAGE_PENDING, pending };
};

const refuseMessageError = (message: string): RefuseMessageError => {
  return { type: MessageActionTypes.REFUSE_MESSAGE_ERROR, message };
};

const refuseMessageSuccess = (messageId: number): RefuseMessageSuccess => {
  return {
    type: MessageActionTypes.REFUSE_MESSAGE_SUCCESS,
    messageId,
  };
};

export const refuseMessage = (
  messageId: Message['id'],
  reasonId: number | null,
  callback: (ok: boolean) => void
): ThunkAction<Promise<void>, State, {}, MessageAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, MessageAction>
  ): Promise<void> => {
    dispatch(refuseMessagePending(true));

    try {
      const response = await messageServiceApi.refuseMessage(
        messageId,
        reasonId
      );
      dispatch(refuseMessagePending(false));
      if (response.status === 200) {
        callback(true);
        dispatch(refuseMessageSuccess(messageId));
        return;
      }
      callback(false);
    } catch (error) {
      dispatch(refuseMessageError((error as Error).message));
      callback(false);
    }
  };
};

/* ----------------------------- INSTALL MESSAGE ---------------------------- */

export interface InstallationMessagePending
  extends Action<MessageActionTypes.INSTALLATION_MESSAGE_PENDING> {
  pending: boolean;
}

export interface InstallationMessageSuccess
  extends Action<MessageActionTypes.INSTALLATION_MESSAGE_SUCCESS> {
  messageId: number;
}

export interface InstallationMessageError
  extends Action<MessageActionTypes.INSTALLATION_MESSAGE_ERROR> {
  message: any;
}

const installationMessagePending = (
  pending: boolean
): InstallationMessagePending => {
  return { type: MessageActionTypes.INSTALLATION_MESSAGE_PENDING, pending };
};

const installationMessageSuccess = (
  messageId: number
): InstallationMessageSuccess => {
  return {
    type: MessageActionTypes.INSTALLATION_MESSAGE_SUCCESS,
    messageId,
  };
};

const installationMessageError = (
  message: string
): InstallationMessageError => {
  return { type: MessageActionTypes.INSTALLATION_MESSAGE_ERROR, message };
};

export const installationMessage = (
  installation: ValidInstallation
): ThunkAction<Promise<void>, State, {}, MessageAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, MessageAction>
  ): Promise<void> => {
    dispatch(installationMessagePending(true));
    try {
      const response = await messageServiceApi.installationMessage(
        installation
      );

      await waitFor(appConfig.waitForTime);

      if (response.status === 200) {
        // TODO: replace with mqtt event
        await waitFor(appConfig.waitForTime);
        await dispatch(orderActions.getOrders());
        await dispatch(getMessages());
        dispatch(installationMessageSuccess(installation.message_id));
      }
    } catch (error) {
      dispatch(installationMessageError((error as Error).message));
    }
  };
};

export interface SetCurrentMessagePending
  extends Action<MessageActionTypes.SET_CURRENT_MESSAGE_PENDING> {
  pending: boolean;
}

export interface SetCurrentMessageSuccess
  extends Action<MessageActionTypes.SET_CURRENT_MESSAGE_SUCCESS> {
  data: Message;
}

export interface SetCurrentMessageError
  extends Action<MessageActionTypes.SET_CURRENT_MESSAGE_ERROR> {
  message: any;
}

const setCurrentMessagePending = (
  pending: boolean
): SetCurrentMessagePending => {
  return { type: MessageActionTypes.SET_CURRENT_MESSAGE_PENDING, pending };
};

const setCurrentMessageSuccess = (data: Message): SetCurrentMessageSuccess => {
  return { type: MessageActionTypes.SET_CURRENT_MESSAGE_SUCCESS, data };
};

const setCurrentMessageError = (message: string): SetCurrentMessageError => {
  return { type: MessageActionTypes.SET_CURRENT_MESSAGE_ERROR, message };
};

export const SetCurrentMessage = (
  message: Message
): ThunkAction<Promise<void>, State, {}, MessageAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, MessageAction>
  ): Promise<void> => {
    dispatch(setCurrentMessagePending(true));
    try {
      const response = await messageServiceApi.setCurrentMessage(message);

      if (response.status === 200) {
        dispatch(setCurrentMessageSuccess(message));
      }
    } catch (error) {
      dispatch(setCurrentMessageError((error as Error).message));
    }
  };
};

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  help: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
}));

interface IProps {
  anchorEl: any;
  onClose: () => void;
}

export default function OperatorPopover(props: IProps) {
  const { anchorEl, onClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div id="popover-content" className={classes.container}>
        <Typography variant="h6" className={classes.title}>
          {t('message.installation.messagingCode.popover.title')}
        </Typography>

        <Typography>Orange : 888</Typography>
        <Typography>SFR : 123</Typography>
        <Typography>Bouygues : 660</Typography>
        <Typography>Free : 666</Typography>

        <Typography variant="h6" className={classes.help}>
          {t('message.installation.messagingCode.popover.help')}
        </Typography>
        <Typography variant="h6">
          {t('message.installation.messagingCode.popover.helpContent')}
        </Typography>
      </div>
    </Popover>
  );
}

export function isRunningStandalone() {
  return (
    // @ts-ignore
    navigator.standalone ||
    window.matchMedia('(display-mode: standalone)').matches
  );
}

export function isIos() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /iphone|ipad|ipod/.test(userAgent);
}

export function hasServiceWorkerRunning() {
  return !!navigator.serviceWorker.controller;
}

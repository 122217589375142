import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { MessageActionTypes } from './types';
import { MessageAction } from './message.action';

export const initialState = {
  pending: false,
  data: {
    messages: null,
  },
  initFetch: true,
  errors: undefined,
};

const message: Reducer<State['message'], MessageAction> = (
  state: State['message'] = initialState,
  action: MessageAction
) => {
  switch (action.type) {
    case MessageActionTypes.GET_MESSAGES_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case MessageActionTypes.GET_MESSAGES_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case MessageActionTypes.GET_MESSAGES_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case MessageActionTypes.INSTALLATION_MESSAGE_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case MessageActionTypes.INSTALLATION_MESSAGE_SUCCESS: {
      return {
        ...state,
        pending: false,
      };
    }
    case MessageActionTypes.INSTALLATION_MESSAGE_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case MessageActionTypes.REFUSE_MESSAGE_PENDING: {
      return {
        ...state,
        pending: action.pending,
      };
    }
    case MessageActionTypes.REFUSE_MESSAGE_SUCCESS: {
      return {
        ...state,
        pending: false,
      };
    }
    case MessageActionTypes.REFUSE_MESSAGE_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default message;

export enum TemplateActionTypes {
  GET_TEMPLATES_PENDING = '@@template/GET_TEMPLATES_PENDING',
  GET_TEMPLATES_SUCCESS = '@@template/GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_ERROR = '@@template/GET_TEMPLATES_ERROR',
  GET_CATEGORIES_PENDING = '@@template/GET_CATEGORIES_PENDING',
  GET_CATEGORIES_SUCCESS = '@@template/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR = '@@template/GET_CATEGORIES_ERROR',
}

export enum DynamicVariableType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  TEL = 'tel',
}

export interface DynamicVariable {
  key: string;
  label: string;
  type: DynamicVariableType;
  visible: boolean;
  value: string;
}

export interface DynamicVariableProps {
  fieldLabel: string;
  fieldKey: string;
  value: string;
  onChangeValue: (key: string, newValue: string) => void;
  onError: (field: string, error: boolean) => void;
}

import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { PlayerActionTypes } from './types';
import { PlayerAction } from './player.action';
import { PlayerStateEnum } from './config/player.config';

export const initialState = {
  id: null,
  state: PlayerStateEnum.STOPPED,
  path: null,
  time: null,
};

const player: Reducer<State['player'], PlayerAction> = (
  state: State['player'] = initialState,
  action: PlayerAction
) => {
  switch (action.type) {
    case PlayerActionTypes.PLAY: {
      return {
        ...state,
        state: PlayerStateEnum.PLAYING,
        id: action.id,
        path: action.path,
      };
    }

    case PlayerActionTypes.PAUSE: {
      return {
        ...state,
        state: PlayerStateEnum.PAUSE,
        id: action.id,
        path: action.path,
      };
    }

    case PlayerActionTypes.STOP: {
      return {
        ...state,
        state: PlayerStateEnum.STOPPED,
        path: null,
        time: null,
      };
    }

    case PlayerActionTypes.TIME_UPDATE: {
      return {
        ...state,
        time: action.time,
      };
    }

    default: {
      return state;
    }
  }
};

export default player;

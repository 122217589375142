import { Steps } from '../config/selection.config';

interface TopBanner {
  title: string;
  content: string;
}

export function getTopBannerContentDependingOnStep(
  activeStepId: number
): TopBanner {
  let topBanner: TopBanner = {
    title: '',
    content: '',
  };

  switch (activeStepId) {
    case Steps.STEP_1: {
      topBanner.title = 'multilingual.step.language.topBannerTitle';
      topBanner.content = 'multilingual.step.language.topBannerContent';
      break;
    }
    case Steps.STEP_2: {
      topBanner.title = 'multilingual.step.text.topBannerTitle';
      topBanner.content = 'multilingual.step.text.topBannerContent';
      break;
    }
    case Steps.STEP_3: {
      topBanner.title = 'multilingual.step.voice.topBannerTitle';
      topBanner.content = 'multilingual.step.voice.topBannerContent';
      break;
    }
    case Steps.STEP_4: {
      topBanner.title = 'multilingual.step.order.topBannerTitle';
      topBanner.content = 'multilingual.step.order.topBannerContent';
      break;
    }
    case Steps.STEP_5: {
      topBanner.title = 'multilingual.step.validation.topBannerTitle';
      topBanner.content = 'multilingual.step.validation.topBannerContent';
      break;
    }
  }

  return topBanner;
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

// MUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// Store
import * as orderAction from '../../modules/order/order.action';
import { getToBannerContentDependingOnStep } from '../../modules/template/helpers/selection.helpers';
import { selectTemplateById } from '../../modules/template/template.selector';
import { selectInfos } from '../../modules/info/info.selector';
import { selectInprogressOrder } from 'modules/order/order.selector';

// Components
import TopBanner from '../../modules/banner/components/topBanner.component';
import BottomBanner from '../../modules/banner/components/bottomBanner.component';
import Progress from '../../modules/utils/components/progress.component';
import DialogDynamicVariables from 'modules/template/components/dialogDynamicVariables.component';

// Others
import config, { Steps } from '../../modules/template/config/selection.config';
import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import {
  updateTemplateWithInfos,
  extractVariablesFromTemplate,
} from '../../modules/template/helpers/textFormat.helpers';

import { ValidUpdateTemplate } from '../../@types/order';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: `calc(var(--vh, 1vh) * 100)`,
    },
    content: {
      display: 'flex',
      flex: 1,
      padding: `${115 + theme.spacing(2)}px ${theme.spacing(1)}px ${
        59 + theme.spacing(5)
      }px ${theme.spacing(1)}px`,
    },
    next: {
      color: theme.palette.common.white,
    },
    previous: {
      color: theme.palette.common.white,
    },
  })
);

export default function TextSelection() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // Store
  const getTemplateById = useSelector(selectTemplateById);
  const infos = useSelector(selectInfos);
  const inprogressOrder = useSelector(selectInprogressOrder);

  // States
  const [activeStep, setActiveStep] = useState(Steps.STEP_1);
  const [selectedCategorieId, setSelectedCategorieId] = useState<string>('');
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [dynamicVariableValues, setDynamicVariableValues] = React.useState<
    Record<string, string>
  >({});
  const [openDialogVariables, setOpenDialogVariables] =
    React.useState<boolean>(false);

  const [isStepValid, setIsStepValid] = React.useState(false);

  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const activeStepData = config.steps[activeStep];

  const selectedTemplate = getTemplateById(selectedTemplateId);

  const dynamicVariables = extractVariablesFromTemplate(
    selectedTemplate?.original_content || ''
  );
  const dynamicVariableKeys = dynamicVariables.map((variable) => variable.key);
  const hasEachDynamicVariableValues = dynamicVariableKeys.every((key) =>
    Object.keys(dynamicVariableValues).find((valueKey) => valueKey === key)
  );

  // Sync dynamic variable values with local state
  React.useEffect(() => {
    if (inprogressOrder?.dynamic_variables) {
      setDynamicVariableValues(inprogressOrder.dynamic_variables);
    }
  }, [inprogressOrder]);

  function handlePreviousClick() {
    const { previousStep } = activeStepData;
    if (previousStep) {
      setActiveStep(previousStep);
    } else {
      history.goBack();
    }
  }

  async function handleNextClick() {
    // Case: user select a template
    if (activeStep === Steps.STEP_2) {
      if (selectedTemplateId) {
        if (selectedTemplate && infos) {
          if (!hasEachDynamicVariableValues) {
            setOpenDialogVariables(true);
            return;
          }

          await dispatch(
            orderAction.saveTemplateDynamicVariables({
              dynamic_variables:
                dynamicVariables.length === 0 ? {} : dynamicVariableValues,
            })
          );
          const payload: ValidUpdateTemplate = {
            template_id: parseInt(selectedTemplateId),
            content: updateTemplateWithInfos(selectedTemplate, infos),
            original_content: updateTemplateWithInfos(selectedTemplate, infos),
          };
          await dispatch(orderAction.saveTemplate(payload));
          dispatch(orderAction.saveMultilingual([]));
          dispatch(orderAction.activateMultilingual(false));
          return history.goBack();
        }
      }
    }
    // Case : custom text (edit existing template or create a custom text)
    if (activeStep === Steps.STEP_3 || activeStep === Steps.STEP_4) {
      const payload: ValidUpdateTemplate = {
        template_id: null,
        content: content,
      };
      await dispatch(
        orderAction.saveTemplateDynamicVariables({
          dynamic_variables: dynamicVariableValues,
        })
      );
      await dispatch(orderAction.saveTemplate(payload));
      dispatch(orderAction.saveMultilingual([]));
      dispatch(orderAction.activateMultilingual(false));

      return history.goBack();
    }
  }

  const { Content } = activeStepData;

  return (
    <div id="selection-view" className={classes.container}>
      {openDialogVariables && (
        <DialogDynamicVariables
          open={openDialogVariables}
          onClose={() => setOpenDialogVariables(false)}
          selectedTemplateId={String(selectedTemplate?.id)}
          onSetVariable={setDynamicVariableValues}
        />
      )}

      <TopBanner
        title={routeMetaData && t(`${routeMetaData.name}`)}
        content={t(`${getToBannerContentDependingOnStep(activeStep)}`)}
      />

      <div id="content" className={classes.content}>
        <Content
          setActiveStep={setActiveStep}
          activeStepId={activeStep}
          selectedCategorieId={selectedCategorieId}
          setSelectedCategorieId={setSelectedCategorieId}
          selectedTemplateId={selectedTemplateId}
          setSelectedTemplateId={setSelectedTemplateId}
          content={content}
          setContent={setContent}
          dynamicVariableValues={dynamicVariableValues}
          setIsStepValid={setIsStepValid}
        />
      </div>

      <BottomBanner
        progress={<Progress percentage={activeStep / activeStepData.nbStep} />}
        Previous={(props: any) => (
          <Button
            onClick={handlePreviousClick}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: any) => (
          <Button
            type="submit"
            disabled={!isStepValid}
            onClick={handleNextClick}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.next')}
          </Button>
        )}
      />
    </div>
  );
}

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import * as planningServiceApi from '../../services/api/planning.service';
import { PlanningActionTypes } from './types';
import { MapPlannings, Planning } from '../../@types/state/planning';

export type PlanningAction =
  | GetPlanningPending
  | GetPlanningSuccess
  | GetPlanningError;

export interface GetPlanningPending
  extends Action<PlanningActionTypes.GET_PLANNINGS_PENDING> {
  pending: boolean;
}

export interface GetPlanningSuccess
  extends Action<PlanningActionTypes.GET_PLANNINGS_SUCCESS> {
  data: State['planning']['data'];
}

export interface GetPlanningError
  extends Action<PlanningActionTypes.GET_PLANNINGS_ERROR> {
  message: any;
}

const getPlanningsPending = (pending: boolean): GetPlanningPending => {
  return { type: PlanningActionTypes.GET_PLANNINGS_PENDING, pending };
};

const getPlanningSuccess = (
  data: State['planning']['data']
): GetPlanningSuccess => {
  return { type: PlanningActionTypes.GET_PLANNINGS_SUCCESS, data };
};

const getPlanningsError = (message: string): GetPlanningError => {
  return { type: PlanningActionTypes.GET_PLANNINGS_ERROR, message };
};

export const getPlannings = (): ThunkAction<
  Promise<void>,
  State,
  {},
  PlanningAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, PlanningAction>
  ): Promise<void> => {
    dispatch(getPlanningsPending(true));
    try {
      const response = await planningServiceApi.getPlannings();

      const { data } = response;

      const dataNormalized = data.reduce(
        (accumulator: MapPlannings, currentValue: Planning) => {
          accumulator[currentValue['id']] = currentValue;

          return accumulator;
        },
        {}
      );

      if (response.status === 200) {
        if (response) {
          dispatch(
            getPlanningSuccess({
              plannings: dataNormalized,
            })
          );
        }
      }
    } catch (error) {
      dispatch(getPlanningsError(error.message));
    }
  };
};

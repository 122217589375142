import React from 'react';
import i18n from '../../../application/i18n';
import MicNoneRoundedIcon from '@material-ui/icons/MicNoneRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Button from '@material-ui/core/Button';
import { Endpoint } from '../../../router/routes.config';
import { OrderEvent } from '../../order/config/order.config';
import { MessageEvent } from '../../message/config/message.config';

export interface StateCard {
  Icon: React.ElementType;
  title: string;
  description: string;
  Action: React.ElementType | null;
  active: boolean;
}

export interface Config {
  states: {
    order: {
      [index: string]: StateCard;
    };
    message: {
      [index: string]: StateCard;
    };
  };
}

export const config: Config = {
  states: {
    order: {
      [OrderEvent.CREATED]: {
        Icon: (props: any) => <MicNoneRoundedIcon {...props} />,
        title: 'event.order.inProduction.title',
        description: 'event.order.inProduction.description',
        Action: null,
        active: false,
      },
      [OrderEvent.IN_PRODUCTION]: {
        Icon: (props: any) => <MicNoneRoundedIcon {...props} />,
        title: 'event.order.inProduction.title',
        description: 'event.order.inProduction.description',
        Action: null,
        active: false,
      },
      [OrderEvent.CANCELED]: {
        Icon: (props: any) => <WarningRoundedIcon {...props} />,
        title: 'event.order.inProduction.title',
        description: 'event.order.inProduction.description',
        Action: (props: any) => (
          <Button disabled {...props}>
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
      [OrderEvent.ERROR]: {
        Icon: (props: any) => <WarningRoundedIcon {...props} />,
        title: 'event.order.error.title',
        description: 'event.order.error.description',
        Action: (props: any) => (
          <Button disabled {...props}>
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
    },
    message: {
      [MessageEvent.TO_INSTALL]: {
        Icon: (props: any) => <HourglassEmptyRoundedIcon {...props} />,
        title: 'event.message.installing.title',
        description: 'event.message.installing.description',
        Action: (props: any) => (
          <Button disabled {...props}>
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
      [MessageEvent.INSTALLING]: {
        Icon: (props: any) => <HourglassEmptyRoundedIcon {...props} />,
        title: 'event.message.installing.title',
        description: 'event.message.installing.description',
        Action: (props: any) => (
          <Button disabled {...props}>
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
      [MessageEvent.INSTALLED]: {
        Icon: (props: any) => <CheckCircleOutlineRoundedIcon {...props} />,
        title: 'event.message.installed.title',
        description: 'event.message.installed.description',
        Action: (props: any) => (
          <Button
            {...props}
            onClick={() => {
              const { message } = props;
              if (message) {
                const path = Endpoint.MESSAGE.replace(/:messageId/, message.id);
                props.history.push(path);
              }
            }}
          >
            {i18n.t('action.display')}
          </Button>
        ),
        active: true,
      },
      [MessageEvent.SCHEDULED]: {
        Icon: (props: any) => <ScheduleRoundedIcon {...props} />,
        title: 'event.message.scheduled.title',
        description: 'event.message.scheduled.description',
        Action: (props: any) => (
          <Button disabled {...props}>
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
      [MessageEvent.ERROR_CODE]: {
        Icon: (props: any) => <ErrorRoundedIcon {...props} />,
        title: 'event.message.messagingCodeMissing.title',
        description: 'event.message.messagingCodeMissing.description',
        Action: (props: any) => (
          <Button
            {...props}
            onClick={() => {
              const { message } = props;
              if (message) {
                const path = Endpoint.MESSAGE_INSTALLATION.replace(
                  /:messageId/,
                  message.id
                );
                props.history.push(path);
              }
            }}
          >
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
      [MessageEvent.ERROR]: {
        Icon: (props: any) => <WarningRoundedIcon {...props} />,
        title: 'event.message.error.title',
        description: 'event.message.error.description',
        Action: (props: any) => (
          <Button disabled {...props}>
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
      [MessageEvent.CREATED]: {
        Icon: (props: any) => <CheckCircleOutlineRoundedIcon {...props} />,
        title: 'event.message.created.title',
        description: 'event.message.created.description',
        Action: (props: any) => (
          <Button disabled {...props}>
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
      [MessageEvent.REFUSED]: {
        Icon: (props: any) => <WarningRoundedIcon {...props} />,
        title: 'event.message.refused.title',
        description: 'event.message.refused.description',
        Action: (props: any) => (
          <Button
            {...props}
            onClick={() => {
              const { message } = props;
              if (message) {
                const path = Endpoint.MESSAGE.replace(/:messageId/, message.id);
                props.history.push(path);
              }
            }}
          >
            {i18n.t('action.display')}
          </Button>
        ),
        active: false,
      },
    },
  },
};

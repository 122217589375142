import { Action } from 'redux';
import { SnackbarMessage } from '../../@types/state/snackbar';
import { SnackbarActionTypes } from './types';

export type SnackbarAction = EnqueueSnackbar | CloseSnackbar | RemoveSnackbar;

export interface EnqueueSnackbar
  extends Action<SnackbarActionTypes.ENQUEUE_SNACKBAR> {
  snackbarQueue: SnackbarMessage;
}

interface Message {
  message: string;
  options: any;
}

export const enqueueSnackbar = (message: Message): EnqueueSnackbar => {
  const key = message.options && message.options.key;

  return {
    type: SnackbarActionTypes.ENQUEUE_SNACKBAR,
    snackbarQueue: {
      ...message,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export interface CloseSnackbar
  extends Action<SnackbarActionTypes.CLOSE_SNACKBAR> {
  dismissAll: boolean;
  key: number;
}

export const closeSnackbar = (key: any): CloseSnackbar => {
  return { type: SnackbarActionTypes.CLOSE_SNACKBAR, dismissAll: !key, key };
};

export interface RemoveSnackbar
  extends Action<SnackbarActionTypes.REMOVE_SNACKBAR> {
  key: number;
}

export const removeSnackbar = (key: number): RemoveSnackbar => {
  return { type: SnackbarActionTypes.REMOVE_SNACKBAR, key };
};

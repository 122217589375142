import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { OrderActionTypes } from './types';
import { OrderAction } from './order.action';
import { SoundSource } from './config/order.config';

const initialState = {
  pending: false,
  data: {
    orders: null,
    inProgressOrder: {
      phone_number: null,
      title: null,
      template_id: null,
      content: '',
      original_content: '',
      sound_id: null,
      voice_id: null,
      custom_voice_url: null,
      sound_source: SoundSource.LIBRARY,
      hasMultilingual: false,
      languages: [],
      install_date: null,
      dynamic_variables: {},
    },
  },
  initFetch: true,
  errors: undefined,
};

const order: Reducer<State['order'], OrderAction> = (
  state: State['order'] = initialState,
  action: OrderAction
) => {
  switch (action.type) {
    case OrderActionTypes.SAVE_MULTILINGUAL: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            languages: action.payload,
          },
        },
      };
    }
    case OrderActionTypes.ACTIVATE_MULTILINGUAL: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            hasMultilingual: action.payload,
          },
        },
      };
    }
    case OrderActionTypes.SAVE_PHONE_NUMBER: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            ...action.payload,
          },
        },
      };
    }
    case OrderActionTypes.SAVE_TITLE: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            ...action.payload,
          },
        },
      };
    }
    case OrderActionTypes.SAVE_TEMPLATE: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            ...action.payload,
          },
        },
      };
    }

    case OrderActionTypes.SAVE_TEMPLATE_DYNAMIC_VARIABLES: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            ...action.payload,
          },
        },
      };
    }
    case OrderActionTypes.SAVE_VOICE: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            ...action.payload,
          },
        },
      };
    }
    case OrderActionTypes.SAVE_SOUND: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            ...action.payload,
          },
        },
      };
    }
    case OrderActionTypes.RESET_IN_PROGRESS_ORDER: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            phone_number: null,
            title: null,
            template_id: null,
            content: '',
            original_content: '',
            sound_id: null,
            voice_id: null,
            custom_voice_url: null,
            sound_source: SoundSource.LIBRARY,
            save: true,
            hasMultilingual: false,
            languages: [],
            install_date: null,
            dynamic_variables: {},
          },
        },
      };
    }
    case OrderActionTypes.CREATE_ORDER_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case OrderActionTypes.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        pending: false,
        initFetch: false,
      };
    }
    case OrderActionTypes.CREATE_ORDER_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.error,
      };
    }
    case OrderActionTypes.GET_ORDERS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case OrderActionTypes.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          orders: action.data,
        },
        initFetch: false,
      };
    }
    case OrderActionTypes.GET_ORDERS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case OrderActionTypes.SET_NO_SOUND: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            sound_id: null,
            sound_source: SoundSource.NO_SOUND,
          },
        },
      };
    }
    case OrderActionTypes.SET_SCHEDULER: {
      return {
        ...state,
        data: {
          ...state.data,
          inProgressOrder: {
            ...state.data.inProgressOrder,
            install_date: action.payload,
          },
        },
      };
    }
    case OrderActionTypes.CREATE_INSTALL_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case OrderActionTypes.CREATE_INSTALL_SUCCESS: {
      return {
        ...state,
        pending: false,
        initFetch: false,
      };
    }
    case OrderActionTypes.CREATE_INSTALL_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.error,
      };
    }
    case OrderActionTypes.CREATE_REMIX_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case OrderActionTypes.CREATE_REMIX_SUCCESS: {
      return {
        ...state,
        pending: false,
        initFetch: false,
      };
    }
    case OrderActionTypes.CREATE_REMIX_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default order;

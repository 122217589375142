import React from 'react';
import { useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
import {
  authRoute,
  AuthRoute,
  getRouteMetaDataFromPathName,
  Endpoint,
} from '../router/routes.config';
import AppBar from '../modules/appBar/components/appBar.component';
import { useLocation } from 'react-router-dom';
import Drawer from '../modules/drawer/components/drawer.component';
import Content from '../modules/drawer/components/content/content.component';
// import Recorder from '../modules/recorder/components/recorder.component';
import InstallationPopup from '../modules/installation/components/android/popup.component';
import InstallationIosPopup from '../modules/installation/components/ios/popup.component';
import Player from '../modules/player/components/player.component';
import { isIos } from '../modules/utils/display';
// import { selectOpen } from '../modules/recorder/recorder.selector';
import { selectOrders } from '../modules/order/order.selector';
import Notifier from '../modules/snackbar/components/notifier';
import config from '../application/app.config';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: theme.palette.background.paper,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function Layout() {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [displayIosInstall, setDisplayIosInstall] = React.useState(true);
  // const isRecorderOpen = useSelector(selectOpen);
  const classes = useStyles();
  const location = useLocation();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const orders = useSelector(selectOrders);

  React.useEffect(() => {
    const savedIosInstall = localStorage.getItem('ios');
    if (savedIosInstall) {
      const iosInstall = JSON.parse(savedIosInstall);
      if (iosInstall.install && displayIosInstall) {
        setDisplayIosInstall(false);
      }
    }
  }, [displayIosInstall, setDisplayIosInstall]);

  return (
    <div id="layout" className={classes.container}>
      <Notifier />
      <InstallationPopup
        visible={
          !!window.deferredPrompt &&
          orders.length > 0 &&
          location.pathname === Endpoint.HOME
        }
      />

      {config.featureFlip.installIos && displayIosInstall && (
        <InstallationIosPopup
          visible={
            isIos() && orders.length > 0 && location.pathname === Endpoint.HOME
          }
        />
      )}

      {/* {isRecorderOpen && <Recorder />} */}
      <CssBaseline />
      <Player />
      {!routeMetaData?.fullscreen && (
        <>
          <AppBar
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <Drawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}>
            <Content />
          </Drawer>
        </>
      )}
      <main id="layout-content" className={classes.content}>
        <Switch>
          {authRoute.map((route: AuthRoute) => (
            <Route key={route.path} path={route.path} exact>
              {route.component}
            </Route>
          ))}
        </Switch>
      </main>
    </div>
  );
}

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// MUI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Store
import { selectTemplateById } from '../../template/template.selector';
import * as orderAction from 'modules/order/order.action';
import { selectInfos } from 'modules/info/info.selector';
import { selectInprogressOrder } from 'modules/order/order.selector';

// Others
import dynamicVariablesConfig from '../config/dynamicVariables.config';
import {
  extractVariablesFromTemplate,
  updateTemplateWithInfos,
} from '../helpers/textFormat.helpers';
import { ValidUpdateTemplate } from '../../../@types/order';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));

interface Props {
  selectedTemplateId: string;
  open: boolean;
  onClose: () => void;
  onSetVariable?: (value: Record<string, string>) => void;
}

export default function DialogDynamicVariables({
  selectedTemplateId,
  open,
  onClose,
  onSetVariable,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Store
  const getTemplateById = useSelector(selectTemplateById);
  const selectedTemplate = getTemplateById(selectedTemplateId);
  const infos = useSelector(selectInfos);
  const inprogressOrder = useSelector(selectInprogressOrder);

  // State
  const [error, setError] = React.useState<Record<string, boolean>>({}); // TODO: create an object for each errors
  const [dynamicVariableValues, setDynamicVariableValues] = React.useState<
    Record<string, string>
  >({});

  // Derived data
  const dynamicVariables = extractVariablesFromTemplate(
    selectedTemplate?.original_content || ''
  );
  const dynamicVariableKeys = dynamicVariables.map((variable) => variable.key);
  const hasEachDynamicVariableValues = dynamicVariableKeys.every((key) =>
    Object.keys(dynamicVariableValues).find((valueKey) => valueKey === key)
  );
  const hasNoError = Object.values(error).every((e) => e === false);
  const disabled = !hasNoError || !hasEachDynamicVariableValues;

  // Sync dynamic variable values with local state
  React.useEffect(() => {
    if (inprogressOrder?.dynamic_variables) {
      setDynamicVariableValues(inprogressOrder.dynamic_variables);
    }
  }, [inprogressOrder]);

  const handleChange = React.useCallback(
    (fieldKey: string, newValue: string) => {
      setDynamicVariableValues({
        ...dynamicVariableValues,
        [fieldKey]: newValue,
      });

      onSetVariable &&
        onSetVariable({
          ...dynamicVariableValues,
          [fieldKey]: newValue,
        });
    },
    [dynamicVariableValues, onSetVariable]
  );

  const handleError = React.useCallback((field: string, error: boolean) => {
    setError((prev) => ({ ...prev, [field]: error }));
  }, []);

  async function handleAccept() {
    if (selectedTemplate && infos) {
      await dispatch(
        orderAction.saveTemplateDynamicVariables({
          dynamic_variables: dynamicVariableValues,
        })
      );

      const payload: ValidUpdateTemplate = {
        template_id: parseInt(selectedTemplateId),
        content: updateTemplateWithInfos(
          selectedTemplate,
          infos,
          dynamicVariableValues
        ),
      };

      await dispatch(orderAction.saveTemplate(payload));
    }

    onClose && onClose();
  }

  function handleCancel() {
    setDynamicVariableValues({});
    onSetVariable && onSetVariable({});
    onClose && onClose();
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{t('dynamicVariables.title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('dynamicVariables.allRequired')}</Typography>
        <div id="template-create" className={classes.container}>
          {dynamicVariables.map((variable, index) => {
            const { component: Component } =
              dynamicVariablesConfig.DYNAMIC_VARIABLE_TYPES[variable.type];

            if (!Component) return null;

            return (
              <Component
                key={index}
                fieldKey={variable.key}
                fieldLabel={variable.label}
                value={dynamicVariableValues[variable.key] || ''}
                onChangeValue={handleChange}
                onError={handleError}
              />
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('dynamicVariables.cancel')}</Button>
        <Button disabled={disabled} onClick={handleAccept}>
          {t('dynamicVariables.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { Dispatch, SetStateAction } from 'react';
import config, { Step } from '../config/selection.config';

export function getContentDependingOnStep(
  activeStepId: number,
  setActiveStep: Dispatch<SetStateAction<number>>,
  selectedSoundId: string,
  setSelectedSoundId: Dispatch<SetStateAction<string>>
): React.ReactNode {
  const { steps } = config;

  function isStep(step: Step) {
    return step.id === activeStepId;
  }

  const step = steps.find(isStep);

  if (!step)
    return (
      <div style={{ color: 'red', textAlign: 'center' }}>
        Aucun contenu pour cette étape
      </div>
    );

  const { Content } = step;

  return (
    <Content
      setActiveStep={setActiveStep}
      activeStepId={activeStepId}
      selectedSoundId={selectedSoundId}
      setSelectedSoundId={setSelectedSoundId}
    />
  );
}

export function getToBannerContentDependingOnStep(
  activeStepId: number
): string {
  let content: string = '';

  switch (activeStepId) {
    case 1: {
      content = 'message.sound.topBanner.content.1';
      break;
    }
    case 2: {
      content = 'message.sound.topBanner.content.2';
      break;
    }
    default: {
      content = 'message.sound.topBanner.content.default';
    }
  }

  return content;
}

import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { RecorderActionTypes } from './types';
import { RecordState } from './config/recorder.config';
import { RecorderAction } from './recorder.action';

export const initialState = {
  recordState: RecordState.NOT_RECORDING,
  data: {
    record: null,
  },
  open: false,
  errors: undefined,
};

const recorder: Reducer<State['recorder'], RecorderAction> = (
  state: State['recorder'] = initialState,
  action: RecorderAction
) => {
  switch (action.type) {
    case RecorderActionTypes.SET_RECORDER_OPEN: {
      return {
        ...state,
        open: action.open,
      };
    }
    case RecorderActionTypes.SET_RECORDING: {
      return {
        ...state,
        recordState: action.recordState,
      };
    }
    case RecorderActionTypes.SET_STOP_RECORDING: {
      return {
        ...state,
        recordState: action.recordState,
      };
    }

    default: {
      return state;
    }
  }
};

export default recorder;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TemplateCard from '../card/template';
import VoiceCard from '../card/voice';
import SoundCard from '../card/sound';
// import SchedulerCard from '../card/scheduler';
import * as lineAction from '../../../line/line.action';
import { selectInfos } from '../../../info/info.selector';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: `calc(var(--vh, 1vh) * 100)`,
  },
  title: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  card: {
    minWidth: '90vw',
  },
  message: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  voice: {},
  cardTitle: {
    color: theme.palette.secondary.main,
  },
  text: {
    color: '#808E95',
    textAlign: 'center',
  },
  button: {
    minHeight: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}));

export default function Step1() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const infos = useSelector(selectInfos);

  function handleNextClick() {
    if (infos) {
      const payload = {
        id: infos.id,
        is_onboarding_done: true,
      };
      dispatch(lineAction.setOnboarding(payload));
    }
  }

  return (
    <div id="demo" className={classes.container}>
      <Typography className={classes.title} variant="h5">
        {t('onboarding.step.2.how')}
      </Typography>

      <div className={classes.message}>
        <TemplateCard />
        <VoiceCard />
        <SoundCard />
        {/* <SchedulerCard /> */}
      </div>

      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleNextClick}
      >
        {t('action.start')}
      </Button>
    </div>
  );
}

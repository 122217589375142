import { State } from '../../@types/state';
import { Role } from '../../application/role.config';
import decodeJwt from '../utils/jwt';

export function selectFetchPending(state: State): boolean {
  return state.auth.pending;
}

export function selectFetchErrors(state: State): string | undefined {
  return state.auth.errors;
}

export function selectToken(state: State) {
  return state.auth.data.token;
}

// TODO: active refreshtoken for connect user
export const selectIsAuth = (state: State): boolean => {
  const token =
    state.auth.data && state.auth.data.token ? state.auth.data.token : null;
  if (!token) return false;
  const payload = decodeJwt(token);
  if (!payload || !payload.exp) return false;
  const { exp } = payload;
  const expireAt = exp * 1000;
  const now = Date.now();
  if (expireAt > now) return true;
  return false;
};

export function selectRole(state: State): Role | null {
  const { role } = state.auth.data;

  if (!role) return null;

  return role;
}

export function selectRefreshtoken(state: State): string | null {
  return state.auth.data.refreshToken;
}

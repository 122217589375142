import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minWidth: '90vw',
    marginBottom: theme.spacing(1),
    '& .MuiCardContent-root': {
      padding: theme.spacing(1, 2),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'center',
  },
  sound: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(10),
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    textAlign: 'left',
    '& p': {
      textAlign: 'left',
    },
  },
}));

export default function Soundcard() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {t('onboarding.step.2.soundChoice')}
        </Typography>
        <div className={classes.sound}>
          <img
            src={`${process.env.PUBLIC_URL}/mock/sound/weekend.jpeg`}
            alt="voice"
            width="65"
          />
          <div id="description" className={classes.description}>
            <Typography color="textSecondary">The Weeknd</Typography>
            <Typography color="textSecondary">Blinding Lights</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as playerAction from '../../player.action';
import { selectPlayerState, selectPlayerId } from '../../player.selector';
import { PlayerStateEnum } from '../../config/player.config';

interface IProps {
  id: string;
  path: string;
}

export default function PlayerButtonOutlined(props: IProps) {
  const { id, path } = props;
  const [
    playerLocalState,
    setPlayerLocalState,
  ] = React.useState<PlayerStateEnum>(PlayerStateEnum.STOPPED);
  const playerGlobalState = useSelector(selectPlayerState);
  const playerGlobalId = useSelector(selectPlayerId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      playerGlobalState === PlayerStateEnum.STOPPED &&
      playerGlobalId === id
    ) {
      setPlayerLocalState(PlayerStateEnum.STOPPED);
    }
  }, [id, playerGlobalId, playerGlobalState]);

  async function handlePlay(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    event.stopPropagation();
    if (playerGlobalState === PlayerStateEnum.PLAYING) {
      await dispatch(playerAction.stop());
    }
    setPlayerLocalState(PlayerStateEnum.PLAYING);
    dispatch(playerAction.play(id, path));
  }
  function handleStop(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    event.stopPropagation();
    setPlayerLocalState(PlayerStateEnum.STOPPED);
    dispatch(playerAction.stop());
  }

  return (
    <>
      {playerLocalState === PlayerStateEnum.PLAYING ? (
        <Button onClick={handleStop}>{'stop'}</Button>
      ) : (
        <Button onClick={handlePlay}>{t('action.listen')}</Button>
      )}
    </>
  );
}

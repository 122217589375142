import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { getContentDependingOnStep } from '../../modules/onboarding/helpers/onboarding.helpers';
import { Steps } from '../../modules/voice/config/selection.config';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../modules/utils/components/loader.component';
import { selectFetchPending } from '../../modules/line/line.selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(var(--vh, 1vh) * 100)`,
      background: theme.palette.background.paper,
    },
    content: {
      padding: theme.spacing(0, 1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
  })
);

export default function Onboarding() {
  const [activeStep, setActiveStep] = useState(Steps.STEP_1);
  const classes = useStyles();
  const fetchPending = useSelector(selectFetchPending);

  return (
    <div id="onboarding-view" className={classes.container}>
      {fetchPending && (
        <Backdrop className={classes.backdrop} open>
          <Loader />
        </Backdrop>
      )}
      <div id="content" className={classes.content}>
        {activeStep && getContentDependingOnStep(activeStep, setActiveStep)}
      </div>
    </div>
  );
}

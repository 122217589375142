import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { refuseMessage } from '../message.action';
import { getRefusedReasons } from 'services/api/message.service';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  onConfirm?: () => void;
}

interface Params {
  messageId: string;
}

interface RefuseReason {
  id: number;
  ats_id: string;
  reason: string;
  order: number;
}

export function RefuseMessageDialog(props: Props) {
  const { open, setOpen } = props;
  const { onConfirm } = props;

  const params = useParams<Params>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [reasons, setReasons] = React.useState<RefuseReason[]>([]);
  const [selectedReason, setSelectedReason] = React.useState('');

  // Populate reasons
  React.useEffect(() => {
    async function getReasons() {
      try {
        const { data, error } = await getRefusedReasons();
        if (data) setReasons(data);
        if (error) console.log(error);
      } catch (error) {
        console.log(error);
      }
    }
    getReasons();
  }, []);

  function handleCallback(ok: boolean) {
    if (ok && onConfirm) onConfirm();
    setLoading(false);
  }

  async function handleConfirm() {
    setLoading(true);
    if (params.messageId) {
      const reason = reasons.find((r) => r.reason === selectedReason);
      const reasonId = reason?.id || null;
      const messageId = Number(params.messageId);

      setTimeout(async () => {
        await dispatch(refuseMessage(messageId, reasonId, handleCallback));
      }, 1000);
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('message.installation.dialogTitleRefuseMessage')}
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            value={selectedReason}
            onChange={(event) => setSelectedReason(event.target.value)}
          >
            {reasons
              .sort((a, b) => a.order - b.order)
              .map((reason) => (
                <FormControlLabel
                  key={reason.id}
                  value={reason.reason}
                  label={reason.reason}
                  control={<Radio />}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setOpen(false)}>
          {t('action.cancel')}
        </Button>
        <Button color="primary" onClick={handleConfirm} disabled={loading}>
          {loading && <CircularProgress size={20} />}
          {!loading && t('action.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: `calc(var(--vh, 1vh) * 100)`,
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: theme.palette.text.secondary,
    },
  })
);
export default function Error() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>Page non trouvée</Typography>
    </div>
  );
}

import { getAuthenticatedInstance } from './helpers/axios.helpers';
// import { Notification } from '../../@types/state/notification';

const authenticatedInstance = getAuthenticatedInstance();

export async function subscribe(subscription: object): Promise<any> {
  return authenticatedInstance.post('/subscription', subscription);
}

export async function getSubcription(): Promise<any> {
  return authenticatedInstance.get('/subscription');
}

// export async function getNotifications(): Promise<any> {
//   return instance.post('/notifications');
// }

export async function getNotifications(): Promise<any> {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve({
        data: [
          {
            id: 1,
            title: 'Nouvelle annonce disponible !',
            description: 'Votre annonce est maintenant en service',
            type: 'message',
            object_id: 34,
          },
          {
            id: 42,
            title: 'Nouvelle annonce disponible !',
            description: 'Votre annonce est maintenant en service',
            type: 'message',
            object_id: 34,
          },
          {
            id: 34,
            title: 'Nouvelle annonce disponible !',
            description: 'Votre annonce est maintenant en service',
            type: 'message',
            object_id: 34,
          },
        ],
        status: 200,
      });
    }, 2000);
  });
}

import { State } from '../../@types/state';
import { MapMessages, Message } from '../../@types/state/message';

export const selectInitFetch = (state: State): boolean => {
  return state.message.initFetch;
};

export const selectFetchPending = (state: State): boolean => {
  return state.message.pending;
};

export function selectFetchErrors(state: State): string | undefined {
  return state.message.errors;
}

export const selectMessagesMap = (state: State): MapMessages | null => {
  return state.message.data.messages;
};

export const selectMessages = (state: State): Message[] => {
  const messagesMap = selectMessagesMap(state);

  if (!messagesMap) return [];

  return Object.values(messagesMap);
};

export const selectCurrentMessage = (state: State): Message | null => {
  // TODO: remplace with the real current message
  const messages = selectMessages(state);

  if (!messages) return null;

  return messages[0];
};

export function selectMessageById(state: State) {
  const messagesMap = selectMessagesMap(state);

  return function (messageId: number | string): Message | null {
    if (!messagesMap) return null;

    return messagesMap[messageId.toString()];
  };
}

export function selectMessageByOrderId(state: State) {
  const messages = selectMessages(state);

  return function (orderId: number | null): Message | undefined {
    if (!orderId) return undefined;

    return messages.find((message: Message) => message.order_id === orderId);
  };
}

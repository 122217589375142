import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import * as playerAction from '../../player.action';
import { selectPlayerState } from '../../player.selector';
import { PlayerStateEnum } from '../../config/player.config';

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  icon: {
    width: 150,
    height: 150,
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    background: (disabled) =>
      disabled ? 'grey' : theme.palette.secondary.main,
    borderRadius: '100%',
  },
}));

interface IProps {
  id: string;
  path: string;
  classe?: string;
  isDisabled?: boolean;
}

export default function PlayerButtonRounded(props: IProps) {
  const { id, path, classe } = props;
  const [disabled] = React.useState(
    props.isDisabled ? props.isDisabled : false
  );
  const playerState = useSelector(selectPlayerState);
  const dispatch = useDispatch();
  const classes = useStyles(disabled);

  function handleClick() {
    if (disabled) return;

    if (
      playerState === PlayerStateEnum.STOPPED ||
      playerState === PlayerStateEnum.PAUSE
    ) {
      dispatch(playerAction.play(id, path));
    } else if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.pause(id, path));
    }
  }
  return (
    <div
      id="player-rounded"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      {playerState === PlayerStateEnum.PLAYING ? (
        <PauseRoundedIcon
          classes={{
            root: classes.icon,
          }}
          onClick={handleClick}
        />
      ) : (
        <PlayArrowRoundedIcon
          classes={{
            root: classes.icon,
          }}
          onClick={handleClick}
        />
      )}
    </div>
  );
}

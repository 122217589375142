import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import MicRoundedIcon from '@material-ui/icons/MicRounded';
import TopBanner from '../../modules/banner/components/topBanner.component';
import BottomBanner from '../../modules/banner/components/bottomBanner.component';
// import Help from '../../modules/help/components/help.component';
import { Gender } from '../../modules/info/config/info.config';
import Loader from '../../modules/utils/components/loader.component';
import Recorder from '../../modules/recorder/components/recorder.component';
import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import * as lineAction from '../../modules/line/line.action';
import * as orderAction from '../../modules/order/order.action';
import * as recorderAction from '../../modules/recorder/recorder.action';
import { RecordType } from '../../modules/recorder/config/recorder.config';
import { selectInfos } from '../../modules/info/info.selector';
import { selectOpen } from '../../modules/recorder/recorder.selector';
import { selectFetchPending } from '../../modules/line/line.selector';
import { selectInprogressOrder } from '../../modules/order/order.selector';

import { ValidUpdateUserInfo } from '../../@types/line';
import { UserUpdateFormData } from '../../@types/form/user/update';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
      paddingTop: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      padding: `${115 + theme.spacing(1)}px ${theme.spacing(1)}px ${
        59 + theme.spacing(5)
      }px ${theme.spacing(1)}px`,
    },
    label: {
      paddingLeft: theme.spacing(0.5),
      marginTop: theme.spacing(2),
    },
    errors: {
      color: theme.palette.error.main,
    },
    translation: {
      margin: theme.spacing(3),
    },
    help: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    genderController: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(6),
    },
    exemple: {
      margin: theme.spacing(2, 1),
    },
    next: {
      color: theme.palette.common.white,
    },
    previous: {
      color: theme.palette.common.white,
    },
    record: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      '& #button-content': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  })
);

export default function InfoUpdate() {
  const [gender, setGender] = React.useState<Gender>(Gender.MALE);
  const [recordType, setRecordType] = React.useState<RecordType>(
    RecordType.FIRST_NAME
  );
  const [lastNameRecordUrl, setLastNameRecordUrl] = React.useState('');
  const [firstNameRecordUrl, setFirstNameRecordUrl] = React.useState('');
  const [companyNameRecordUrl, setCompanyNameRecordUrl] = React.useState('');
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState, control } =
    useForm<UserUpdateFormData>({
      mode: 'onChange',
    });
  const infos = useSelector(selectInfos);
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const fetchPending = useSelector(selectFetchPending);
  const isRecorderOpen = useSelector(selectOpen);
  const inprogressOrder = useSelector(selectInprogressOrder);

  async function onSubmit(data: UserUpdateFormData) {
    if (infos) {
      const userInfo: ValidUpdateUserInfo = {
        id: infos.line_detail_id,
        first_name: data.firstName,
        first_name_pronunciation: data.firstNamePhonetic,
        last_name: data.lastName,
        last_name_pronunciation: data.lastNamePhonetic,
        company_name: data.company,
        company_pronunciation: data.companyPhonetic,
        gender: data.gender,
      };

      // add records
      if (lastNameRecordUrl.length > 0) {
        userInfo.last_name_pronunciation_url = lastNameRecordUrl;
      }
      if (firstNameRecordUrl.length > 0) {
        userInfo.first_name_pronunciation_url = firstNameRecordUrl;
      }
      if (companyNameRecordUrl.length > 0) {
        userInfo.company_pronunciation_url = companyNameRecordUrl;
      }

      await dispatch(lineAction.setLineUserInfo(userInfo));

      // update inprogressOrder when accedd not all granted and no custom voice
      const { access } = infos;
      if (
        !access.all_granted &&
        inprogressOrder &&
        !inprogressOrder.custom_voice_url
      ) {
        const accessVoiceId = access?.[`voice_ids_${data.gender}`]?.[0];
        if (accessVoiceId) {
          await dispatch(
            orderAction.saveVoice({
              voice_id: accessVoiceId,
              custom_voice_url: null,
            })
          );
        }
      }

      history.goBack();
    }
  }

  function handleRecord(recordType: RecordType) {
    setRecordType(recordType);
    dispatch(recorderAction.setRecorderOpen(true));
  }

  function handleGenderChange(event: React.ChangeEvent<HTMLInputElement>) {
    setGender(event.target.value as Gender);
  }

  return (
    <form
      id="deployment-create-view"
      className={classes.container}
      onSubmit={handleSubmit(onSubmit)}
    >
      {isRecorderOpen && recordType === RecordType.LAST_NAME && (
        <Recorder
          topBannerTitle={t('recorder.lastName.topBannerTitle')}
          topBannerContent={t('recorder.lastName.topBannerContent')}
          setRecordUrl={setLastNameRecordUrl}
        />
      )}
      {isRecorderOpen && recordType === RecordType.FIRST_NAME && (
        <Recorder
          topBannerTitle={t('recorder.firstName.topBannerTitle')}
          topBannerContent={t('recorder.firstName.topBannerContent')}
          setRecordUrl={setFirstNameRecordUrl}
        />
      )}
      {isRecorderOpen && recordType === RecordType.COMPANY_NAME && (
        <Recorder
          topBannerTitle={t('recorder.companyName.topBannerTitle')}
          topBannerContent={t('recorder.companyName.topBannerContent')}
          setRecordUrl={setCompanyNameRecordUrl}
        />
      )}
      <TopBanner
        title={routeMetaData && t(`${routeMetaData.name}`)}
        content={t('message.user.userBannerContent')}
      />

      <div id="content" className={classes.content}>
        {fetchPending && (
          <Backdrop className={classes.backdrop} open>
            <Loader />
          </Backdrop>
        )}

        <InputLabel htmlFor="lastName" className={classes.label}>
          {t('message.user.lastNameLabel')}
        </InputLabel>
        <TextField
          id="lastName"
          name="lastName"
          inputRef={register({ required: true })}
          defaultValue={infos && infos.last_name ? infos.last_name : ''}
          autoComplete="family-name"
          variant="outlined"
          margin="normal"
          type="text"
          fullWidth
          placeholder={t('message.user.lastNamePlaceorder')}
          color="secondary"
        />

        <InputLabel htmlFor="lastNamePhonetic" className={classes.label}>
          {t('message.user.lastNamePhoneticLabel')}
        </InputLabel>
        <TextField
          id="lastNamePhonetic"
          name="lastNamePhonetic"
          inputRef={register()}
          defaultValue={
            infos && infos.last_name_pronunciation
              ? infos.last_name_pronunciation
              : ''
          }
          autoComplete="off"
          variant="outlined"
          margin="normal"
          type="text"
          fullWidth
          placeholder={t('message.user.lastNamePhoneticPlaceorder')}
          color="secondary"
        />

        <div className={classes.record}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleRecord(RecordType.LAST_NAME)}
          >
            <div id="button-content">
              <MicRoundedIcon />
              {t('profile.recorderLastName')}
            </div>
          </Button>
        </div>

        <InputLabel htmlFor="firstName" className={classes.label}>
          {t('message.user.firstNameLabel')}
        </InputLabel>
        <TextField
          id="firstName"
          name="firstName"
          inputRef={register({ required: true })}
          defaultValue={infos && infos.first_name ? infos.first_name : ''}
          autoComplete="given-name"
          variant="outlined"
          margin="normal"
          type="text"
          fullWidth
          placeholder={t('message.user.firstNamePlaceorder')}
          color="secondary"
        />

        <InputLabel htmlFor="firstNamePhonetic" className={classes.label}>
          {t('message.user.firstNamePhoneticLabel')}
        </InputLabel>
        <TextField
          id="firstNamePhonetic"
          name="firstNamePhonetic"
          inputRef={register()}
          defaultValue={
            infos && infos.first_name_pronunciation
              ? infos.first_name_pronunciation
              : ''
          }
          autoComplete="off"
          variant="outlined"
          margin="normal"
          type="text"
          fullWidth
          placeholder={t('message.user.firstNamePhoneticPlaceorder')}
          color="secondary"
        />

        <div className={classes.record}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleRecord(RecordType.FIRST_NAME)}
          >
            <div id="button-content">
              <MicRoundedIcon />
              {t('profile.recorderFirstName')}
            </div>
          </Button>
        </div>

        <InputLabel htmlFor="organizationName" className={classes.label}>
          {t('message.user.companyLabel')}
        </InputLabel>
        <TextField
          id="organizationName"
          name="company"
          inputRef={register({
            required: true,
          })}
          defaultValue={
            infos && infos.company_name
              ? infos.company_name
              : infos && infos.lm_company_name
              ? infos && infos.lm_company_name
              : ''
          }
          autoComplete="organization"
          variant="outlined"
          margin="normal"
          type="text"
          fullWidth
          placeholder={t('message.user.companyPlaceorder')}
          color="secondary"
        />

        <InputLabel htmlFor="companyPhonetic" className={classes.label}>
          {t('message.user.companyPhoneticLabel')}
        </InputLabel>
        <TextField
          id="companyPhonetic"
          name="companyPhonetic"
          inputRef={register()}
          defaultValue={
            infos && infos.company_pronunciation
              ? infos.company_pronunciation
              : infos && infos.lm_company_pronunciation
              ? infos.lm_company_pronunciation
              : ''
          }
          autoComplete="off"
          variant="outlined"
          margin="normal"
          type="text"
          fullWidth
          placeholder={t('message.user.companyPhoneticPlaceorder')}
          color="secondary"
        />

        <div className={classes.record}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleRecord(RecordType.COMPANY_NAME)}
          >
            <div id="button-content">
              <MicRoundedIcon />
              {t('profile.recorderCompany')}
            </div>
          </Button>
        </div>

        <InputLabel className={classes.label}>
          {t('message.user.gender')}
        </InputLabel>
        <Typography classes={{ root: classes.exemple }}>
          {t('message.user.genderExemple')}
        </Typography>
        <Controller
          id="gender"
          name="gender"
          defaultValue={infos && infos.gender ? infos.gender : Gender.MALE}
          control={control}
          rules={{ required: true }}
          as={
            <RadioGroup
              classes={{ root: classes.genderController }}
              value={gender}
              onChange={handleGenderChange}
            >
              <FormControlLabel
                value={Gender.MALE}
                control={<Radio name="gender" color="secondary" />}
                label={t('user.maleHe')}
                labelPlacement="bottom"
              />
              <FormControlLabel
                value={Gender.FEMALE}
                control={<Radio name="gender" color="secondary" />}
                label={t('user.femaleShe')}
                labelPlacement="bottom"
              />
            </RadioGroup>
          }
        />

        {/* <div className={classes.help}>
          <Help />
        </div> */}
      </div>

      <BottomBanner
        Previous={(props: any) => (
          <Button
            onClick={() => history.goBack()}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: any) => (
          <Button
            type="submit"
            disabled={!formState.isValid}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.next')}
          </Button>
        )}
      />
    </form>
  );
}

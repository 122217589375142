import React from 'react';
import { useTranslation } from 'react-i18next';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import logo from '../../../../application/images/logo-installation.svg';
import addToHomescreen from '../../../../application/images/AddToHomescreen.svg';
import action from '../../../../application/images/action.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: theme.zIndex.drawer + 2,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    descriptionContainer: {
      display: 'flex',

      alignItems: 'center',
      height: '30%',
      backgroundColor: '#FAFAFB',
    },
    logo: {
      marginLeft: theme.spacing(2),
    },
    description: {
      fontWeight: 'bold',
      color: '#585C5D',
      '&::after': {
        content: '"by ATS Studio"',
        display: 'block',
        fontSize: theme.typography.caption.fontSize,
        color: '#808E95DE',
      },
    },
    installContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      backgroundColor: '#1EA0FB',
    },
    text: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1),
    },
    helpInstall: {
      textAlign: 'left',
      marginRight: theme.spacing(1),
      color: theme.palette.common.white,
    },
    arrowWrapper: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#1EA0FB',
      '& svg': {
        color: theme.palette.common.white,
      },
    },
    img: {
      padding: theme.spacing(0, 1),
      verticalAlign: 'text-bottom',
    },
  })
);

interface IProps {
  visible: boolean;
}

export default function InstallationPopupIos(props: IProps) {
  const [clicked, setClicked] = React.useState(false);
  let { visible } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  function handleOnClick() {
    localStorage.setItem('ios', JSON.stringify({ install: true }));
  }

  function handleClickBackdrop() {
    setClicked(true);
    localStorage.setItem('ios', JSON.stringify({ install: true }));
  }

  return (
    <Backdrop
      open={visible && !clicked}
      className={classes.backdrop}
      onClick={handleClickBackdrop}
    >
      <Slide direction="up" timeout={500} in={visible && !clicked}>
        <div id="installation-button" className={classes.container}>
          <div
            id="app-description-container"
            className={classes.descriptionContainer}
          >
            <img className={classes.logo} src={logo} alt="app-logo" />
            <Typography className={classes.description}>
              {t('installPopupIos.title')}
            </Typography>
          </div>

          <div
            id="installation-help-ios"
            className={classes.installContainer}
            onClick={handleOnClick}
          >
            <div className={classes.text}>
              <Typography className={classes.helpInstall}>
                {t('installPopupIos.text1')}
                <img src={action} alt="install-ios" className={classes.img} />
                {t('installPopupIos.text2')}
                <img
                  src={addToHomescreen}
                  alt="action"
                  className={classes.img}
                />
              </Typography>
            </div>
            <div id="arrow-wrapper" className={classes.arrowWrapper}>
              <ArrowDownwardRoundedIcon />
            </div>
          </div>
        </div>
      </Slide>
    </Backdrop>
  );
}

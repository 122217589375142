import { Gender } from '../modules/info/config/info.config';

export default {
  user: {
    company: 'Société 42',
    firstName: 'Thomas',
    lastName: 'Nova',
    gender: Gender.MALE,
  },
};

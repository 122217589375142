import { getAuthenticatedInstance } from './helpers/axios.helpers';
import { CreateOrderPayload } from '../../@types/api/order';
import { SoundSource } from 'modules/order/config/order.config';

const authenticatedInstance = getAuthenticatedInstance();

export async function getOrders(): Promise<any> {
  return authenticatedInstance.get('/orders');
}

export async function createOrder(payload: CreateOrderPayload): Promise<any> {
  return authenticatedInstance.post('/rpc/create_order', payload);
}

export interface ICreateRemixApi {
  order_id: number;
  sound_id: string | null;
  sound_source: SoundSource;
  install_date: string;
}

export async function createRemix(payload: ICreateRemixApi): Promise<any> {
  return authenticatedInstance.post('/rpc/create_remix', payload);
}

export interface ICreateInstallApi {
  message_id: number;
  install_date: string;
}

export async function createInstall(payload: ICreateInstallApi): Promise<any> {
  return authenticatedInstance.post('/rpc/create_install', payload);
}

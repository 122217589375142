import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import AuthRoute from './modules/auth/components/route/authRoute.component';
import Login from './views/auth/login.view';
import Starting from './views/starting/starting.view';
import Layout from './views/layout.view';
import ScrollToTop from './modules/utils/components/scrollToTop.component';
import ServiceWorkerWrapper from './modules/serviceWorker/components/wrapper.component';
import config from './application/app.config';
import { isIos } from './modules/utils/display';
import injectManifest from './application/manifest/injectManifest';
import { useInitDataLoad } from './data/initLoad.hook';
import { selectThemeFromInfos } from './modules/info/info.selector';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';

import createTheme from './application/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    app: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: `calc(var(--vh, 1vh) * 100)`,
    },
  })
);

interface IProps {
  history: History;
}

export default function App(props: IProps) {
  const { history } = props;
  const themeFromInfos = useSelector(selectThemeFromInfos);

  const classes = useStyles();

  useInitDataLoad();

  // use CSS variable for correct height on mobiles including toolbar
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  // capture Chrome event for deffered pwa install
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    window.deferredPrompt = event;
  });

  // inject manifest only for android
  if (!isIos()) {
    injectManifest();
  }

  return (
    <div id="app" className={classes.app}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        <ConnectedRouter history={history}>
          <ScrollToTop />
          <ThemeProvider
            theme={themeFromInfos ? createTheme(themeFromInfos) : createTheme()}
          >
            <SnackbarProvider maxSnack={config.maxSnackbarMessage}>
              <ServiceWorkerWrapper />
              <Switch>
                <Route path={'/starting'} component={Starting} />
                <Route path={'/login'} component={Login} />
                <AuthRoute component={() => <Layout />} />
              </Switch>
            </SnackbarProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </MuiPickersUtilsProvider>
    </div>
  );
}

import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Endpoint } from '../../../router/routes.config';
import { selectInfos } from '../info.selector';
import config from '../../../application/template.config';
import { Gender } from '../config/info.config';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(0.5),
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingRight: '20%',
    fontWeight: 500,
  },
  avatar: {
    fontSize: 60,
    color: theme.palette.secondary.contrastText,
    marginRight: theme.spacing(1),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}px`,
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-20px',
  },
  button: {
    color: theme.palette.secondary.contrastText,
  },
}));

interface IProps {
  classe?: string;
}

export default function UserCard(props: IProps) {
  const { classe } = props;
  const classes = useStyles();
  const history = useHistory();
  const infos = useSelector(selectInfos);
  const { t } = useTranslation();

  const displayData =
    infos &&
    infos.first_name &&
    infos.last_name &&
    infos.company_name &&
    infos.gender
      ? `${infos.first_name} ${infos.last_name} (${
          infos.gender === Gender.MALE
            ? `${t('user.male')}`
            : `${t('user.female')}`
        }), ${infos.company_name}`
      : `${config.user.firstName} ${config.user.lastName} (${
          config.user.gender === Gender.MALE
            ? `${t('user.male')}`
            : `${t('user.female')}`
        }), ${config.user.company}`;

  return (
    <Card
      id="info-card"
      className={classe ? clsx(classe, classes.container) : classes.container}
    >
      <Typography className={classes.title}>
        {t('message.user.identity')}
      </Typography>
      <CardContent className={classes.content}>
        <AccountCircleRoundedIcon className={classes.avatar} />
        <Typography>{displayData}</Typography>
      </CardContent>
      <div className={classes.action}>
        <Button
          onClick={() => history.push(Endpoint.USER_UDPATE)}
          className={classes.button}
        >
          {t('action.edit')}
        </Button>
      </div>
    </Card>
  );
}

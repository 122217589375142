import { State } from '../../@types/state';
import {RecordState} from './config/recorder.config';

export function selectOpen(state: State): boolean {
  return state.recorder.open;
}

export function selectRecordState(state: State): RecordState {
  return state.recorder.recordState;
}

import React, { Dispatch, SetStateAction } from 'react';
import config, { Step } from '../config/onboarding.config';

export function getContentDependingOnStep(
  activeStepId: number,
  setActiveStep: Dispatch<SetStateAction<number>>
): React.ReactNode {
  const { steps } = config;

  function isStep(step: Step) {
    return step.id === activeStepId;
  }

  const step = steps.find(isStep);

  if (!step)
    return (
      <div style={{ color: 'red', textAlign: 'center' }}>
        Aucun contenu pour cette étape
      </div>
    );

  const { Content } = step;

  return <Content setActiveStep={setActiveStep} activeStepId={activeStepId} />;
}

import React from 'react';
import { Redirect } from 'react-router-dom';
import NotFound from '../views/notFound.view';
import Home from '../views/home/home.view';
import Onboarding from '../views/onboarding/onboarding.view';
import Messages from '../views/message/messages.view';
import Message from '../views/message/message.view';
import OrderCreate from '../views/order/create/create.view';
import Order from '../views/order/order.view';
import UserUpdate from '../views/info/update.view';
import OrderTitleUpdate from '../views/order/create/title/create.view';
import TextSelection from '../views/template/selection.view';
import VoiceSelection from '../views/voice/selection.view';
import SoundSelection from '../views/sound/selection.view';
import OrderCreateSuccess from '../views/order/create/success.view';
import MessageInstallation from '../views/message/installation.view';
import Splashscreen from '../views/splashscreen/splashscreen.view';
import MultilingualSelection from '../views/multilingual/selection.view';
import Legal from '../views/legal/legal.view';
import ProfileUpdate from '../views/profile/update.view';
import SchedulerSelection from '../views/scheduler/selection.view';

export enum Endpoint {
  SPLASH_SCREEN = '/splashscreen',
  LOGIN = '/login',
  ROOT = '/',
  ONBOARDING = '/onboarding',
  HOME = '/home',
  MESSAGES = '/messages',
  MESSAGE = '/messages/:messageId',
  ACCOUNT = '/account',
  ORDER = '/orders/:orderId',
  ORDER_CREATE = '/orders/create',
  ORDER_CREATE_SUCCESS = '/orders/create/success',
  ORDER_TITLE_UPDATE = '/orders/title/update',
  USER_UDPATE = '/user/update',
  TEMPLATE_SELECTION = '/template/selection',
  VOICE_SELECTION = '/voice/selection',
  SOUND_SELECTION = '/sound/selection',
  MESSAGE_INSTALLATION = '/messages/validation/:messageId',
  CHANGE_PASSWORD = '/changePassword',
  MULTILINGUAL = '/multilingual/selection',
  LEGAL = '/legal',
  PROFILE_UPDATE = '/profile',
  SCHEDULER_SELECTION = '/scheduler/selection',
}

export enum RouteName {
  HOME = 'route.home',
  MESSAGES = 'route.messages',
  USER_UDPATE = 'route.userUpdate',
  ORDER = 'route.order',
  ORDER_CREATE = 'route.deploymentCreate',
  TEXT_SELECTION = 'route.templateSelection',
  VOICE_SELECTION = 'route.voiceSelection',
  SOUND_SELECTION = 'route.soundSelection',
  MESSAGE_INSTALLATION = 'route.messageInstallation',
  ORDER_TITLE_UPDATE = 'route.orderTitleUpdate',
  MULTILINGUAL = 'route.multilingual',
  LEGAL = 'route.legal',
  PROFILE_UPDATE = 'route.profile',
  SCHEDULER_SELECTION = 'route.schedulerSelection',
}

export interface AuthRoute {
  path: string;
  component: any;
  regex: RegExp;
  name?: string;
  fullscreen: boolean;
}

export const authRoute: AuthRoute[] = [
  {
    path: Endpoint.ROOT,
    regex: / /,
    fullscreen: true,
    component: <Redirect to={Endpoint.HOME} />,
  },
  {
    path: Endpoint.SPLASH_SCREEN,
    component: <Splashscreen />,
    regex: /splashscreen/,
    fullscreen: true,
  },
  {
    path: Endpoint.ONBOARDING,
    component: <Onboarding />,
    regex: /onboarding/,
    fullscreen: true,
  },
  {
    path: Endpoint.HOME,
    component: <Home />,
    regex: /home/,
    name: RouteName.HOME,
    fullscreen: false,
  },
  {
    path: Endpoint.MESSAGE_INSTALLATION,
    component: <MessageInstallation />,
    regex: /messages\/validation/,
    name: RouteName.MESSAGE_INSTALLATION,
    fullscreen: true,
  },
  {
    path: Endpoint.MESSAGE,
    component: <Message />,
    regex: /messages/,
    name: RouteName.MESSAGES,
    fullscreen: false,
  },
  {
    path: Endpoint.MESSAGES,
    component: <Messages />,
    regex: /messages/,
    name: RouteName.MESSAGES,
    fullscreen: false,
  },
  {
    path: Endpoint.ORDER_CREATE,
    component: <OrderCreate />,
    regex: /orders\/create/,
    name: RouteName.ORDER_CREATE,
    fullscreen: true,
  },
  {
    path: Endpoint.ORDER,
    component: <Order />,
    regex: /orders/,
    name: RouteName.ORDER,
    fullscreen: true,
  },
  {
    path: Endpoint.SCHEDULER_SELECTION,
    component: <SchedulerSelection />,
    regex: /scheduler\/selection/,
    name: RouteName.SCHEDULER_SELECTION,
    fullscreen: true,
  },
  {
    path: Endpoint.USER_UDPATE,
    component: <UserUpdate />,
    regex: /user\/update/,
    name: RouteName.USER_UDPATE,
    fullscreen: true,
  },
  {
    path: Endpoint.ORDER_TITLE_UPDATE,
    component: <OrderTitleUpdate />,
    regex: /orders\/title\/update/,
    name: RouteName.ORDER_TITLE_UPDATE,
    fullscreen: true,
  },
  {
    path: Endpoint.TEMPLATE_SELECTION,
    component: <TextSelection />,
    regex: /template\/selection/,
    name: RouteName.TEXT_SELECTION,
    fullscreen: true,
  },
  {
    path: Endpoint.VOICE_SELECTION,
    component: <VoiceSelection />,
    regex: /voice\/selection/,
    name: RouteName.VOICE_SELECTION,
    fullscreen: true,
  },
  {
    path: Endpoint.SOUND_SELECTION,
    component: <SoundSelection />,
    regex: /sound\/selection/,
    name: RouteName.SOUND_SELECTION,
    fullscreen: true,
  },
  {
    path: Endpoint.MULTILINGUAL,
    component: <MultilingualSelection />,
    regex: /multilingual\/selection/,
    name: RouteName.SOUND_SELECTION,
    fullscreen: true,
  },
  {
    path: Endpoint.ORDER_CREATE_SUCCESS,
    component: <OrderCreateSuccess />,
    regex: /deployments\/create/,
    name: RouteName.ORDER_CREATE,
    fullscreen: true,
  },
  {
    path: Endpoint.LEGAL,
    component: <Legal />,
    regex: /legal/,
    name: RouteName.LEGAL,
    fullscreen: true,
  },
  {
    path: Endpoint.PROFILE_UPDATE,
    component: <ProfileUpdate />,
    regex: /profile/,
    name: RouteName.PROFILE_UPDATE,
    fullscreen: false,
  },
  {
    path: '*',
    regex: /\*/,
    fullscreen: false,
    component: <NotFound />,
  },
];

export interface RouteMetaData {
  regex: RegExp;
  name: string;
  fullscreen: boolean;
}

export function getRouteMetaDataFromPathName(pathName: string) {
  const data = authRoute.find((route) => route.regex.test(pathName));

  return data && data;
}

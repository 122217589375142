import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadAllData } from './loadAllData';

import { selectIsAuth, selectToken } from '../modules/auth/auth.selector';

export const useInitDataLoad = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const token = useSelector(selectToken);

  React.useEffect(() => {
    if (isAuth && token) {
      loadAllData(dispatch);
    }

    return () => {};
  }, [dispatch, token, isAuth]);
};

import { Infos } from '../../../@types/state/info';
import { Gender } from '../../info/config/info.config';
import { Template } from '../../../@types/state/template';
import config from '../../../application/template.config';
import { DynamicVariable, DynamicVariableType } from '../types';

export interface ReplaceRule {
  regex: RegExp;
  value: string;
}

/**
 * Replace for all templates standards variables with user values
 * Format display using html tags and css values
 */
export function updateTemplatesForDisplay(
  templates: Template[],
  infos: Infos | null
): Template[] {
  const templatesToUpdate: Template[] = [];

  templates.forEach((template: Template) => {
    const updatedTemplate = updateTemplateForDisplay(template, infos);

    templatesToUpdate.push(updatedTemplate);
  });

  return templatesToUpdate;
}

/**
 * Replace standards variables with user values
 * Format display using html tags and css values
 */
export function updateTemplateForDisplay(
  template: Template,
  infos: Infos | null
): Template {
  const replaceRules: ReplaceRule[] = [
    {
      regex: /{company}/gi,
      value: `<strong data-content="${
        infos && infos.company_name ? infos.company_name : config.user.company
      }"></strong>`,
    },
    {
      regex: /{firstName}/gi,
      value: `<strong data-content="${
        infos && infos.first_name !== null
          ? infos.first_name
          : config.user.firstName
      }"></strong>`,
    },
    {
      regex: /{lastName}/gi,
      value: `<strong data-content="${
        infos && infos.last_name ? infos.last_name : config.user.lastName
      }"></strong>`,
    },
    {
      regex: /\(?{phonLastName}\)?/gi,
      value: ``,
    },
    {
      regex: /\(?{phonFirstName}\)?/gi,
      value: ``,
    },
    {
      regex: /\(?{phonCompany}\)?/gi,
      value: ``,
    },
  ];

  let content = template.content || '';

  const regex = RegExp('{gender:\\((.*?),(.*?)\\)(?=})', 'gi');
  const genderFromTemplate = regex.exec(content);
  const genderMale =
    (genderFromTemplate &&
      `<strong data-content="${genderFromTemplate[1]}"></strong>`) ||
    '';
  const genderFemale =
    (genderFromTemplate &&
      `<strong data-content="${genderFromTemplate[2]}"></strong>`) ||
    '';

  replaceRules.forEach((replaceRule: ReplaceRule) => {
    content = content.replace(replaceRule.regex, replaceRule.value);

    const genderValue =
      infos && infos.gender === Gender.FEMALE ? genderFemale : genderMale;

    content = content.replace(/{gender:(.*?),(.*?)}/, genderValue);
  });
  const templateUpdated = {
    ...template,
    content: content,
  };

  return templateUpdated;
}

/**
 * Replace dynamic variables with labels
 * Format display using html tags and css values
 */
export function updateTemplatesForDisplayWithDynamicLabels(
  templates: Template[]
): Template[] {
  return templates.map((template) => {
    const dynamicVariables = extractVariablesFromTemplate(template.content);
    dynamicVariables.forEach((variable) => {
      template.content = template.content.replace(
        variable.value,
        `<strong data-content="${variable.label}" style="display: ${
          variable.visible ? 'inline' : 'none'
        };"></strong>`
      );
    });
    return template;
  });
}

/**
 * Replace dynamic variables with user values
 * Format display using html tags and css values
 */
export function updateTemplatesForDisplayWithDynamicVariableValues(
  templates: Template[],
  dynamicVariableValues: Record<string, string>
): Template[] {
  return templates.map((template) => {
    const dynamicVariables = extractVariablesFromTemplate(template.content);
    dynamicVariables.forEach((variable) => {
      template.content = template.content.replace(
        variable.value,
        `<strong data-content="${
          dynamicVariableValues[variable.key] || variable.value
        }" style="display: ${variable.visible ? 'inline' : 'none'};"></strong>`
      );
    });
    return template;
  });
}

/**
 * Replace dynamic variables with user values
 * Format display using html tags and css values
 */
export function updateContentWithDynamicVariableValues(
  content: string,
  dynamicVariableValues: Record<string, string>
): string {
  let newContent = content;
  const dynamicVariables = extractVariablesFromTemplate(content);

  dynamicVariables.forEach((variable) => {
    newContent = newContent.replace(
      variable.value,
      `<strong data-content="${
        dynamicVariableValues[variable.key] || variable.value
      }" style="display: ${variable.visible ? 'inline' : 'none'};"></strong>`
    );
  });

  return newContent;
}

/**
 * Replace dynamic variables with user values
 * Format display using html tags and css values
 */
export function updateContentForDisplayWithDynamicVariableValues(
  content: string,
  dynamicVariableValues: Record<string, string>
): string {
  let newContent = content;
  const dynamicVariables = extractVariablesFromTemplate(content);

  dynamicVariables.forEach((variable) => {
    newContent = newContent.replace(
      variable.value,
      dynamicVariableValues[variable.key] || variable.label
    );
  });

  return newContent;
}

/**
 * Interpolate standards variables for order content
 */
export function updateTemplateWithInfos(
  template: Template,
  infos: Infos,
  dynamicVariableValues?: Record<string, string>
): string {
  const replaceRules: ReplaceRule[] = [
    {
      regex: /{company}/gi,
      value: `${infos.company_name}`,
    },
    {
      regex: /{firstName}/gi,
      value: `${infos.first_name ? infos.first_name : 'Thomas'}`,
    },
    {
      regex: /{lastName}/gi,
      value: `${infos.last_name ? infos.last_name : 'Nova'}`,
    },
  ];

  let content = template.content;

  const regex = RegExp('{gender:\\((.*?),(.*?)\\)(?=})', 'gi');
  const genderFromTemplate = regex.exec(content);
  const genderMale = (genderFromTemplate && genderFromTemplate[1]) || '';
  const genderFemale = (genderFromTemplate && genderFromTemplate[2]) || '';

  const genderValue =
    infos && infos.gender === Gender.MALE ? genderMale : genderFemale;

  replaceRules.forEach((replaceRule: ReplaceRule) => {
    content = content.replace(replaceRule.regex, replaceRule.value);

    content = content.replace(/{gender:(.*?),(.*?)}/, genderValue);
  });

  if (dynamicVariableValues) {
    return updateContentWithDynamicVariableValues(
      content,
      dynamicVariableValues
    );
  }

  return content;
}

/**
 * Catch all valid dynamic variables
 *
 *  (?<=) look behind
 *  (?=)  look ahead
 *
 * Credits: https://stackoverflow.com/a/23756210
 */
export function extractVariablesFromTemplate(
  templateContent: string | undefined
): DynamicVariable[] {
  if (!templateContent) return [];

  const regex = RegExp(/(?<={).*?(?=})/, 'gi');
  const variables = templateContent.match(regex);

  const dynamicVariables: DynamicVariable[] = [];

  if (!variables) return dynamicVariables;

  for (const variable of variables) {
    const values = variable.split('|').map((elem) => elem.trim());
    // A dynamic variable is valid with 4 elements : variable_key | label | type | visible
    const isDynamicVariable = values.length === 4;

    if (isDynamicVariable) {
      const dynamicVariable: DynamicVariable = {
        key: values[0],
        label: values[1],
        type: values[2] as DynamicVariableType,
        visible: values[3] === 'true' ? true : false,
        value: `{${variable}}`,
      };
      dynamicVariables.push(dynamicVariable);
    }
  }

  return dynamicVariables;
}

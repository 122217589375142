import * as React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import { DynamicVariableProps } from 'modules/template/types';

const REGEX_REQUIRED = /^\S+.*$/;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  label: {
    paddingLeft: theme.spacing(0.5),
    marginTop: theme.spacing(2),
  },
}));

export function VariableText(props: DynamicVariableProps) {
  const { fieldKey, fieldLabel, value, onChangeValue, onError } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [valid, setValid] = React.useState(true);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    const reg = new RegExp(REGEX_REQUIRED);
    const isValid = reg.test(value);

    setValid(isValid);

    onError(fieldKey, !isValid);

    onChangeValue && onChangeValue(fieldKey, value);
  }

  return (
    <React.Fragment>
      <InputLabel htmlFor="lastName" className={classes.label}>
        {fieldLabel && fieldLabel}
      </InputLabel>
      <TextField
        id="content"
        name="content"
        variant="outlined"
        margin="normal"
        type="text"
        value={value}
        placeholder={fieldLabel}
        onChange={handleChange}
        color="secondary"
      />
      {!valid && (
        <FormHelperText error={!valid}>
          {t('dynamicVariables.required')}
        </FormHelperText>
      )}
    </React.Fragment>
  );
}

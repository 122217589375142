import { store } from '../../../Store';
import { ValidRefreshToken } from '../../../@types/auth';
import * as authAction from '../../../modules/auth/auth.action';
import { selectRefreshtoken, selectToken } from 'modules/auth/auth.selector';

export function waitFor(milliseconds: number): Promise<unknown> {
  const newWait = new Promise(function (resolve) {
    setTimeout(resolve, milliseconds);
  });

  return newWait;
}

export async function getNewToken() {
  const { dispatch } = store;
  return new Promise(async (resolve, reject) => {
    const refreshToken = selectRefreshtoken(store.getState());

    if (refreshToken) {
      const payload: ValidRefreshToken = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      };
      // dispatch at this level doesn't anderstand 'redux-thunk'
      // @ts-ignore
      await dispatch(authAction.refreshToken(payload));

      const newToken = selectToken(store.getState());

      resolve(newToken);
    } else {
      reject('noToken');
    }
  });
}

import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';

import * as orderAction from 'modules/order/order.action';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    minHeight: theme.spacing(8),
    marginBottom: theme.spacing(3),
  },
}));

export interface IProps {
  activeStepId: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

export default function InstallTypes(props: IProps) {
  const { activeStepId, setActiveStep } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  function handleSchedulerNavigate() {
    setActiveStep(activeStepId + 1);
  }

  function handleNoScheduler() {
    dispatch(orderAction.setScheduler(null));
    history.goBack();
  }

  return (
    <div id="sound-types" className={classes.container}>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        disableElevation
        size="large"
        onClick={handleSchedulerNavigate}
      >
        {t('scheduler.action')}
      </Button>

      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        disableElevation
        size="large"
        onClick={handleNoScheduler}
      >
        {t('scheduler.now')}
      </Button>
    </div>
  );
}

import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: (progress: boolean) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: `${progress ? 'center' : 'space-between'}`,
    width: '100%',
    height: 59,
    padding: theme.spacing(1),
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    zIndex: 1200,
  }),
  button: {
    padding: `${theme.spacing(0)} ${theme.spacing(4)}px`,
  },
}));

export interface IProps {
  progress?: React.ReactNode;
  Previous: React.ElementType;
  Next: React.ElementType;
}

export default function BottomBanner(props: IProps) {
  const { progress, Previous, Next } = props;
  const classes = useStyles(!!progress);

  return (
    <div id="bottom-banner" className={classes.container}>
      <Previous className={classes.button} />
      {progress && progress}
      <Next className={classes.button}>suivant</Next>
    </div>
  );
}

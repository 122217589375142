import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { Menu as MuiMenu } from '@material-ui/core';
import VoicemailRoundedIcon from '@material-ui/icons/VoicemailRounded';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import LibraryMusicRoundedIcon from '@material-ui/icons/LibraryMusicRounded';
import Typography from '@material-ui/core/Typography';

import * as orderAction from '../../order/order.action';
import * as snackbarAction from '../../snackbar/snackbar.action';
import {
  selectHasCreditCreate,
  selectHasCreditInstall,
  selectHasCreditRemix,
  selectInfos,
} from 'modules/info/info.selector';

import { Endpoint } from '../../../router/routes.config';
import { MessageActions } from 'modules/message/config/message.config';

import { Infos } from '../../../@types/state/info';

enum CreditType {
  CREATE = 'create',
  INSTALL = 'install',
  REMIX = 'remix',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      right: 20,
      bottom: 30,
      background: theme.palette.secondary.main,
      boxShadow: `5px 10px 15px rgba(0, 0, 0, 0.4)`,
      borderRadius: theme.spacing(2),
      overflowWrap: 'break-word',
    },
    button: {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 10,
      },
    },
    buttonTitle: {
      color: theme.palette.secondary.contrastText,
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1),
    },
    orderCreate: {},

    messagesList: {
      '& .MuiPaper-root': {
        paddingTop: 0,
        borderRadius: theme.spacing(2),
      },
      '& ul': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    messageListTitle: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      opacity: '1 !important',
    },
  })
);

function getMenuTitle(text: string, classes: any) {
  return (
    <Box id="menu-title" display="flex" alignItems="center">
      <VoicemailRoundedIcon className={classes.buttonTitle} />
      <Box ml={1} color="secondary.contrastText">
        {text}
      </Box>
    </Box>
  );
}

function getButtom(
  t: any,
  infos: Infos | null,
  icon: any,
  text: string,
  credit: CreditType
) {
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Box fontWeight="fontWeightBold">
        <Box color="text.primary">
          <Typography style={{ whiteSpace: 'initial' }}>{t(text)}</Typography>
        </Box>
        {infos && credit === CreditType.CREATE && (
          <Box color="text.secondary" fontWeight="fontWeightLight">
            {`${t('credit.available')} : ${
              infos.credits.credit_create | infos.credits.credit_lm_create
            }`}
          </Box>
        )}
        {infos && credit === CreditType.INSTALL && (
          <Box color="text.secondary" fontWeight="fontWeightLight">
            {`${t('credit.available')} : ${
              infos.credits.credit_install | infos.credits.credit_lm_install
            }`}
          </Box>
        )}
        {infos && credit === CreditType.REMIX && (
          <Box color="text.secondary" fontWeight="fontWeightLight">
            {`${t('credit.available')} : ${
              infos.credits.credit_remix | infos.credits.credit_lm_remix
            }`}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default function Menu() {
  const buttonEl = React.useRef<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const classes = useStyles();
  const hasCreditCreate = useSelector(selectHasCreditCreate);
  const hasCreditInstall = useSelector(selectHasCreditInstall);
  const hasCreditRemix = useSelector(selectHasCreditRemix);
  const infos = useSelector(selectInfos);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleOnClickListItem(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleOnClose() {
    setAnchorEl(null);
  }

  async function handleOnCreateMessageClick(
    event: React.MouseEvent<HTMLElement>
  ) {
    event.stopPropagation();
    if (hasCreditCreate) {
      history.push(Endpoint.ORDER_CREATE);
      dispatch(orderAction.resetInProgressOrder());
      dispatch(orderAction.getOrders());
    } else {
      dispatch(
        snackbarAction.enqueueSnackbar({
          message: `${t('snackbar.error.noCredit')}`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            action: (key: number) => (
              <Button
                style={{ color: 'white' }}
                onClick={() => dispatch(snackbarAction.closeSnackbar(key))}
              >
                {t('action.close')}
              </Button>
            ),
          },
        })
      );
    }
  }

  async function handleOnInstallMessageClick(
    event: React.MouseEvent<HTMLElement>
  ) {
    event.stopPropagation();
    if (hasCreditInstall) {
      history.push(
        `${Endpoint.MESSAGES}?action=${MessageActions.CREATE_INSTAL}`
      );
    } else {
      dispatch(
        snackbarAction.enqueueSnackbar({
          message: `${t('snackbar.error.noCredit')}`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            action: (key: number) => (
              <Button
                style={{ color: 'white' }}
                onClick={() => dispatch(snackbarAction.closeSnackbar(key))}
              >
                {t('action.close')}
              </Button>
            ),
          },
        })
      );
    }
  }

  async function handleOnRemixMessageClick(
    event: React.MouseEvent<HTMLElement>
  ) {
    event.stopPropagation();
    if (hasCreditRemix) {
      history.push(
        `${Endpoint.MESSAGES}?action=${MessageActions.CREATE_REMIX}`
      );
    } else {
      dispatch(
        snackbarAction.enqueueSnackbar({
          message: `${t('snackbar.error.noCredit')}`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            action: (key: number) => (
              <Button
                style={{ color: 'white' }}
                onClick={() => dispatch(snackbarAction.closeSnackbar(key))}
              >
                {t('action.close')}
              </Button>
            ),
          },
        })
      );
    }
  }

  return (
    <div id="messages-menu" className={classes.container}>
      <List
        ref={buttonEl}
        className={classes.button}
        component="nav"
        aria-label="Device settings"
      >
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleOnClickListItem}
        >
          <ListItemText primary={getMenuTitle(t('menu.title'), classes)} />
        </ListItem>
      </List>

      <MuiMenu
        id="messages-list"
        className={classes.messagesList}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          id="messages-list-title"
          className={classes.messageListTitle}
          disabled
        >
          {getMenuTitle(t('menu.title'), classes)}
        </MenuItem>
        <Divider />
        <MenuItem id="message-create" onClick={handleOnCreateMessageClick}>
          {getButtom(
            t,
            infos,
            <AddRoundedIcon fontSize="large" className={classes.icon} />,
            'fab.orderCreate',
            CreditType.CREATE
          )}
        </MenuItem>
        <MenuItem id="message-install" onClick={handleOnInstallMessageClick}>
          {getButtom(
            t,
            infos,
            <AutorenewRoundedIcon fontSize="large" className={classes.icon} />,
            'fab.orderInstall',
            CreditType.INSTALL
          )}
        </MenuItem>
        <MenuItem id="message-remix" onClick={handleOnRemixMessageClick}>
          {getButtom(
            t,
            infos,
            <LibraryMusicRoundedIcon
              fontSize="large"
              className={classes.icon}
            />,
            'fab.orderRemix',
            CreditType.REMIX
          )}
        </MenuItem>
      </MuiMenu>
    </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { config, ConfigItem } from '../../config/drawer.config';
import { useLocation, useHistory } from 'react-router-dom';
import { getRouteMetaDataFromPathName } from '../../../../router/routes.config';
import { selectInfos } from '../../../info/info.selector';
// import I18n from '../../../translation/components/i18n.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    logo: {
      margin: theme.spacing(2),
    },
    logoContainer: {
      background: theme.palette.primary.main,
    },
    name: {
      paddingLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    item: {
      paddingRight: theme.spacing(10),
      color: theme.palette.text.primary,
      '& svg': {
        color: theme.palette.text.primary,
      },
    },
    active: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '& svg': {
        color: theme.palette.secondary.contrastText,
      },
    },
  })
);

export default function Content() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const infos = useSelector(selectInfos);

  function handleOnClick(endpoint: string) {
    history.push(endpoint);
  }

  return (
    <div id="drawer-content" className={classes.container}>
      <div id="logo-container" className={classes.logoContainer}>
        <img
          className={classes.logo}
          src={(infos && infos.logo_url) || ''}
          height="60"
          alt="logo"
        />
      </div>
      {/* <div
        id="i18n-select"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <I18n />
      </div> */}
      {infos && (
        <Typography
          className={classes.name}
          color="textSecondary"
          variant="h5"
          gutterBottom
        >
          {infos && `${infos.first_name} ${infos.last_name}`}
        </Typography>
      )}

      <List>
        {config.map((item: ConfigItem) => {
          const { Icon } = item;
          return (
            <ListItem
              key={item.id}
              button
              className={
                routeMetaData?.name === item.text
                  ? clsx(classes.item, classes.active)
                  : classes.item
              }
              onClick={() => handleOnClick(item.endpoint)}
              disabled={item.disabled}
            >
              <ListItemIcon>{Icon}</ListItemIcon>
              <ListItemText>{t(item.text)}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

import * as React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { selectInfos } from 'modules/info/info.selector';

import { useQuery } from 'modules/utils/hook';
import { addDays } from 'modules/utils/utils.helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    paddingTop: theme.spacing(10),
  },
  scheduler: {
    width: '100%',
    padding: theme.spacing(0, 8),
  },
}));

export interface IProps {
  activeStepId: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

export default function Scheduler({ selectedDate, setSelectedDate }: IProps) {
  const [minDate, setMinDate] = React.useState(new Date());
  const classes = useStyles();
  const query = useQuery();
  const action = query.get('action');
  const infos = useSelector(selectInfos);

  // Set the min date according to the type of action
  React.useEffect(() => {
    if (infos?.delay_before_install && action) {
      const delayForAction = infos.delay_before_install[action];
      const minDateForAction = addDays(delayForAction);
      if (minDateForAction) {
        setSelectedDate(minDateForAction);
        setMinDate(minDateForAction);
      }
    }
  }, [action, infos, setSelectedDate]);

  return (
    <div className={classes.root}>
      <KeyboardDateTimePicker
        ampm={false}
        className={classes.scheduler}
        variant="inline"
        value={selectedDate}
        disablePast
        minDate={minDate}
        // @ts-ignore
        onChange={setSelectedDate}
        label=""
        format="yyyy/MM/dd HH:mm"
      />
    </div>
  );
}

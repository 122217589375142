import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Drawer as MuiDrawer } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Endpoint } from '../../../router/routes.config';
import appConfig from '../../../application/app.config';

interface IProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactChild;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1, 0),
    maxHeight: '100vh',
  },
  drawerContent: {
    minHeight: `calc(var(--vh, 1vh) * 100 - ${35 + 19}px)`,
    overflow: 'auto',
  },
  version: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  legal: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 0),
    background: '#ECEFF1',
    borderTop: '1px solid',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function Drawer(props: IProps) {
  const { isDrawerOpen, setIsDrawerOpen, children } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <MuiDrawer
      id="drawer-open"
      open={isDrawerOpen}
      onClick={() => setIsDrawerOpen(!isDrawerOpen)}
    >
      <div id="drawer-content-wrapper" className={classes.drawerContent}>
        {children && children}
      </div>
      <Typography
        color="textSecondary"
        align="right"
        variant="caption"
        className={classes.version}
      >
        {`V${process.env.REACT_APP_VERSION}`}
      </Typography>
      {appConfig.featureFlip.legal && (
        <div
          id="legal"
          className={classes.legal}
          onClick={() => history.push(Endpoint.LEGAL)}
        >
          <Typography variant="caption" color="textPrimary" align="center">
            {t('legal.link')}
          </Typography>
        </div>
      )}
    </MuiDrawer>
  );
}

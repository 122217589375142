import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Endpoint } from 'router/routes.config';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import * as authAction from 'modules/auth/auth.action';
import {
  selectFetchErrors as selectFetchAuthErrors,
  selectToken,
} from 'modules/auth/auth.selector';
import startingLogo from 'application/images/starting-log.png';

import { ValidRefreshToken } from '../../@types/auth';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(var(--vh, 1vh) * 100)`,
    background: theme.palette.background.paper,
  },
  content: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  auth: {
    flex: 1,
  },
  progress: {},
  logo: {
    maxWidth: 100,
    maxHeight: 100,
    marginBottom: theme.spacing(3),
  },
  loader: {
    width: '100%',
    background: theme.palette.text.secondary,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    '& p': {
      marginBottom: theme.spacing(2),
    },
    '& span': {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(5),
    },
  },
}));

export default function Starting() {
  const [loading, setLoading] = React.useState(false);
  const [canRedirect, setCanRedirect] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fetchAuthErrors = useSelector(selectFetchAuthErrors);
  const token = useSelector(selectToken);

  const searchParams = new URLSearchParams(location.search);
  const hash = searchParams.get('h');
  const destination = searchParams.get('d');
  const id = searchParams.get('id');

  // Auth user with refresh token
  React.useEffect(() => {
    let ignore = false;
    async function authWithRefreshToken() {
      if (hash && !ignore) {
        setLoading(true);
        const refreshToken: ValidRefreshToken = {
          grant_type: 'refresh_token',
          refresh_token: hash,
        };
        await dispatch(authAction.refreshToken(refreshToken));
        setCanRedirect(true);
        setLoading(false);
      }
    }

    authWithRefreshToken();

    return () => {
      ignore = true;
      setCanRedirect(false);
    };
  }, [dispatch, hash]);

  // Redirect user
  React.useEffect(() => {
    let ignore = false;
    async function redirect() {
      if (canRedirect && !fetchAuthErrors && !ignore && token) {
        switch (destination) {
          case 'order': {
            history.replace(Endpoint.SPLASH_SCREEN);
            break;
          }
          case 'message': {
            if (!id) {
              history.push(Endpoint.HOME);
            }
            if (id) {
              const path = Endpoint.MESSAGE_INSTALLATION.replace(
                /:messageId/,
                id
              );
              history.replace(path);
            }
            break;
          }
          default: {
            history.push(Endpoint.HOME);
          }
        }
      }
    }

    redirect();

    return () => {
      ignore = true;
    };
  }, [
    canRedirect,
    destination,
    dispatch,
    history,
    id,
    loading,
    token,
    fetchAuthErrors,
  ]);

  return (
    <div id="starting-view" className={classes.container}>
      <div id="content" className={classes.content}>
        <img src={startingLogo} alt="logo-ats" className={classes.logo} />
      </div>

      <div id="auth" className={classes.auth}>
        {loading && (
          <div className={classes.progress}>
            <Typography color="textSecondary" align="center">
              {t('auth.identification')}
            </Typography>
            <LinearProgress classes={{ root: classes.loader }} />
          </div>
        )}
      </div>

      <div id="footer" className={classes.footer}>
        <Typography color="textSecondary" align="center">
          {t('starting')}
        </Typography>
        <Typography
          color="textSecondary"
          align="center"
          variant="caption"
        >{`V${process.env.REACT_APP_VERSION}`}</Typography>
      </div>
    </div>
  );
}

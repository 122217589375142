import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Steps } from '../../config/onboarding.config';
import PlayerButtonRounded from '../../../player/components/button/rounded.component';
import * as PlayerAction from '../../../player/player.action';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: `calc(var(--vh, 1vh) * 100)`,
  },
  title: {
    margin: theme.spacing(6),
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  subTitle: {
    padding: theme.spacing(0, 4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  hint: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: '#808E95',
  },
  sound: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  card: {
    minWidth: '90vw',
    '& .MuiCardContent-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  button: {
    minHeight: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}));

export interface IProps {
  activeStepId: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export default function Step1(props: IProps) {
  const { setActiveStep } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const path = `${process.env.PUBLIC_URL}/mock/message/message-1.mp3`;

  function handleNextClick() {
    dispatch(PlayerAction.stop());
    setActiveStep(Steps.STEP_2);
  }

  return (
    <div id="voice-types" className={classes.container}>
      <Typography className={classes.title} variant="h4">
        {t('onboarding.step.1.hello')}
      </Typography>
      <Typography className={classes.subTitle} variant="h6">
        {t('onboarding.step.1.help')}
      </Typography>

      <div id="demo-sound" className={classes.sound}>
        <Typography className={classes.hint}>
          {t('onboarding.step.1.demo')}
        </Typography>
        <Card className={classes.card}>
          <CardContent>
            <PlayerButtonRounded id="1" path={path} />
          </CardContent>
        </Card>
      </div>

      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleNextClick}
      >
        {t('action.next')}
      </Button>
    </div>
  );
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: theme.palette.text.secondary,
    },
  })
);
export default function Login() {
  const classes = useStyles();

  return (
    <div id="login-view" className={classes.container}>
      <Typography className={classes.text} variant={'h6'}>
        Token invalide ou expiré
      </Typography>
      <Typography className={classes.text}>
        Veuillez vous reconnecter
      </Typography>
    </div>
  );
}
